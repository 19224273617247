import React, { Component } from 'react';
import store from '../store';
import Grid from '../components/Grid.js';
import Paginacao from '../components/Paginacao.js';
import Collection from '../components/Collection.js';
import NavBar from '../components/NavBar.js';
import { GetEnderecosAPI } from '../models/ModelEnderecos.js';
import { GetFornecedoresAutocompleteAPI } from '../models/ModelFornecedores.js';
import { GetServicosAutocompleteAPI } from '../models/ModelServicos.js';
import { GetProdutosAutocompleteAPI } from '../models/ModelProdutos.js';
import { GetClientesAutocompleteAPI } from '../models/ModelClientes.js';
import { clearMessage, questionsMessage } from '../services/Funcoes.js';


class ComponentErp extends Component {


  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState) {

  }

  novoCadastro = (event) => {
    event.preventDefault()

    this.setState({
      refresh: 'limpando',
      id: 0
    });

  }

  returnComponentRefresh = () => {

    this.setState({
      refresh: 'limpando'
    })

  }

  async loadDataFornecedor(nome) {
    var response = await GetFornecedoresAutocompleteAPI(nome);
    if (response.erro === 0 && response.lista.length > 0)
      return response.lista;
    else
      return [Object({ id: '', nome: '-' })];

  }

  async loadDataProduto(nome) {
    var response = await GetProdutosAutocompleteAPI(store.getState().comandas.cadastro.id_fornecedor, nome);
    if (response.erro === 0 && response.lista.length > 0)
      return response.lista;
    else
      return [Object({ id: '', nome: '-', vVenda: '' })];

  }

  async loadDataServico(nome) {

    var response = await GetServicosAutocompleteAPI(nome);
    if (response.erro === 0 && response.lista.length > 0)
      return response.lista;
    else
      return [Object({ id: '', nome: '-', serv_valor: '' })];

  }

  async loadDataCliente(nome) {
    var response = await GetClientesAutocompleteAPI(nome);
    if (response.erro === 0 && response.lista.length > 0)
      return response.lista;
    else
      return [Object({ id: '', nome: '-' })];

  }

  handleInputChangeCliente = (values) => {
    if (values === null)
      this.setState({
        id_cliente: '',
        cli_razao: ''
      });
    else
      this.setState({
        id_cliente: values.id,
        cli_razao: values.nome
      });

  }

  handleInputChangeFornecedor = (values) => {
    if (values === null)
      this.setState({
        id_fornecedor: '',
        nome_fornecedor: ''
      });
    else
      this.setState({
        id_fornecedor: values.id,
        nome_fornecedor: values.nome
      });
  }

  handleSubmitGravando = (event) => {
    event.preventDefault()
    this.setState({
      refresh: 'gravando'
    });

  }

  handleSubmit = (event) => {
    event.preventDefault()
  }

  handleInputEstado = (event) => {
    this.handleInputChange(event)
    this.callApiGetCidades(event.target.value)
  }

  async callApiGetCidades(id_estado) {

    if (id_estado > 0) {
      var response = await GetEnderecosAPI(id_estado, true);
      this.setState(
        response
      );
      this.setState({
        sid_cidade: null
      }
      );

    } else {
      this.setState(
        { cidades: null }
      );
    }
  }

  callBackPaginacao = (response) => {
    response.refresh = 'gravando';
    response.type = this.state.redux_prefix + response.type;
    this.updateReduxAndState(response);
  }

  handleInputChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  setAba = (paba) => {
    if (this.state.aba !== paba) {
      this.setState({
        aba: paba
      });
    }
  }

  clickChildModal = (id, refresh, onClick, itens, title) => {
    if (title === 'undefined') {
      title = 'O que deseja fazer?';
    }

    var content = [
      {
        item:
          <Collection onClick={onClick} itens={itens} />
      }
    ];
    questionsMessage(title, content);


    if (id !== '') {
      this.setState({
        refresh: refresh,
        id: id,
      });
    }

  }


  Grid = (grids) => {
    return <div>{Grid(grids)}{Paginacao(grids)}</div>;
  }

  GridFull = (grids) => {
    return <div>{Grid(grids)}</div>;
  }

  navMenu = (nav) => {
    return <NavBar className={nav.className}
      tabs={nav.tabs}
      disabled={nav.disabled}
      onClick={nav.onClick}
      href={nav.href}
    />
  }
  clickChildListaAba = (event) => {
    if (this.state.aba !== 'Lista') {
      this.setAba('Lista');
      this.setState({ refresh: 'lista' });
    }
  }

  updateReduxAndState = (response) => {
    store.dispatch(response);
    this.setState(response);
  }

  updateRedux = (response) => {
    store.dispatch(response);
  }

  clearModal = () => {
    clearMessage();
  }

  actionClick = (tag) => {
    let button = document.querySelector(tag);
    button.click();
  }

  linkLista = (event) => {
    event.preventDefault()
    this.props.history.goBack();
  }

  render() {

    return (

      <div>
      </div>

    );

  }
}
export default (ComponentErp);
