import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import { getSisStatus } from '../services/Auth.js';
import FormEdit from '../components/FormEdit.js';
import { GetColaboradoresAPI } from '../models/ModelColaboradores.js';
import ColaboradoresUpdate from './ColaboradoresUpdate.js';
import ColaboradoresPermissoes from './ColaboradoresPermissoes.js';
import ColaboradoresSenha from './ColaboradoresSenha.js';

class Colaboradores extends ComponentErp {
  constructor(props) {
    super(props);
    this.state = store.getState().colaboradores.grids;
  }

  componentDidMount() {
    this.callApi()
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.setState({ refresh: 'buscando' });
      this.callApi()
    }

    if (this.state.refresh === 'limpando') {
      this.actionClick('.cadastro');
    }

    if (this.state.refresh === 'permissoes' || this.state.refresh === 'listapermissoes') {
      this.actionClick('.permissoes');
    }

    if (this.state.refresh === 'senha' || this.state.refresh === 'listasenha') {
      this.actionClick('.senha');
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()

    var response = {
      refresh: 'gravando',
      fNome: this.state.fNome,
      fStatus: this.state.fStatus,
      type: 'COLABORADORES.TOOGLE_FBUSCA'
    }
    this.updateReduxAndState(response);

  }

  clickChild = (event, id) => {
    this.clickChildModal(
      id,
      'concluido',
      store.getState().colaboradores.grids.edit.onClick,
      store.getState().colaboradores.grids.edit.itens);

  }

  clickChildCadastro = (event) => {
    event.preventDefault()
    var response = '';

    if (this.state.aba !== 'Cadastro' && this.state.id !== 0 && this.state.id !== store.getState().colaboradores.id_empresa_usu) {

      response = {
        id: this.state.id,
        type: 'COLABORADORES.TOOGLE_CADASTRO'
      }
      this.updateRedux(response);

      this.setState({
        refresh: 'buscacolaboradorpermissao'
      });

      this.actionClick('.cadastro');
      this.clearModal();

    } else if (this.state.id === 0) {
      response = { type: 'COLABORADORES.TOOGLE_CLEAN', refresh: 'limpando' }
      this.updateRedux(response);

    }
    if (this.state.aba !== 'Cadastro') {
      this.actionClick('.cadastro');
    }
    this.setAba('Cadastro');

  }

  clickChildPermissoes = (event) => {

    if (this.state.aba !== 'Permissoes' && this.state.id !== 0 && this.state.id !== store.getState().colaboradores.permissoes.id_empresa_usu) {
      var response = { permissoes: <ColaboradoresPermissoes />, refresh: 'listapermissoes', id_empresa_usu: this.state.id, type: 'COLABORADORES.PERMISSOES.TOOGLE_ID_COLABORADOR' }
      this.updateReduxAndState(response);
    }

    if (this.state.aba !== 'Permissoes') {
      this.actionClick('.permissoes');
      this.clearModal();
    }

    this.setAba('Permissoes');
  }

  clickChildSenha = (event) => {

    if (this.state.aba !== 'Senha' && this.state.id !== 0 &&
      this.state.id !== store.getState().colaboradores.senha.id_empresa_usu) {
      var response = {
        senha: <ColaboradoresSenha />,
        refresh: 'listasenha',
        id_empresa_usu: this.state.id,
        type: 'COLABORADORES.SENHA.TOOGLE_ID_COLABORADOR'
      }
      this.updateReduxAndState(response);
    }

    if (this.state.aba !== 'Senha') {
      this.actionClick('.senha');
      this.clearModal();
    }

    this.setAba('Senha');
  }

  async callApi() {
    var grids = store.getState().colaboradores.grids;

    var response = await GetColaboradoresAPI(grids.limit, grids.offset, grids.fNome, grids.fStatus);
    response.refresh = 'concluido';
    response.onClickModal = this.clickChild;
    response.callBackPaginacao = this.callBackPaginacao;
    response.editOnClick = [this.clickChildCadastro, this.clickChildPermissoes, this.clickChildSenha];

    let nav = store.getState().colaboradores.grids.nav;
    nav.disabled[2] = 'disabled';
    nav.onClick[0] = this.clickChildListaAba;
    nav.onClick[1] = this.clickChildCadastro;
    nav.onClick[2] = this.clickChildPermissoes;
    nav.onClick[3] = this.clickChildSenha;

    response.nav = nav;

    response.type = 'COLABORADORES.TOOGLE_LISTA';

    this.updateRedux(response);
    grids = store.getState().colaboradores.grids;
    this.setState(grids);
  }

  render() {

    return (

      <div className="container">
        <div className="row">
          <h2 className="section-title">Colaboradores</h2>
          <div className="card">
            {this.navMenu(store.getState().colaboradores.grids.nav)}
            <div id="lista" className="dataTables_wrapper no-footer row">
              <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">

                {<FormEdit id='fNome'
                  onChange={this.handleInputChange}
                  label='Busque por nome'
                  value={this.state.fNome}
                  styleDiv='4' />}

                {<FormEdit id='fStatus'
                  label='Status'
                  styleDiv='4'
                  onChange={this.handleInputChange}
                  typeField='select'
                  value={this.state.fStatus}
                  optionsField={getSisStatus('status_padrao')} />}

                {<FormEdit id='submit'
                  label='Pesquisar'
                  styleDiv='submit'
                  styleField='submit'
                  typeField='submit'
                  icon='pesquisa'
                />}

                {<FormEdit id='cadNovo'
                  label='Novo Cadastro'
                  styleDiv='submit'
                  onClick={this.novoCadastro}
                  styleField='submit'
                  typeField='button'
                  icon='adiciona'
                />}

              </form>

              {this.Grid(store.getState().colaboradores.grids)}
            </div>
            <div id="cadastro" className="dataTables_wrapper no-footer row">
              {<ColaboradoresUpdate />}
            </div>
            <div id="permissoes" className="dataTables_wrapper no-footer row">
              {store.getState().colaboradores.grids.permissoes}
            </div>
            <div id="senha" className="dataTables_wrapper no-footer row">
              {store.getState().colaboradores.grids.senha}
            </div>

          </div>
        </div>
      </div>

    );

  }
}
export default (Colaboradores);
