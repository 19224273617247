import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { Redirect, BrowserRouter, Switch, Route } from "react-router-dom";
import { isAuthenticated } from "./services/Auth";
import Login from './pages/Login';
import App from './App';
import Clientes from './pages/Clientes';
import Fornecedores from './pages/Fornecedores';
import Colaboradores from './pages/Colaboradores';
import Produtos from './pages/Produtos';
import Servicos from './pages/Servicos';
import Comandas from './pages/Comandas';
import ContasPagar from './pages/ContasPagar';
import ContasReceber from './pages/ContasReceber';
import FluxoCaixa from './pages/FluxoCaixa';
import RelatorioContasaPagarGeral from './pages/RelatorioContasaPagarGeral';
import RelatorioContasaPagarContas from './pages/RelatorioContasaPagarContas';
import RelatorioContasaPagarFornecedores from './pages/RelatorioContasaPagarFornecedores';
import RelatorioContasaPagarPorFornecedores from './pages/RelatorioContasaPagarPorFornecedores';
import ResultadoFinanceiro from './pages/ResultadoFinanceiro';
import UsuarioSenha from './pages/UsuarioSenha';
import Home from './pages/Home';
import Sair from './pages/Sair';
import store from './store';

const PrivateRoute = ({ component: Component }) => (
  isAuthenticated() ? (
    <Route exact component={Component} />
  ) : (
      <Redirect to={{ pathname: "/" }} />
    )
);


class Routes extends Component {


  render() {
    return (
      <Provider store={store}>
        <BrowserRouter>
          <App>

            <Switch>
              <Route path="/" exact component={Login} />

              <PrivateRoute path="/home" exact component={Home} />
              <PrivateRoute path="/cliente/lista" exact component={Clientes} />
              <PrivateRoute path="/fornecedores/lista" exact component={Fornecedores} />
              <PrivateRoute path="/produto/lista" exact component={Produtos} />
              <PrivateRoute path="/servico/lista" exact component={Servicos} />
              <PrivateRoute path="/colaborador/lista" exact component={Colaboradores} />
              <PrivateRoute path="/venda/lista" exact component={Comandas} />
              <PrivateRoute path="/contasapagar/lista" exact component={ContasPagar} />
              <PrivateRoute path="/contasareceber/lista" exact component={ContasReceber} />
              <PrivateRoute path="/relatorios/contasapagar/geral" exact component={RelatorioContasaPagarGeral} />
              <PrivateRoute path="/relatorios/contasapagar/contas" exact component={RelatorioContasaPagarContas} />
              <PrivateRoute path="/relatorios/contasapagar/fornecedores" exact component={RelatorioContasaPagarFornecedores} />
              <PrivateRoute path="/relatorios/contasapagar/porfornecedores" exact component={RelatorioContasaPagarPorFornecedores} />
              <PrivateRoute path="/relatorios/resultadofinanceiro" exact component={ResultadoFinanceiro} />
              <PrivateRoute path="/fluxocaixa/lista" exact component={FluxoCaixa} />
              <PrivateRoute path="/sair" exact component={Sair} />
              <PrivateRoute path="/usuario/trocarsenha" exact component={UsuarioSenha} />
            </Switch>
          </App>
        </BrowserRouter>
      </Provider>
    );
  }
}

export default (Routes);
