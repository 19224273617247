import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import FormEdit from '../components/FormEdit.js';
import { GetClientesEndAPI } from '../models/ModelClientesEnd.js';
import ClientesEnderecoUpdate from './ClientesEnderecoUpdate.js';

class ClientesEndereco extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = store.getState().clientes.enderecos;
    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;

    document.body.appendChild(script);
  }

  componentDidMount() {
    this.callApi(1)
  }

  componentDidUpdate(prevProps, prevState) {

    var response = {};
    if (//store.getState().clientes.enderecos.refresh===5 || 
      (store.getState().clientes.enderecos.refresh === 'listaendereco' &&
        store.getState().clientes.enderecos.id_cliente !== store.getState().clientes.cadastro.id
      )) {
      response = { type: 'CLIENTES.ENDERECO.TOOGLE_REFRESH' }
      this.updateRedux(response);
      this.callApi(1);
    }

    if (this.state.refresh === 'buscando') {
      response = {
        refresh: 'concluido',
        btnCadastro: <ClientesEnderecoUpdate
          id_cliente={this.state.id_cliente}
          id_cliente_end={this.state.id_cliente_end}
          refresh='buscando'
          returnComponentRefresh={this.returnComponentRefresh}
        />
      }

      this.setState(response);

    }

    if (this.state.refresh === 'limpando') {
      this.callApi(0);
      response = {
        refresh: 'concluido',
        id_cliente: this.state.id_cliente,
        id_cliente_end: 0,
        btnCadastro: this.btnCadastroNovo()
      }
      this.setState(response);

    }

  }

  btnCadastroNovo = () => {
    return (<><FormEdit
      id='cadNovoEnd'
      label='Novo Cadastro'
      styleDiv='submit'
      onClick={this.novoCadastro}
      styleField='submit'
      typeField='button'
      icon='adiciona' />

      <FormEdit
        id='AtualizaEnd'
        label='Atualizar'
        styleDiv='submit'
        onClick={() => this.callApi(1)}
        styleField='submit'
        typeField='button'
        icon='atualiza' />
    </>)
  }

  callBackPaginacao = (response) => {
    response.refresh = 'buscaclienteendereco';
    response.type = 'CLIENTES.ENDERECO.' + response.type;
    this.updateReduxAndState(response);
  }

  async callApi(modal) {
    var grids = store.getState().clientes.enderecos;

    var response = await GetClientesEndAPI(store.getState().clientes.cadastro.id, grids.limit, grids.offset, modal);
    response.refresh = 'concluido';
    response.callBackPaginacao = this.callBackPaginacao;
    response.btnCadastro = this.btnCadastroNovo();
    response.onClickModal = this.novoCadastro;
    response.id_cliente = store.getState().clientes.cadastro.id;

    response.type = 'CLIENTES.ENDERECO.TOOGLE_LISTA';
    this.updateRedux(response);
    grids = store.getState().clientes.enderecos;
    this.setState(grids);
  }



  novoCadastro = (event, id_cliente_end) => {
    event.preventDefault()

    this.setState({
      refresh: 'buscando',
      id_cliente_end: id_cliente_end
    });

  }


  render() {

    return (
      <div>
        <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">
          {<FormEdit id='sEndIdCliente' typeField='hidden'
            value={store.getState().clientes.enderecos.id_cliente}
          />}

          {<FormEdit id='sEndCnpj'
            label='Cliente'
            styleDiv='1'
            value={store.getState().clientes.enderecos.cliente}
            typeField='readonly'
          />}

        </form>
        {this.state.btnCadastro}
        {this.Grid(store.getState().clientes.enderecos)}

      </div>

    );

  }
}

export default ClientesEndereco;
