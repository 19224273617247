import React, { Component } from 'react';
import ReactDOM from 'react-dom';

class Modal extends Component {

  handleClose = (event) => {
    ReactDOM.unmountComponentAtNode(ReactDOM.findDOMNode(this).parentNode);
  }

  loadContent = () => {
    var content = this.props.content;
   
    if (typeof content === 'string') {
      return (<li>{content}</li>);
    } else {
      return content.map((item, key) =>
        <li key={key}>
          {item.item}
        </li>
      );
    }
  }

  render() {
    if (this.props.type === 'progress')
      return (
        <div>
          <div id="modal-overlay" className="modal-overlay">
          </div>
          <div id="progress" className="progress">
            <div className="indeterminate"></div>
          </div>
        </div>

      );

    if (this.props.type === 'warning' || this.props.type === 'information')
      return (
        <div>
          <div id="modal-overlay" className="modal-overlay"></div>
          <div id="modal1" className="modal open">
            <div className="modal-content">
              <h4>{this.props.title}</h4>
              <ul>{this.loadContent()}</ul>
            </div>
            <div className="modal-footer">

              <div className="input-field col s20 right">
                <button className="closeModal btn waves-effect waves-light"
                  type="submit"
                  name="closeModal"
                  onClick={this.handleClose}>
                  {this.props.button}
                  <i className="material-icons left white-text">close</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      );

    if (this.props.type === 'legend')
      return (
        <div>
          <div id="modal-overlay" className="modal-overlay"></div>
          <div id="modal1" className="modal open">
            <div className="modal-content">
              <h4>{this.props.title}</h4>
              {this.props.content}
            </div>
            <div className="modal-footer">
              <div className="input-field col s20 right">
                <button className="closeModal btn waves-effect waves-light"
                  type="submit"
                  name="closeModal"
                  onClick={this.handleClose}>
                  {this.props.button}
                  <i className="material-icons left white-text">close</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      );

    return '';

  }

}

export default Modal;
