import api from '../services/Api.js';
import { clearMessage, warningMessageCatch, progressBar } from '../services/Funcoes.js';


/**
 * Lista de Resultado Financeiro
 * @param {*} fAno
 */

export const GetResultadoFinanceiroAPI = (fAno) => {

  progressBar();


  return api.get('/relatorios/resultados?fano=' + fAno)
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        vendastotal: response.data.result.vendastotal,
        creditostotal: response.data.result.creditostotal,
        debitostotal: response.data.result.debitostotal,
        lucrototal: response.data.result.lucrototal,
        lucrorealtotal: response.data.result.lucrorealtotal,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}
