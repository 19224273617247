import React, { Component } from 'react';
import api from '../services/Api.js';
import {
  login, logout, setMenu, setMenuAcao, setEmpresa, setUsuario, setSisFormasPgto, setSisPlanoDebito, setSisPlanoItemDebito, setSisStatus, setSisUnidades, setSisTipoEnd, setSisEstados
} from '../services/Auth.js';
import '../css/Materialize.min.css';
import '../css/Login.css';
import { progressBar, warningMessageCatch } from '../services/Funcoes.js';

class Login extends Component {

  constructor(props) {
    super(props)
    this.state = {
      empresa: '',
      login: '',
      password: ''
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    progressBar();

    logout();

    api.post('/login', this.state)
      .then(response => {
        login(response.data.usuario.token);

        api.get('/usuario')
          .then(response => {
            setMenu(response.data.usuario.permissoes);
            setMenuAcao(response.data.usuario.permissoesAcao);
            setSisStatus(response.data.usuario.status);
            setSisUnidades(response.data.usuario.unidades);
            setSisFormasPgto(response.data.usuario.formasPgto);
            setSisPlanoDebito(response.data.usuario.plano_debito);
            setSisPlanoItemDebito(response.data.usuario.plano_item_debito);
            setSisEstados(response.data.usuario.estados);
            setSisTipoEnd(response.data.usuario.tipo_end);
            setEmpresa(response.data.usuario.empresa);
            setUsuario(response.data.usuario.emp_usu_nome);
            this.props.history.push("/home");

          })
          .catch(error => {
            warningMessageCatch(error.response);
          })

      })
      .catch(error => {
        warningMessageCatch(error.response);
      })


  }



  handleInputChange = (event) => {
    event.preventDefault()
    this.setState({
      [event.target.name]: event.target.value
    })
  }

  render() {

    var x = document.body;
    x.style.backgroundImage = "url(/assets/images/solucoes-para-seu-site.jpg)";

    x = document.getElementById('sys-header');
    x.style.display = "none";

    x = document.getElementById('sys-footer');
    x.style.display = "none";

    return (
      <div className="main_login radius-full effect-02">
        <figure><img src="/assets/images/logo50x50.png" title="Ferramenta de Gerenciamento de site" alt="[Ferramenta de Gerenciamento de site]" /></figure>
        <h1 className="size-4 font-normal color-7 align-c">Faça seu login!</h1>
        <form name="form" onSubmit={this.handleSubmit} action="" method="post" encType="multipart/form-data">
          <input type="text" name="empresa" id="empresa" title="Informe sua empresa" onChange={this.handleInputChange} value={this.state.empresa} required="on" placeholder="Informe sua empresa" />
          <input type="text" name="login" id="login" title="Informe seu e-mail" onChange={this.handleInputChange} value={this.state.login} required="on" placeholder="Informe seu e-mail" />
          <input type="password" name="password" id="password" title="Informe sua senha" onChange={this.handleInputChange} value={this.state.password} required="on" placeholder=".........." />
          <input type="submit" name="submit" value="Logar" title="Clique aqui para logar na Ferramenta de Gerenciamento de site" className="bt-login radius-full effect-02 ma-b" />
          <p className="tagline size-4 font-normal color-5 align-c"><mark className="font-medium color-8" id="login-erro"></mark></p>
        </form>
      </div>
    );

  }
}

export default Login;
