import api from '../services/Api.js';
import { clearMessage, warningMessage, warningMessageCatch, progressBar } from '../services/Funcoes.js';

/**
 * Autocomplete de Serviços
 * @param {*} fNome 
 */

export const GetServicosAutocompleteAPI = (fNome) => {
  return api.get('/servico/autocomplete?fnome=' + fNome)
    .then(response => {
      return {
        lista: response.data.result.lista,
        erro: 0
      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })
}
/**
 * Lista de Serviços
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome 
 */

export const GetServicosAPI = (limit, offset, fNome, fid_sis_status) => {

  progressBar();


  return api.get('/servico/lista?limit=' + limit + '&offset=' + offset + '&fnome=' + fNome +
    '&fid_sis_status=' + fid_sis_status)
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        pages: response.data.result.pages,
        limit: response.data.result.limit,
        offset: response.data.result.offset,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}


/* 
 * Busca Servico
 * @param {*} id  
 */
export const GetServicoAPI = (id) => {
  progressBar();

  return api.get('/servico/' + id)
    .then(response => {
      clearMessage();
      var ret = response.data.result;
      ret.id = id;
      ret.id_servico = id;
      ret.refresh = 'concluido';
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }

    })

}

/* 
 * Cadastro do Servico
 * @param {*} id 
 * @param {*} data 
 */
export const PostServicoAPI = (id, data) => {
  progressBar();

  var url = '';
  if (id === 0) {
    url = '/servico/add';
  } else {
    url = '/servico/' + id;
  }

  return api.post(url, data)
    .then(response => {
      warningMessage(response.data.result.mensagem);

      return {
        erro: 0,
        refresh: 'concluido'
      }

    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }
    })

}
