import React from 'react';
import ComponentErp from './ComponentErp.js';
import { getSisTipoEnd, getSisEstados } from '../services/Auth.js';
import FormEdit from '../components/FormEdit.js';
import { GetFornecedorEndAPI, PostFornecedorEndAPI } from '../models/ModelFornecedoresEnd.js';
import { GetEnderecosAPI, GetEnderecoCepAPI } from '../models/ModelEnderecos.js';


class FornecedoresEnderecoUpdate extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = {
      refresh:  'concluido', 
      tipo_end: getSisTipoEnd(),
      estados: getSisEstados(),
      cidades: null,
      id_fornecedor: this.props.id_fornecedor,
      id_fornecedor_end: this.props.id_fornecedor_end,
      sid_fornecedor_end: '',
      sid_sis_tp_end: '',
      sforn_endereco: '',
      sforn_numero: '',
      sforn_complemento: '',
      sforn_bairro: '',
      sforn_cep: '',
      sid_pais: '',
      sid_estado: '',
      sid_cidade: '',
      sforn_principal: ''
    }

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;

    document.body.appendChild(script);

  }

  componentDidMount() {
    if (this.state.id_fornecedor > 0 && this.state.id_fornecedor_end > 0) {
      this.callApiGetFornecedorEnd(this.state.id_fornecedor, this.state.id_fornecedor_end)
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.callApi();
      this.setState({
        refresh: 'concluido'
      })
    }

    if (this.props.refresh === 'buscando' && this.props.id_fornecedor_end !== this.state.id_fornecedor_end) {
      this.setState({
        refresh: this.props.refresh,
        id_fornecedor: this.props.id_fornecedor,
        id_fornecedor_end: this.props.id_fornecedor_end
      })
      this.callApiGetFornecedorEnd(this.props.id_fornecedor, this.props.id_fornecedor_end)
    }

    if (this.props.refresh === 'limpando' && this.state.id_fornecedor_end > 0) {
      this.setState({
        refresh: 'concluido',
        id_fornecedor_end: 0,
        sid_fornecedor_end: '',
        sid_sis_tp_end: '',
        sforn_endereco: '',
        sforn_numero: '',
        sforn_complemento: '',
        sforn_bairro: '',
        sforn_cep: '',
        sid_pais: '',
        sid_estado: '',
        sid_cidade: '',
        sforn_principal: ''

      })
    }

  }

  async callApi() {
    let data =
    {
      id_fornecedor_end: this.state.sid_fornecedor_end,
      id_sis_tp_end: this.state.sid_sis_tp_end,
      forn_endereco: this.state.sforn_endereco,
      forn_numero: this.state.sforn_numero,
      forn_complemento: this.state.sforn_complemento,
      forn_bairro: this.state.sforn_bairro,
      forn_cep: this.state.sforn_cep,
      id_pais: this.state.sid_pais,
      id_estado: this.state.sid_estado,
      id_cidade: this.state.sid_cidade,
      forn_principal: this.state.sforn_principal
    }

    var response = await PostFornecedorEndAPI(this.state.id_fornecedor, this.state.id_fornecedor_end, data);
    if (response.erro === 0) {
      this.props.returnComponentRefresh();
    }
  }


  async callApiGetFornecedorEnd(id_fornecedor, id_fornecedor_end) {

    var response = await GetFornecedorEndAPI(id_fornecedor, id_fornecedor_end);
    if (response.sid_estado > 0) {
      var responseCid = await GetEnderecosAPI(response.sid_estado);
      response.cidades = responseCid.cidades;
    } else {
      response.cidades = null;
    }
    this.setState(
      response
    );
  }

  handleInputCep = (event) => {
    this.handleInputChange(event)

    if(event.target.value.length===9)
      this.callApiGetCep(event.target.value)
  }

  async callApiGetCep(cep) {
    if (cep.length=== 9) {
      var response = await GetEnderecoCepAPI(cep.replace('-',''));
      if(response.endereco!==undefined){
        this.setState({
          sforn_endereco: response.endereco.endereco,
          sforn_numero: '',
          sforn_complemento: '',
          sforn_bairro: response.endereco.bairro,
          sid_estado: response.endereco.id_estado,
          sid_cidade: response.endereco.id_cidade,
          cidades: response.cidades
          });
      }
    } else {
      this.setState(
        { cidades: null }
      );
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.callApi()

  }

  handleCancel = (event) => {
    event.preventDefault()
    this.props.returnComponentRefresh();

  }

  render() {

    return (
      <form name="form" 
      onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">
        {<FormEdit id='id_fornecedor_end' typeField='hidden' 
          value={this.state.id_fornecedor_end}
        />}

        {<FormEdit id='sid_sis_tp_end'
          label='Tipo de Endereço'
          styleDiv='2'
          onChange={this.handleInputChange}
          typeField='select'
          value={this.state.sid_sis_tp_end}
          required='on'
          optionsField={this.state.tipo_end}
        />}

        {<FormEdit id='sforn_cep'
          label='CEP'
          styleDiv='2'
          onChange={this.handleInputCep}
          value={this.state.sforn_cep}
          mask="cep"
          required='on'
        />}

        {<FormEdit id='sforn_endereco'
          label='Endereço'
          styleDiv='1'
          onChange={this.handleInputChange}
          value={this.state.sforn_endereco}
          required='on'
        />}

        {<FormEdit id='sforn_numero'
          label='Número'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.sforn_numero}
          mask="numero"
        />}

        {<FormEdit id='sforn_complemento'
          label='Complemento'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.sforn_complemento}
        />}

        {<FormEdit id='sforn_bairro'
          label='Bairro'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.sforn_bairro}
          required='on'
        />}

        {<FormEdit id='sid_estado'
          label='Estado'
          styleDiv='2'
          onChange={this.handleInputEstado}
          typeField='select'
          value={this.state.sid_estado}
          required='on'
          optionsField={this.state.estados}
        />}

        {<FormEdit id='sid_cidade'
          label='Cidade'
          styleDiv='2'
          onChange={this.handleInputChange}
          typeField='select'
          value={this.state.sid_cidade}
          required='on'
          optionsField={this.state.cidades}
        />}

        {<FormEdit id='sforn_principal'
          label='Principal?'
          styleDiv='2'
          typeField='checkbox'
          onChange={this.handleInputChange}
          value={this.state.sforn_principal}
        />}


        {<FormEdit id='cancelar'
          label='Cancelar'
          styleDiv='submit right'
          styleField='submit'
          typeField='button'
          onClick={this.handleCancel}
          icon='cancelar'
        />}

        {<FormEdit id='gravar'
          label='Gravar'
          styleDiv='submit right'
          styleField='submit'
          typeField='submit'
          icon='gravar'
        />}



      </form>

    );

  }
}

export default FornecedoresEnderecoUpdate;
