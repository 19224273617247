import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import FormEdit from '../components/FormEdit.js';
import { GetResultadoFinanceiroAPI } from '../models/ModelResultadoFinanceiro.js';


class ResultadoFinanceiro extends ComponentErp {
  constructor(props) {
    super(props);
    this.state = store.getState().resultadoFinanceiro.grids;
  }

  componentDidMount() {
    this.callApi()
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.setState({ refresh: 'buscando' });
      this.callApi()
    }

  }

  handleSubmit = (event) => {
    event.preventDefault()
    var response = {
      refresh: 'gravando',
      fAno: this.state.fAno,
      type: 'RESULTADOFINANCEIRO.TOOGLE_FBUSCA'
    }
    this.updateReduxAndState(response);

  }

  async callApi() {
    var grids = store.getState().resultadoFinanceiro.grids;

    var response = await GetResultadoFinanceiroAPI(grids.fAno);
    response.refresh = 'concluido';
    response.type = 'RESULTADOFINANCEIRO.TOOGLE_LISTA';

    this.updateRedux(response);
    grids = store.getState().fluxoCaixa.grids;
    this.setState(grids);
  }



  render() {

    return (

      <div className="container">
        <div className="row">
          <h2 className="section-title">Resultado Financeiro</h2>
          <div className="card">
            {this.navMenu(store.getState().resultadoFinanceiro.grids.nav)}

            <div id="lista" className="dataTables_wrapper no-footer row">
              <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">

                {<FormEdit id='fAno'
                  label='Ano'
                  value={this.state.fAno}
                  onChange={this.handleInputChange}
                  typeField='number'
                  styleDiv='4' />}

                {<FormEdit id='submit'
                  label='Pesquisar'
                  styleDiv='submit'
                  styleField='submit'
                  typeField='submit'
                  icon='pesquisa'
                />}

              </form>

              {this.GridFull(store.getState().resultadoFinanceiro.grids)}
            </div>
            <div className="row s1">
              <div className="row">
                <div className="col s2" style={{ width: '160px' }}>
                  <i class="material-icons green-text text-lighten-2 left">bookmark</i>
                  <p>Vendas:</p>
                </div>
                <div className="col s2">
                  <p>{store.getState().resultadoFinanceiro.grids.vendastotal}</p>
                </div>
              </div>
              <div className="row">
                <div className="col s2" style={{ width: '160px' }}>
                  <i class="material-icons  red-text left">bookmark</i>
                  <p>Compras:</p>
                </div>
                <div className="col s2">
                  <p>{store.getState().resultadoFinanceiro.grids.debitosopertotal}</p>
                </div>
              </div>
              <div className="row">
                <div className="col s2" style={{ width: '160px' }}>
                  <i class="material-icons  gray-text left">bookmark</i>
                  <p>Lucro Operacional:</p>
                </div>
                <div className="col s4">
                  <p>{store.getState().resultadoFinanceiro.grids.lucrototal}</p>
                </div>
              </div>
              <div className="row">
                <div className="col s2" style={{ width: '160px' }}>
                  <i class="material-icons green-text text-lighten-2 left">bookmark</i>
                  <p>Créditos:</p>
                </div>
                <div className="col s2">
                  <p>{store.getState().resultadoFinanceiro.grids.creditostotal}</p>
                </div>
              </div>
              <div className="row">
                <div className="col s2" style={{ width: '160px' }}>
                  <i class="material-icons  red-text left">bookmark</i>
                  <p>Débitos:</p>
                </div>
                <div className="col s2">
                  <p>{store.getState().resultadoFinanceiro.grids.debitostotal}</p>
                </div>
              </div>
              <div className="row">
                <div className="col s2" style={{ width: '160px' }}>
                  <i class="material-icons  gray-text left">bookmark</i>
                  <p>Lucro Financeiro:</p>
                </div>
                <div className="col s2">
                  <p>{store.getState().resultadoFinanceiro.grids.lucrorealtotal}</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

    );

  }
}
export default (ResultadoFinanceiro);
