import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import FormEdit from '../components/FormEdit.js';
import { getSisEstoque } from '../services/Auth.js';
import { GetProdutosAPI } from '../models/ModelProdutos.js';
import ProdutosUpdate from './ProdutosUpdate.js';


class Produtos extends ComponentErp {
  constructor(props) {
    super(props);
    this.state = store.getState().produtos.grids;
  }

  componentDidMount() {
    this.callApi()
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.setState({ refresh: 'buscando' });
      this.callApi()
    }

  }

  handleSubmit = (event) => {
    event.preventDefault()

    var response = {
      refresh: 'gravando',
      fNome: this.state.fNome,
      id_fornecedor: this.state.id_fornecedor,
      nome_fornecedor: this.state.nome_fornecedor,
      fSituacao: this.state.fSituacao,
      type: 'PRODUTOS.TOOGLE_FBUSCA'
    }

    this.updateReduxAndState(response);

  }

  novoCadastro = (event) => {
    event.preventDefault()
    var response = { refresh: 'limpando', id: 0, type: 'PRODUTOS.TOOGLE_CLEAN' }
    this.updateReduxAndState(response);
    this.actionClick('.cadastro');
  }


  clickChildCadastro = (event, id) => {

    event.preventDefault()
    var response = '';
    if (id !== 0 && id !== store.getState().produtos.cadastro.id_produto) {

      response = {
        id: id,
        type: 'PRODUTOS.TOOGLE_CADASTRO',
        refresh: 'buscaproduto'
      }
      this.updateReduxAndState(response);

      this.actionClick('.cadastro');
      this.clearModal();

    } else if (id === 0) {
      response = { type: 'PRODUTOS.TOOGLE_CLEAN', refresh: 'limpando' }
      this.updateReduxAndState(response);
    }
    if (this.state.aba !== 'Cadastro') {
      this.actionClick('.cadastro');
    }
    this.setAba('Cadastro');

  }

  async callApi() {
    var grids = store.getState().produtos.grids;

    var response = await GetProdutosAPI(grids.limit, grids.offset, grids.fNome, grids.id_fornecedor, grids.fSituacao);
    response.refresh = 'concluido';
    response.onClickModal = this.clickChildCadastro;
    response.callBackPaginacao = this.callBackPaginacao;

    let nav = store.getState().produtos.grids.nav;
    nav.onClick[0] = this.clickChildListaAba;
    nav.onClick[1] = null;
    response.nav = nav;

    response.type = 'PRODUTOS.TOOGLE_LISTA';

    this.updateRedux(response);
    grids = store.getState().produtos.grids;
    this.setState(grids);

  }



  render() {

    return (

      <div className="container">
        <div className="row">
          <h2 className="section-title">Produtos</h2>
          <div className="card">
            {this.navMenu(store.getState().produtos.grids.nav)}

            <div id="lista" className="dataTables_wrapper no-footer row">
              <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">

                {<FormEdit id='fNome'
                  onChange={this.handleInputChange}
                  label='Busque por nome'
                  value={this.state.fNome}
                  styleDiv='4' />}

                {<FormEdit id='fSituacao'
                  label='Situação'
                  styleDiv='4'
                  typeField='select'
                  value={this.state.fSituacao}
                  required='on'
                  onChange={this.handleInputChange}
                  optionsField={getSisEstoque()}
                />}

                {<FormEdit id='nome_fornecedor'
                  label='Nome Fornecedor'
                  styleDiv='2'
                  typeField='autocomplete'
                  onChange={this.handleInputChangeFornecedor}
                  loadData={this.loadDataFornecedor}
                  options={[(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }]}
                  value={(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }}

                  required='on'
                />}

                {<FormEdit id='submit'
                  label='Pesquisar'
                  styleDiv='submit'
                  styleField='submit'
                  typeField='submit'
                  icon='pesquisa'
                />}

                {<FormEdit id='cadNovo'
                  label='Novo Cadastro'
                  styleDiv='submit'
                  onClick={this.novoCadastro}
                  styleField='submit'
                  typeField='button'
                  icon='adiciona'
                />}

              </form>
              {this.Grid(store.getState().produtos.grids)}

            </div>
            <div id="cadastro" className="dataTables_wrapper no-footer row">
              {<ProdutosUpdate />}
            </div>
          </div>
        </div>
      </div>

    );

  }
}
export default (Produtos);
