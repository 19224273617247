const INITIAL_STATE = {
    grids:
    {
        redux_prefix: 'COLABORADORES.',
        header: ['ID', 'CPF', 'Nome', 'Status'],
        colunas: ['id_empresa_usu', 'emp_usu_cpf', 'emp_usu_nome', 'status'],
        checkbox: '',
        styles: ['50px', '', '', ''],
        lista: [],

        limit: 25,
        offset: 1,
        pages: 0,
        fNome: '',
        fStatus: '',
        fid_sis_status: '',
        permissoes: null,
        senha: null,
        nav: {
            tabs: ['Lista de Colaboradores', 'Cadastrar', 'Permissões','Senha'],
            className: ['lista active', 'cadastro', 'permissoes disabled','senha disabled'],
            disabled: [null, null, 'disabled','disabled'],
            href: ['#lista', '#cadastro', '#permissoes','#senha'],
            onClick: [null, null, null,null]
        },
        edit: {
            itens: ['Alterar Cadastro', 'Alterar Permissões', 'Alterar Senha'],
            onClick: ['', '']
        },
        onClickModal: '',
        id: 0,
        refresh: 'concluido' //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
    },
    cadastro:
    {
        refresh: 'concluido', //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        id: 0,
        id_empresa_usu: '',
        emp_usu_nome: '',
        emp_usu_cpf: '',
        id_sis_status: '',
        emp_usu_telefone: '',
        emp_usu_ramal: '',
        emp_usu_celular: '',
        emp_usu_email: ''
    },
    permissoes:
    {
        refresh: 'concluido', //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        colaborador: '',
        id_empresa_usu: 0,
        lista: []
    },
    senha:
    {
        refresh: 'concluido', //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        colaborador: '',
        id_empresa_usu: 0
    }


}

export default function colaboradores(state = INITIAL_STATE, action) {
    var grid = state.grids;
    var cadastro = state.cadastro;
    var permissoes = state.permissoes;
    var senha = state.senha;
    
    if (action.type === 'COLABORADORES.TOOGLE_LISTA') {
        grid.erro = action.erro;
        if (grid.erro === 0) {
            grid.lista = action.lista;
            grid.onClickModal = action.onClickModal;
            grid.callBackPaginacao = action.callBackPaginacao;
            grid.pages = action.pages;
            grid.limit = action.limit;
            grid.offset = action.offset;
            grid.edit.onClick = action.editOnClick;
        }
        return { ...state, grid }
    }

    if (action.type === 'COLABORADORES.TOOGLE_FBUSCA') {
        grid.fNome = action.fNome;
        grid.fStatus = action.fStatus;
        return { ...state, grid }
    }

    if (action.type === 'COLABORADORES.TOOGLE_LIMIT') {
        grid.offset = 1;
        grid.limit = action.limit;
        return { ...state, grid }
    }

    if (action.type === 'COLABORADORES.TOOGLE_OFFSET') {
        grid.offset = action.offset;
        return { ...state, grid }
    }

    if (action.type === 'COLABORADORES.TOOGLE_CADASTRO') {
        cadastro.id = action.id;
        cadastro.refresh = 'buscando';
        return { ...state, cadastro }
    }

    if (action.type === 'COLABORADORES.TOOGLE_REFRESH') {
        cadastro.refresh = 'concluido';
        return { ...state, cadastro }
    }

    if (action.type === 'COLABORADORES.TOOGLE_GET_ID') {
        cadastro.refresh = 'concluido';
        cadastro.id_empresa_usu = action.id_empresa_usu;
        cadastro.id_sis_status = action.id_sis_status;
        cadastro.emp_usu_nome = action.emp_usu_nome;
        cadastro.emp_usu_cpf = action.emp_usu_cpf;
        cadastro.emp_usu_email = action.emp_usu_email;
        cadastro.emp_usu_telefone = action.emp_usu_telefone;
        cadastro.emp_usu_ramal = action.emp_usu_ramal;
        cadastro.emp_usu_celular = action.emp_usu_celular;
        return { ...state, cadastro }
    }

    if (action.type === 'COLABORADORES.TOOGLE_CLEAN') {
        cadastro.refresh = action.refresh;
        cadastro.id = 0;
        cadastro.id_empresa_usu = 0;
        cadastro.id_sis_status = '';
        cadastro.emp_usu_nome = '';
        cadastro.emp_usu_cpf = '';
        cadastro.emp_usu_email = '';
        cadastro.emp_usu_telefone = '';
        cadastro.emp_usu_ramal = '';
        cadastro.emp_usu_celular = '';
        return { ...state, cadastro }
    }


    if (action.type === 'COLABORADORES.PERMISSOES.TOOGLE_CLEAN') {
        permissoes.refresh = action.refresh;
        permissoes.id = 0;
        permissoes.id_empresa_usu = 0;
        return { ...state, cadastro }
    }

    if (action.type === 'COLABORADORES.PERMISSOES.TOOGLE_REFRESH') {
        permissoes.refresh = 'concluido';
        grid.refresh = 'concluido';
        return { ...state, permissoes, grid }
    }

    if (action.type === 'COLABORADORES.PERMISSOES.TOOGLE_ID_COLABORADOR') {
        permissoes.refresh = action.refresh;
        cadastro.id = action.id_empresa_usu;
        grid.nav.disabled[2] = null;
        grid.nav.disabled[3] = null;
        grid.permissoes = action.permissoes;


        return { ...state, cadastro, permissoes, grid }
    }

    if (action.type === 'COLABORADORES.PERMISSOES.TOOGLE_LISTA') {
        permissoes.erro = action.erro;
        if (permissoes.erro === 0) {
            permissoes.id_empresa_usu = action.id_empresa_usu;
            permissoes.lista = action.lista;
            permissoes.colaborador = action.colaborador;
        }

        return { ...state, permissoes }
    }

    if (action.type === 'COLABORADORES.SENHA.TOOGLE_REFRESH') {
        senha.refresh = 'concluido';
        grid.refresh = 'concluido';
        return { ...state, senha, grid }
    }

    if (action.type === 'COLABORADORES.SENHA.TOOGLE_ID_COLABORADOR') {
        senha.refresh = action.refresh;
        cadastro.id = action.id_empresa_usu;
        grid.nav.disabled[2] = null;
        grid.nav.disabled[3] = null;
        grid.senha = action.senha;


        return { ...state, cadastro, senha, grid }
    }

    if (action.type === 'COLABORADORES.SENHA.TOOGLE_LISTA') {
        senha.erro = action.erro;
        if (senha.erro === 0) {
            senha.id_empresa_usu = action.id_empresa_usu;
            senha.colaborador = action.colaborador;
        }

        return { ...state, senha }
    }

    return state;
}