import api from '../services/Api.js';
import { clearMessage, warningMessageCatch, progressBar } from '../services/Funcoes.js';

/**
 * Lista de Lançamento Financeiro por cliente
 * @param {*} id 
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} modal 
 */
export const GetClientesFinAPI = (id, limit, offset, modal) => {

  if (modal === 1)
    progressBar();


  return api.get('/cliente/' + id + '/financeiro/lista?limit=' + limit + '&offset=' + offset)
    .then(response => {
      if (modal === 1)
        clearMessage();
      return {
        lista: response.data.result.lista,
        pages: response.data.result.pages,
        limit: response.data.result.limit,
        offset: response.data.result.offset,
        cliente: response.data.result.cliente,
        erro: 0
      }
    })
    .catch(error => {
      if (modal === 1)
        warningMessageCatch(error.response);
      return {
        error: 1
      }

    })



}

