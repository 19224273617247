import React from 'react';

export default function Paginacao (grids){

  function handleInputChange (event, callBackPaginacao) {
    var dispatch = {type:'TOOGLE_LIMIT', limit: event.target.value}; 
    callBackPaginacao(dispatch);
  }
  
  function handleInputNav (key, callBackPaginacao) {
    var page = key;
    
    if(key<1)
       page = 1;

    if(key>grids.pages)
       page = grids.pages;

    if(page.toString()!==grids.offset.toString()){
      var dispatch = {type:'TOOGLE_OFFSET', offset: page}; 
      callBackPaginacao(dispatch);
    }


  }

  return (
    
    <div className="footer-wrapper">
      <div className="dataTables_length" id="table-custom-elements_length">
        <label>Exibir 
          <div className="select-wrapper">

            <select name="limit" defaultValue={grids.limit} 
                aria-controls="table-custom-elements"
                onChange={(event) => handleInputChange(event,grids.callBackPaginacao)}
                tabIndex="-1">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div> registros
        </label>
      </div>
      <div className="paging-info">
        <div className="dataTables_info" id="table-custom-elements_info" role="status" aria-live="polite">
          Página {grids.offset} de {grids.pages}
        </div>
        <div className="dataTables_paginate paging_full" id="table-custom-elements_paginate">
          <div onClick={() => handleInputNav('1',            grids.callBackPaginacao)} className={grids.offset>1 ? "paginate_button first waves-effect" : "paginate_button first waves-effect disabled"} aria-controls="table-custom-elements" data-dt-idx="0" tabIndex="0" id="table-custom-elements_first">First</div>
          <div onClick={() => handleInputNav(parseInt(grids.offset)-1, grids.callBackPaginacao)} className={parseInt(grids.offset-1)>=1 ? 'paginate_button previous waves-effect': 'paginate_button previous waves-effect disabled' } aria-controls="table-custom-elements" data-dt-idx="1" tabIndex="1" id="table-custom-elements_previous">Previous</div>
          <div onClick={() => handleInputNav(parseInt(grids.offset)+1, grids.callBackPaginacao)} className={parseInt(parseInt(grids.offset)+1)<=parseInt(grids.pages) ? 'paginate_button next waves-effect': 'paginate_button next waves-effect disabled'} aria-controls="table-custom-elements" data-dt-idx="2" tabIndex="2" id="table-custom-elements_next">Next</div>
          <div onClick={() => handleInputNav(grids.pages,    grids.callBackPaginacao)} className={grids.offset<grids.pages ? 'paginate_button last waves-effect':'paginate_button last waves-effect disabled'} aria-controls="table-custom-elements" data-dt-idx="3" tabIndex="3" id="table-custom-elements_last">Last</div>
        </div>
      </div>
    </div>

  );
            
  
}


