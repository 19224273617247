import React from 'react';

function montaHeader(header, coluna, styles, onClick) {
  if (header.length === 0)
    return (<thead><tr><th></th></tr></thead>);

  var colunas = header.map((item, key) =>
    <th key={item} style={{ width: styles[key] }} >{item}</th>);
  var checkbox = <th className="dataTables-checkbox-column sorting_disabled" style={{ width: '50px' }}><label><input className="filled-in select-all" name="select_all" value="1" type="checkbox" /><span></span></label></th>
  var colunaEdit = '';
  if (onClick !== undefined)
    colunaEdit = <td></td>;

  if (coluna !== '')
    return (<thead><tr>{colunaEdit}{checkbox}{colunas}</tr></thead>);
  else
    return (<thead><tr>{colunaEdit}{colunas}</tr></thead>);
}

function montaCheckBox(item, campo) {
  if (!campo)
    return;

  return (
    <td className="dataTables-checkbox-column">
      <label>
        <input className="filled-in" type="checkbox" name="id[]" value={item[campo]} /><span></span>
      </label>
    </td>);
}

function montaBody(header, lista, colunas, checkbox, onClick) {

  if (lista.length > 0) {
    return <tbody>{lista.map((item, key) =>
      <tr key={key} role="row" className={(key % 2 === 0) ? 'odd' : 'even'}>
        {montaCheckBox(item, checkbox)}
        {montaMenu(item, colunas, onClick)}
        {montaCols(header, item, colunas)}
      </tr>
    )}</tbody>;
  } else {
    return <tbody><tr><td>-</td></tr></tbody>;
  }
}

function montaCols(header, lista, colunas) {
  if (colunas.length > 0) {
    return colunas.map((item, keys) =>
      <td key={'td' + keys} data-title={header[keys]} className={(item === 'color') ? lista[item] : ''}>{(item !== 'color') ? lista[item] : ''}</td>
    );
  } else {
    return <td>-</td>;
  }
}

function montaMenu(lista, colunas, onClick) {
  if (colunas.length > 0 && onClick !== undefined) {
    return <td key={'edit' + lista[colunas[0]]}
      data-title='Ação'
      onClick={(e) => onClick(e, lista[colunas[0]])}
      style={{ width: '50px' }}>
      <a href='#!'>
        <i className="material-icons left" >edit</i>
      </a>
    </td>;
  } else {
    return '';
  }
}

export default function Grid(grids) {

  return (
    <div className="table-responsive-vertical">
      <table id="table-custom-elements" className="row-border dataTable" cellSpacing="0" width="100%" role="grid" aria-describedby="table-custom-elements_info">
        {montaHeader(grids.header, grids.checkbox, grids.styles, grids.onClickModal)}
        {montaBody(grids.header, grids.lista, grids.colunas, grids.checkbox, grids.onClickModal)}
      </table>
    </div>
  )
}

