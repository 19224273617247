import React, { Component } from 'react';

class Footer extends Component {

    render() {
        return (
            <div className="container">
                <div className="row">
                </div>
            </div>
        );


    }
}

export default Footer;
