import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import FormEdit from '../components/FormEdit.js';
import { GetColaboradorPermissoesAPI, PostColaboradorPermissoesAPI } from '../models/ModelColaboradores.js';

class ColaboradoresPermissoes extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = store.getState().colaboradores.permissoes;

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;

    document.body.appendChild(script);
  }

  componentDidMount() {
    if (store.getState().colaboradores.permissoes.id_empresa_usu !== store.getState().colaboradores.cadastro.id) {
      this.callApiGetColaboradorPermissoes()
    }
  }

  componentDidUpdate(prevProps, prevState) {

    var response = {};
    if ((store.getState().colaboradores.permissoes.refresh === 'listapermissoes' &&
      store.getState().colaboradores.permissoes.id_empresa_usu !== store.getState().colaboradores.cadastro.id
    )) {
      response = { type: 'COLABORADORES.PERMISSOES.TOOGLE_REFRESH' }
      this.callApiGetColaboradorPermissoes()
    }

    if (this.state.refresh === 'buscando') {
      response = {
        refresh: 'concluido'
      }
      this.setState(response);
    }

    if (this.state.refresh === 'limpando') {
      response = {
        refresh: 'concluido',
        id_empresa_usu: this.state.id_empresa_usu,

      }
      this.setState(response);

    }

  }

  async callApiGetColaboradorPermissoes() {
    var response = {}
    if (store.getState().colaboradores.cadastro.id > 0) {
      response = await GetColaboradorPermissoesAPI(store.getState().colaboradores.cadastro.id);
      response.refresh = 'concluido';
      response.type = 'COLABORADORES.PERMISSOES.TOOGLE_LISTA';
      this.updateRedux(response);
    } else {
      response = {
        refresh: 'concluido',
        id_empresa_usu: '',
        type: 'COLABORADORES.PERMISSOES.TOOGLE_CLEAN'
      };
      this.updateRedux(response);
    }

    var permissoes = store.getState().colaboradores.permissoes;
    this.setState(permissoes);

  }

  async callApi() {
    let data =
    {
      lista: this.state.lista
    }
    var response = await PostColaboradorPermissoesAPI(this.state.id_empresa_usu, data);
    this.setState(response);

  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.callApi()
  }

  handleInputChangeArray = (event) => {
    var lista = this.state.lista;
    lista[event.target.name.substr(12)].permissao = parseInt(event.target.value);
    this.setState({
      lista: lista
    });
  }

  montaFormulario = (lista) => {
    if (lista.length === 0)
      return (<></>);
    var fields = lista.map((id, key) =>
      <div key={key} >
        <FormEdit id={'id_sis_menu_' + key}
          label={lista[key].menu}
          styleDiv='3'
          onChange={this.handleInputChangeArray}
          styleField='checkbox'
          typeField='checkbox'
          value={lista[key].permissao}
        />

      </div>);
    return (<div className="input-field col s12">{fields}</div>);
  }

  render() {

    return (
      <div>
        <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">
          {<FormEdit id='sColaboradorNome'
            label='Colaborador'
            styleDiv='1'
            value={this.state.colaborador}
            typeField='readonly'
          />}

          {this.montaFormulario(this.state.lista)}

          <div className="input-field col s3">
            {<FormEdit id='cadGravaPermissao'
              label='Gravar'
              styleDiv='submit'
              styleField='submit'
              typeField='button'
              icon='salvar'
            />}
          </div>
        </form>
      </div>

    );

  }
}

export default ColaboradoresPermissoes;
