import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import FormEdit from '../components/FormEdit.js';
import { PostColaboradorSenhaAPI, GetColaboradorPermissoesAPI } from '../models/ModelColaboradores.js';

class ColaboradoresSenha extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = {
      sNovaSenha: '',
      sConfirmaSenha: ''
    };
    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;

    document.body.appendChild(script);


  }

  componentDidMount() {
    if (store.getState().colaboradores.senha.id_empresa_usu !== store.getState().colaboradores.cadastro.id) {
      this.callApiGetColaborador()
    }
  }

  componentDidUpdate(prevProps, prevState) {

    var response = {};
    if ((store.getState().colaboradores.senha.refresh === 'listasenha' &&
      store.getState().colaboradores.senha.id_empresa_usu !== store.getState().colaboradores.cadastro.id
    )) {
      response = { type: 'COLABORADORES.SENHA.TOOGLE_REFRESH' }
      this.callApiGetColaborador()
    }

    if (this.state.refresh === 'buscando') {
      response = {
        refresh: 'concluido'
      }
      this.setState(response);
    }

    if (this.state.refresh === 'limpando') {
      response = {
        refresh: 'concluido',
        id_empresa_usu: this.state.id_empresa_usu,

      }
      this.setState(response);

    }



  }

  async callApiGetColaborador() {
    var response = {}
    if (store.getState().colaboradores.cadastro.id > 0) {
      response = await GetColaboradorPermissoesAPI(store.getState().colaboradores.cadastro.id);
      response.refresh = 'concluido';
      response.type = 'COLABORADORES.SENHA.TOOGLE_LISTA';
      this.updateRedux(response);
    } else {
      response = {
        refresh: 'concluido',
        id_empresa_usu: '',
        type: 'COLABORADORES.SENHA.TOOGLE_CLEAN'
      };
      this.updateRedux(response);
    }
    var senha = store.getState().colaboradores.senha;
    this.setState(senha);

  }

  async callApi() {
    let data =
    {
      colaborador: true,
      id_empresa_usu: this.state.id_empresa_usu,
      novasenha: this.state.sNovaSenha,
      confirma: this.state.sConfirmaSenha
    }

    var response = await PostColaboradorSenhaAPI(data);
    this.setState(response);
    this.setState({
      sNovaSenha: '',
      sConfirmaSenha: ''
    });
  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.callApi()
  }


  render() {
    return (
      <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">

        {<FormEdit id='sColaboradorNome'
          label='Colaborador'
          styleDiv='1'
          value={this.state.colaborador}
          typeField='readonly'
        />}

        {<FormEdit id='sNovaSenha'
          label='Nova Senha'
          styleDiv='2'
          typeField="password"
          onChange={this.handleInputChange}
          value={this.state.sNovaSenha}
        />}

        {<FormEdit id='sConfirmaSenha'
          label='Confirme a nova senha'
          styleDiv='2'
          typeField="password"
          onChange={this.handleInputChange}
          value={this.state.sConfirmaSenha}
        />}

        {<FormEdit id='gravar'
          label='Gravar'
          styleDiv='submit right'
          styleField='submit'
          typeField='submit'
          icon='gravar'
        />}
      </form>

    );

  }
}


export default ColaboradoresSenha;
