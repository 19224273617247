import React from 'react';
import ComponentErp from './ComponentErp.js';

import store from '../store';
import { getSisTipoVenda, getSisUnidades, getFormasPgto, getMenuAcao } from '../services/Auth.js';

import FormEdit from '../components/FormEdit.js';
import Grid from '../components/Grid.js';
import { GetComandaAPI, PostComandaAPI, DelVendaItemServ, DelVendaItemProd, PostFecharComanda, PostReabrirComanda, PostRecebimento } from '../models/ModelComandas.js';

class ComandasUpdate extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = store.getState().comandas.cadastro;
    this.state.id_tem = '';
    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;


    document.body.appendChild(script);

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.callApi();
      this.setState({
        refresh: 'concluido'
      })
    }

    if (this.state.refresh === 'concluidoRefresh') {
      this.setState({
        refresh: 'concluido'
      })
    }


    var response = {}
    if (store.getState().comandas.cadastro.refresh === 'buscando' &&
      store.getState().comandas.cadastro.id !== store.getState().comandas.cadastro.id_venda
    ) {
      response = { type: 'COMANDAS.TOOGLE_REFRESH' }
      this.updateRedux(response);
      this.callApiGetComanda(store.getState().comandas.cadastro.id)
    }

    if (store.getState().comandas.cadastro.refresh === 'limpando') {
      response = { refresh: 'concluido', type: 'COMANDAS.TOOGLE_CLEAN' }
      this.updateRedux(response);
      var cadastro = store.getState().comandas.cadastro;
      this.setState(cadastro);
    }

    if (this.state.novoitem !== prevState.novoitem) {
      this.setState({
        refresh: 'concluido'
      })
    }

  }

  async callApi() {
    let data =
    {
      id_venda: this.state.id_venda,
      id_cliente: this.state.id_cliente,
      venda_vlr: this.state.venda_vlr,
      venda_vlr_desconto: this.state.venda_vlr_desconto,
      venda_qtdd: this.state.venda_qtdd,
      id_tipo: this.state.id_tipo,
      id_produto: this.state.id_produto,
      id_servico: this.state.id_servico,
      id_produto_unidade: this.state.id_produto_unidade,
      id_fornecedor: this.state.id_fornecedor
    }

    var response = await PostComandaAPI(this.state.id_venda, data);

    if (response.erro === 0) {
      response.type = 'COMANDAS.TOOGLE_SET_ID';
      this.updateReduxAndState(response);
      this.callApiGetComanda(response.id_venda);
    }
  }

  async callApiGetComanda(id) {

    var response = await GetComandaAPI(id);

    response.onClickModalProd = this.clickChildEditProdConfirm;
    response.onClickModalServ = this.clickChildEditServConfirm;

    response.refresh = 'concluidoRefresh';
    if (response.erro === 0)
      response.type = 'COMANDAS.TOOGLE_GET_ID';
    else
      response.type = 'COMANDAS.TOOGLE_CLEAN';
    response.id_sis_forma_pgto = '';
    response.venda_vlr_rec = '';

    this.updateRedux(response);
    var cadastro = store.getState().comandas.cadastro;
    this.setState(cadastro);

  }

  clickChildEditProdConfirm = (event, id) => {

    this.clickChildModal(
      '',
      '',
      [this.clickChildEditProd],
      ['Confirmar'],
      'Deseja realmente excluir esse item?');

    this.setState({ id_item: id });


  }
  clickChildEditProd = () => {
    this.callExcluirProd();
  }

  async callExcluirProd() {
    var response = await DelVendaItemProd(store.getState().comandas.cadastro.id_venda, this.state.id_item);
    if (response.erro === 0)
      this.callApiGetComanda(store.getState().comandas.cadastro.id_venda);
  }

  async callRecebimento() {

    let data =
    {
      venda_vlr_rec: store.getState().comandas.cadastro.venda_vlr_rec,
      id_sis_forma_pgto: store.getState().comandas.cadastro.id_sis_forma_pgto
    }


    var response = await PostRecebimento(store.getState().comandas.cadastro.id_venda, data);
    if (response.erro === 0) {
      this.callApiGetComanda(store.getState().comandas.cadastro.id_venda);

    }
  }

  async callFecharComanda() {

    var response = await PostFecharComanda(store.getState().comandas.cadastro.id_venda);
    if (response.erro === 0)
      this.callApiGetComanda(store.getState().comandas.cadastro.id_venda);
  }

  async callReabrirComanda() {
    var response = await PostReabrirComanda(store.getState().comandas.cadastro.id_venda);
    if (response.erro === 0)
      this.callApiGetComanda(store.getState().comandas.cadastro.id_venda);
  }

  clickChildEditServConfirm = (event, id) => {

    this.clickChildModal(
      '',
      '',
      [this.clickChildEditServ],
      ['Confirmar'],
      'Deseja realmente excluir esse item?');

    this.setState({ id_item: id });
  }

  clickChildEditServ = () => {
    this.callExcluirServ();
  }

  async callExcluirServ() {
    var response = await DelVendaItemServ(store.getState().comandas.cadastro.id_venda, this.state.id_item);
    if (response.erro === 0)
      this.callApiGetComanda(store.getState().comandas.cadastro.id_venda);
  }

  handleInputChangeFornecedor = (values) => {
    var response = {};
    response.type = 'COMANDAS.TOOGLE_SET_ID_FORNECEDOR';
    if (values === null) {
      this.setState({
        id_fornecedor: '',
        nome_fornecedor: ''
      });
      response.id_fornecedor = '';
    } else {
      this.setState({
        id_fornecedor: values.id,
        nome_fornecedor: values.nome
      });
      response.id_fornecedor = values.id;
    }
    this.updateRedux(response);

  }

  handleInputChangeServico = (values) => {
    if (values === null)
      this.setState({
        id_servico: '',
        nome_servico: '',
        venda_vlr: ''
      });
    else
      this.setState({
        id_servico: values.id,
        nome_servico: values.nome,
        venda_vlr: values.serv_valor
      });


  }


  handleInputChangeProduto = (values) => {
    if (values === null)
      this.setState({
        id_produto: '',
        nome_produto: '',
        venda_vlr: ''
      });
    else
      this.setState({
        id_produto: values.id,
        nome_produto: values.nome,
        venda_vlr: values.vVenda
      });

  }

  novoCadastro = (event) => {
    event.preventDefault()

    var response = { type: 'COMANDAS.TOOGLE_NEW_ITEM', novoitem: true }
    this.updateReduxAndState(response);


  }

  btnRecebimento = (event) => {
    event.preventDefault()

    var response = { type: 'COMANDAS.RECEBIMENTO', venda_vlr_rec: this.state.venda_vlr_rec, id_sis_forma_pgto: this.state.id_sis_forma_pgto }
    this.updateReduxAndState(response);
    this.callRecebimento();

  }

  btnFecharComanda = (event) => {
    event.preventDefault()
    this.callFecharComanda();
  }

  btnReabrirComanda = (event) => {
    event.preventDefault()
    this.callReabrirComanda();
  }

  render() {
    return (
      <form name="form" onSubmit={this.handleSubmitGravando} action="" method="get" encType="multipart/form-data">

        {<FormEdit id='id_venda'
          label='ID Comanda'
          styleDiv='2'
          value={this.state.id_venda}
          typeField='readonly'
        />}

        {<FormEdit id='cliente_nome'
          label='Cliente'
          styleDiv='2'
          typeField='autocomplete'
          onChange={this.handleInputChangeCliente}
          loadData={this.loadDataCliente}
          options={[(this.state.id_cliente !== '') ? { id: this.state.id_cliente, nome: this.state.cli_razao } : { id: 0, nome: '' }]}
          value={(this.state.id_cliente !== '') ? { id: this.state.id_cliente, nome: this.state.cli_razao } : { id: 0, nome: '' }}
          required='on'
        />}

        {(this.state.id_venda !== 0) ?
          <FormEdit id='venda_vlr_prod'
            label='Valor dos produtos'
            styleDiv='4'
            typeField='readonly'
            value={this.state.venda_vlr_prod}
          /> : ''}

        {(this.state.id_venda !== 0) ?
          <FormEdit id='venda_vlr_serv'
            label='Valor dos serviços'
            styleDiv='4'
            typeField='readonly'
            value={this.state.venda_vlr_serv}
          /> : ''}

        {(this.state.id_venda !== 0) ?
          <FormEdit id='venda_vlr_desc'
            label='Valor dos descontos'
            styleDiv='4'
            typeField='readonly'
            value={this.state.venda_vlr_desc}
          /> : ''}

        {(store.getState().comandas.cadastro.id_venda !== 0) ?
          <FormEdit id='venda_vlr_total'
            label='Valor total'
            styleDiv='4'
            typeField='readonly'
            value={this.state.venda_vlr_total}
          /> : ''}

        {(store.getState().comandas.cadastro.id_venda !== 0 &&
          store.getState().comandas.cadastro.venda_recebimento !== '') ?
          <FormEdit id='venda_recebimento'
            label='Recebimento'
            styleDiv='1'
            typeField='readonly'
            value={this.state.venda_recebimento}
          /> : ''}

        {(getMenuAcao(1, 8) === true &&
          store.getState().comandas.cadastro.id_venda !== 0 &&
          store.getState().comandas.cadastro.venda_fechada === 1) ?
          <FormEdit id='reabrirComanda'
            label='Reabrir Comanda'
            styleDiv='submit right'
            styleField='submit'
            onClick={this.btnReabrirComanda}
            typeField='button'
            icon='add'
          /> : ''}

        {(store.getState().comandas.cadastro.id_venda !== 0 &&
          store.getState().comandas.cadastro.venda_fechada !== 1) ?
          <FormEdit id='venda_vlr_rec'
            label='Valor Recebido'
            styleDiv='4'
            mask="real"
            onChange={this.handleInputChange}
            value={this.state.venda_vlr_rec}
          /> : ''}

        {(store.getState().comandas.cadastro.id_venda !== 0 &&
          store.getState().comandas.cadastro.venda_fechada !== 1) ?
          <FormEdit id='id_sis_forma_pgto'
            label='Forma de pagamento'
            styleDiv='4'
            onChange={this.handleInputChange}
            typeField='select'
            value={this.state.id_sis_forma_pgto}
            optionsField={getFormasPgto('forma_pgto_venda')}
          /> : ''}

        {(store.getState().comandas.cadastro.id_venda !== 0 &&
          store.getState().comandas.cadastro.venda_fechada !== 1) ?
          <FormEdit id='fecharComanda'
            label='Fechar Comanda'
            styleDiv='submit right'
            styleField='submit'
            onClick={this.btnFecharComanda}
            typeField='button'
            icon='add'
          /> : ''}

        {(store.getState().comandas.cadastro.id_venda !== 0 &&
          store.getState().comandas.cadastro.venda_fechada !== 1) ?
          <FormEdit id='adicionaRecebimento'
            label='Gravar Recebimento'
            styleDiv='submit right'
            styleField='submit'
            onClick={this.btnRecebimento}
            typeField='button'
            icon='add'
          /> : ''}

        {(store.getState().comandas.cadastro.novoitem === false &&
          store.getState().comandas.cadastro.venda_fechada !== 1) ?
          <FormEdit id='adicionar'
            label='Adicionar Item'
            styleDiv='submit right'
            styleField='submit'
            onClick={this.novoCadastro}
            typeField='button'
            icon='add'
          /> : ''}

        {(store.getState().comandas.cadastro.novoitem === true) ?
          <FormEdit id='id_tipo'
            label='Tipo de venda'
            styleDiv='2'
            onChange={this.handleInputChange}
            typeField='select'
            value={this.state.id_tipo}
            required='on'
            optionsField={getSisTipoVenda('')}
          /> : ''}

        {(this.state.id_tipo === '2' && store.getState().comandas.cadastro.novoitem === true) ?
          <FormEdit id='nome_fornecedor'
            label='Nome Fornecedor'
            styleDiv='2'
            typeField='autocomplete'
            onChange={this.handleInputChangeFornecedor}
            loadData={this.loadDataFornecedor}
            options={[(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }]}
            value={(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }}
            required='on'
          /> : ''}

        {(this.state.id_tipo === '2' && store.getState().comandas.cadastro.novoitem === true) ?
          <FormEdit id='id_produto'
            label='Produto'
            styleDiv='2'
            typeField='autocomplete'
            onChange={this.handleInputChangeProduto}
            loadData={this.loadDataProduto}
            options={[(this.state.id_produto !== '') ? { id: this.state.id_produto, nome: this.state.nome_produto } : { id: 0, nome: '' }]}
            value={(this.state.id_produto !== '') ? { id: this.state.id_produto, nome: this.state.nome_produto } : { id: 0, nome: '' }}
            required='on'
          /> : ''}

        {(this.state.id_tipo === '2' && store.getState().comandas.cadastro.novoitem === true) ?
          <FormEdit id='id_produto_unidade'
            label='Unidade'
            styleDiv='2'
            onChange={this.handleInputChange}
            typeField='select'
            value={this.state.id_produto_unidade}
            required='on'
            optionsField={getSisUnidades()}
          /> : ''}

        {(this.state.id_tipo === '1' && store.getState().comandas.cadastro.novoitem === true) ?
          <FormEdit id='id_servico'
            label='Serviço'
            styleDiv='2'
            typeField='autocomplete'
            onChange={this.handleInputChangeServico}
            loadData={this.loadDataServico}
            options={[(this.state.id_servico !== '') ? { id: this.state.id_servico, nome: this.state.nome_servico } : { id: 0, nome: '' }]}
            value={(this.state.id_servico !== '') ? { id: this.state.id_servico, nome: this.state.nome_servico } : { id: 0, nome: '' }}
            required='on'
          /> : ''}

        {(store.getState().comandas.cadastro.novoitem === true) ?
          <FormEdit id='venda_qtdd'
            label='Quantidade'
            styleDiv='3'
            onChange={this.handleInputChange}
            value={this.state.venda_qtdd}
            required='on'
          /> : ''}

        {(store.getState().comandas.cadastro.novoitem === true) ?
          <FormEdit id='venda_vlr'
            label='Valor do Item'
            styleDiv='3'
            mask="real"
            onChange={this.handleInputChange}
            value={this.state.venda_vlr}
            required='on'
          /> : ''}

        {(store.getState().comandas.cadastro.novoitem === true) ?
          <FormEdit id='venda_vlr_desconto'
            label='Valor do desconto'
            styleDiv='3'
            mask="real"
            onChange={this.handleInputChange}
            value={this.state.venda_vlr_desconto}
          /> : ''}

        {(store.getState().comandas.cadastro.novoitem === true) ?
          <FormEdit id='gravar'
            label='Gravar'
            styleDiv='submit right'
            styleField='submit'
            onClick={this.handleSubmitGravando}
            typeField='submit'
            icon='gravar'
          /> : ''}

        {(store.getState().comandas.cadastro.servicos.lista.length > 0) ?
          Grid(store.getState().comandas.cadastro.servicos)
          : ''}

        {(store.getState().comandas.cadastro.produtos.lista.length > 0) ?
          Grid(store.getState().comandas.cadastro.produtos)
          : ''}
      </form>


    );

  }
}


export default ComandasUpdate;
