const INITIAL_STATE = {
    grids:
    {
        redux_prefix: 'CLIENTES.',
        header: ['ID', 'CPF/CNPJ', 'Nome', 'Telefone', 'E-mail'],
        colunas: ['id_cliente', 'cli_cnpj', 'cli_razao', 'cli_tel1', 'cli_email'],
        checkbox: '',
        styles: ['50px', '', '', '', ''],
        lista: [],
        limit: 25,
        offset: 1,
        pages: 0,
        fNome: '',
        fStatus: '',
        endereco: null,
        financeiro: null,
        nav: {
            tabs: ['Lista de Clientes', 'Cadastrar', 'Endereço', 'Financeiro'],
            className: ['lista active', 'cadastro', 'endereco disabled', 'financeiro disabled'],
            disabled: [null, null, 'disabled', 'disabled'],
            href: ['#lista', '#cadastro', '#endereco', '#financeiro'],
            onClick: [null, null, null, null]
        },
        edit: {
            itens: ['Alterar Cadastro', 'Alterar Endereços', 'Visualizar Financeiro'],
            onClick2: ['', '', '']
        },
        onClickModal: '',
        id: 0,
        refresh: 'concluido'
    },
    cadastro:
    {
        refresh: 'concluido', //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        id: 0,
        sIdCliente: '',
        sStatus: '',
        sCnpj: '',
        sCliRazao: '',
        sCliFantasia: '',
        sCliTel1: '',
        sCliCel1: '',
        sCliEmail: '',
        sCliNascimento: ''
    },
    enderecos:
    {
        refresh: 'concluido', //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        header: ['ID', 'Tipo', 'Principal?', 'CEP', 'Endereço', 'Bairro', 'Cidade', 'Estado'],
        colunas: ['id_cliente_end', 'tp_end', 'cli_principal', 'cli_cep', 'cli_endereco', 'cli_bairro', 'cidade', 'estado'],
        checkbox: '',
        styles: ['50px', '50px', '50px', '70px', '', '', '', '50px'],
        lista: [],
        cliente: '',
        limit: 25,
        offset: 1,
        pages: 0,
        btnCadastro: null,
        onClickModal: '',
        id_cliente: 0,
        id_cliente_end: 0
    },
    financeiro:
    {
        refresh: 'concluido', //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        header: ['ID', 'Venda', 'Valor', 'C/D', 'Data', 'Descrição'],
        colunas: ['id_financeiro', 'id_venda', 'fin_valor', 'fin_tipo', 'fin_data_cad', 'fin_descricao'],
        checkbox: '',
        styles: ['50px', '50px', '70px', '50px', '70px', ''],
        lista: [],
        cliente: '',
        limit: 25,
        offset: 1,
        pages: 0,
        //onClickModal: '',
        id_cliente: 0,
        id_financeiro: 0
    }

}

export default function clientes(state = INITIAL_STATE, action) {
    var grid = state.grids;
    var cadastro = state.cadastro;
    var enderecos = state.enderecos;
    var financeiro = state.financeiro;
    if (action.type === 'CLIENTES.TOOGLE_LISTA') {
        
        grid.erro = action.erro;
        if (grid.erro === 0) {
            grid.lista = action.lista;
            grid.onClickModal = action.onClickModal;
            grid.callBackPaginacao = action.callBackPaginacao;
            grid.pages = action.pages;
            grid.limit = action.limit;
            grid.offset = action.offset;
            grid.edit.onClick = action.editOnClick;
        }
       
        grid.nav = action.nav; 
        grid.nav.disabled[2] = 'disabled';

        return { ...state, grid }
    }

    if (action.type === 'CLIENTES.TOOGLE_FBUSCA') {
        grid.fNome = action.fNome;
        grid.fStatus = action.fStatus;
        return { ...state, grid }
    }

    if (action.type === 'CLIENTES.TOOGLE_LIMIT') {
        grid.offset = 1;
        grid.limit = action.limit;
        return { ...state, grid }
    }
    if (action.type === 'CLIENTES.TOOGLE_OFFSET') {
        grid.offset = action.offset;
        return { ...state, grid }
    }

    if (action.type === 'CLIENTES.TOOGLE_CADASTRO') {
        cadastro.id = action.id;
        cadastro.refresh = 'buscando';
        grid.nav.disabled[2] = null;
        grid.nav.disabled[3] = null;
        return { ...state, cadastro, grid }
    }

    if (action.type === 'CLIENTES.TOOGLE_REFRESH') {
        cadastro.refresh = 'concluido';
        return { ...state, cadastro }
    }

    if (action.type === 'CLIENTES.TOOGLE_GET_ID') {
        cadastro.refresh = 'concluido';
        cadastro.sStatus = action.sStatus;
        cadastro.sIdCliente = action.sIdCliente;
        cadastro.sCnpj = action.sCnpj;
        cadastro.sCliRazao = action.sCliRazao;
        cadastro.sCliFantasia = action.sCliFantasia;
        cadastro.sCliTel1 = action.sCliTel1;
        cadastro.sCliCel1 = action.sCliCel1;
        cadastro.sCliEmail = action.sCliEmail;
        cadastro.sCliNascimento = action.sCliNascimento;
        return { ...state, cadastro }
    }

    if (action.type === 'CLIENTES.TOOGLE_CLEAN') {
        cadastro.refresh = action.refresh;
        cadastro.id = 0;
        cadastro.sStatus = '';
        cadastro.sIdCliente = '';
        cadastro.sCnpj = '';
        cadastro.sCliRazao = '';
        cadastro.sCliFantasia = '';
        cadastro.sCliTel1 = '';
        cadastro.sCliCel1 = '';
        cadastro.sCliEmail = '';
        cadastro.sCliNascimento = '';
        grid.nav.disabled[2] = 'disabled';

        return { ...state, cadastro, grid }
    }


    if (action.type === 'CLIENTES.ENDERECO.TOOGLE_ID_CLIENTE') {
        enderecos.refresh = action.refresh;
        cadastro.id = action.id_cliente;
        grid.nav.disabled[2] = null;
        grid.nav.disabled[3] = null;
        grid.endereco = action.endereco;

        return { ...state, enderecos, cadastro, grid }
    }

    if (action.type === 'CLIENTES.ENDERECO.TOOGLE_REFRESH') {
        enderecos.refresh = 'concluido';
        grid.refresh = 'concluido';
        return { ...state, enderecos, grid }
    }

    if (action.type === 'CLIENTES.ENDERECO.TOOGLE_LISTA') {

        enderecos.erro = action.erro;
        if (enderecos.erro === 0) {
            enderecos.id_cliente = action.id_cliente;
            enderecos.cliente = action.cliente;
            enderecos.lista = action.lista;
            enderecos.btnCadastro = action.btnCadastro;
            enderecos.onClickModal = action.onClickModal;
            enderecos.callBackPaginacao = action.callBackPaginacao;
            enderecos.pages = action.pages;
            enderecos.limit = action.limit;
            enderecos.offset = action.offset;
        }
        return { ...state, enderecos }
    }

    if (action.type === 'CLIENTES.FINANCEIRO.TOOGLE_ID_CLIENTE') {
        financeiro.refresh = action.refresh;
        cadastro.id = action.id_cliente;
        grid.nav.disabled[2] = null;
        grid.nav.disabled[3] = null;
        grid.financeiro = action.financeiro;

        return { ...state, financeiro, cadastro, grid }
    }

    if (action.type === 'CLIENTES.FINANCEIRO.TOOGLE_REFRESH') {
        financeiro.refresh = 'concluido';
        grid.refresh = 'concluido';
        return { ...state, financeiro, grid }
    }

    if (action.type === 'CLIENTES.FINANCEIRO.TOOGLE_LISTA') {

        financeiro.erro = action.erro;
        if (financeiro.erro === 0) {
            financeiro.id_cliente = action.id_cliente;
            financeiro.cliente = action.cliente;
            financeiro.lista = action.lista;
            financeiro.callBackPaginacao = action.callBackPaginacao;
            financeiro.pages = action.pages;
            financeiro.limit = action.limit;
            financeiro.offset = action.offset;
        }
        return { ...state, financeiro }
    }


    return state;
}