//import { getDate, addDateDay } from '../../services/Funcoes.js';

const INITIAL_STATE = {
    grids:
    {
        redux_prefix: 'CONTASRECEBER.',
        header: ['ID', 'Cliente', 'Valor', 'Última Compra', 'Contato', 'Telefone', 'E-mail'],
        colunas: ['id_cliente', 'cli_razao', 'cli_saldo', 'cli_data_ultima_compra', 'cli_contato1', 'cli_tel1', 'cli_email'],
        checkbox: '',
        styles: ['50px', '', '70px', '100px', '', '', ''],
        lista: [],

        limit: 25,
        offset: 1,
        pages: 0,
        fNome: '',
        fSituacao: 'areceber',
        endereco: null,
        nav: {
            tabs: ['Lista de contas à receber', 'Detalhamento'],
            className: ['lista active', 'cadastro'],
            disabled: [null, null],
            href: ['#lista', '#cadastro'],
            onClick: [null, null]
        },
        edit: {
            itens: ['Alterar Cadastro'],
            onClick: ['']
        },
        onClickModal: '',
        id: 0,
        refresh: 'concluido' //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
    },
    cadastro:
    {
        refresh: 'concluido', //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        id: 0,
        id_cliente: '',
        cli_saldo: '',
        nome: '',
        ultima_compra: '',
        fin_descricao: '',
        id_sis_forma_pgto: '',
        header: ['Cliente', 'Valor', 'Última Compra', 'Contato', 'Telefone', 'E-mail'],
        colunas: ['cli_razao', 'cli_saldo', 'cli_data_ultima_compra', 'cli_contato1', 'cli_tel1', 'cli_email'],
        checkbox: '',
        styles: ['', '70px', '100px', '', '', ''],
        grids: {
            redux_prefix: 'CONTASRECEBER.',
            header: ['ID', 'Data', 'Descricao', 'Valor', 'C/D'],
            colunas: ['id_financeiro', 'fin_data_cad', 'fin_descricao', 'fin_vlr', 'fin_tipo'],
            checkbox: '',
            styles: ['50px', '70px', '', '70px', '50px', ''],
            lista: [],
            limit: 25,
            offset: 1,
            pages: 0,
            endereco: null,
            edit: {
                itens: ['Deseja realmente excluir o registro?'],
                onClick: ['']
            },
            onClickModal: '',
            refresh: 'concluido' //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
        },

    }

}

export default function contasReceber(state = INITIAL_STATE, action) {
    var grid = state.grids;
    if (action.type === 'CONTASRECEBER.TOOGLE_LISTA') {
        grid.erro = action.erro;
        if (grid.erro === 0) {
            grid.lista = action.lista;
            grid.onClickModal = action.onClickModal;
            grid.callBackPaginacao = action.callBackPaginacao;
            grid.pages = action.pages;
            grid.limit = action.limit;
            grid.offset = action.offset;
            grid.edit.onClick = action.editOnClick;
        }
        return { ...state, grid }
    }

    if (action.type === 'CONTASRECEBER.TOOGLE_FBUSCA') {
        grid.fNome = action.fNome;
        grid.fSituacao = action.fSituacao;
        return { ...state, grid }
    }

    if (action.type === 'CONTASRECEBER.TOOGLE_LIMIT') {
        grid.offset = 1;
        grid.limit = action.limit;
        return { ...state, grid }
    }

    if (action.type === 'CONTASRECEBER.TOOGLE_OFFSET') {
        grid.offset = action.offset;
        return { ...state, grid }
    }

    var cadastro = state.cadastro;
    if (action.type === 'CONTASRECEBER.TOOGLE_CADASTRO') {
        cadastro.id = action.id;
        cadastro.refresh = 'buscando';
        return { ...state, cadastro }
    }

    if (action.type === 'CONTASRECEBER.TOOGLE_REFRESH') {
        cadastro.refresh = 'concluido';
        return { ...state, cadastro }
    }

    if (action.type === 'CONTASRECEBER.TOOGLE_GET_ID') {
        cadastro.refresh = 'concluido';
        cadastro.id_cliente = action.id_cliente;
        cadastro.nome = action.nome;
        cadastro.grids.lista = action.lista;
        cadastro.ultima_compra = action.ultima_compra;
        cadastro.cli_saldo = action.cli_saldo;
        cadastro.grids.onClickModal = action.onClickModal;
        cadastro.grids.edit.onClick = action.editOnClick;

        //cadastro.grids.callBackPaginacao = action.callBackPaginacao;

        return { ...state, cadastro }
    }

    if (action.type === 'CONTASRECEBER.TOOGLE_REFRESH_ADD') {
        cadastro.refresh = 'concluido';
        cadastro.fin_vlr = '';
        cadastro.cli_saldo = action.cli_saldo;
        cadastro.id_sis_forma_pgto = '';
        cadastro.fin_descricao = '';
        cadastro.grids.lista = action.lista;
        return { ...state, cadastro }
    }

    if (action.type === 'CONTASRECEBER.TOOGLE_CLEAN') {
        cadastro.refresh = action.refresh;
        cadastro.id = 0;
        cadastro.id_cliente = 0;
        cadastro.nome = '';
        cadastro.ultima_compra = '';
        cadastro.cli_saldo = '';
        cadastro.fin_vlr = '';
        cadastro.id_sis_forma_pgto = '';
        cadastro.fin_descricao = '';
        cadastro.lista = [];
        return { ...state, cadastro }
    }

    return state;
}