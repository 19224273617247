import React from 'react';
import ReactDOM from 'react-dom';
import ComponentErp from './ComponentErp.js';
import Header from '../components/Header.js';
import { Chart } from "react-google-charts";
import { GetRelatorioGeralAPI, GetRelatorioFornContasAPI } from '../models/ModelContasPagar.js';
import FormEdit from '../components/FormEdit.js';

class Home extends ComponentErp {
  constructor(props) {
    super(props);

    this.state = {
      id_fornecedor: 0,
      nome_fornecedor: '',
      fAnoContas: 2021,
      fAnoGeral: 2021,
      dadosGeral: {
        data: [
          [
            'Mes',
            'Contas à Pagar',
            'Contas Pagas',
          ],
          ['-', 0, 0]
        ],
        optionsBar: {
          title: 'Contas à Pagar - Geral',
          vAxis: { title: 'Valor' },
          hAxis: { title: 'Mês' },
          seriesType: 'bars',
          language: 'br',
        }
      },
      dadosContas: {
        data: [
          [
            'Mes',
            'Contas à Pagar',
            'Contas Pagas',
          ],
          ['-', 0, 0]
        ],
        optionsBar: {
          title: 'Contas à Pagar - Geral',
          vAxis: { title: 'Valor' },
          hAxis: { title: 'Mês' },
          seriesType: 'bars',
          language: 'br',

        }
      },

    };

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;

    document.body.appendChild(script);
  }

  componentDidMount() {
    ReactDOM.render(<Header />, document.getElementById('sys-header'));
    this.setState({
      refresh: 'buscando'
    })
    this.callApi(true,true);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.refresh==='concluidoRefresh'){
      this.setState({
        refresh: 'concluido'
      })  
    }
  }

  carregaGraficoGeral() {
    return <Chart
      chartType="ColumnChart"
      data={this.state.dadosGeral.data}
      options={this.state.dadosGeral.optionsBar}
      loader={<div>Carregando gráfico</div>}
      width="90%"
      height="400px"
      formatters={[
        {
          type: "NumberFormat",
          column: 1,
          options: {
            prefix: "R$ ",
            decimalSymbol: ",",
          }
        },
        {
          type: "NumberFormat",
          column: 2,
          options: {
            prefix: "R$ ",
            decimalSymbol: ",",
          }
        }
      ]}
    />
  }

  carregaGraficoContas() {
    return <Chart
      chartType="ColumnChart"
      data={this.state.dadosContas.data}
      options={this.state.dadosContas.optionsBar}
      loader={<div>Carregando gráfico</div>}
      width="90%"
      height="400px"
      formatters={[
        {
          type: "NumberFormat",
          column: 1,
          options: {
            prefix: "R$ ",
            decimalSymbol: ",",
          }
        },
        {
          type: "NumberFormat",
          column: 2,
          options: {
            prefix: "R$ ",
            decimalSymbol: ",",
          }
        }
      ]}
    />
  }

  processaRelatorioGeral(response) {
    if (response.erro === 0) {
      var data = [
        [
          'Mês',
          'Contas à Pagar',
          'Contas Pagas',
        ]
      ];

      for (const item of response.lista) {
        if (item.referencia === 'Valor de Contas a Pagar') {
          data[1] = ['Jan', parseFloat(item.mes01.replace('R$ ', '').replace(',', '.'))]
          data[2] = ['Fev', parseFloat(item.mes02.replace('R$ ', '').replace(',', '.'))]
          data[3] = ['Mar', parseFloat(item.mes03.replace('R$ ', '').replace(',', '.'))]
          data[4] = ['Abr', parseFloat(item.mes04.replace('R$ ', '').replace(',', '.'))]
          data[5] = ['Mai', parseFloat(item.mes05.replace('R$ ', '').replace(',', '.'))]
          data[6] = ['Jun', parseFloat(item.mes06.replace('R$ ', '').replace(',', '.'))]
          data[7] = ['Jul', parseFloat(item.mes07.replace('R$ ', '').replace(',', '.'))]
          data[8] = ['Ago', parseFloat(item.mes08.replace('R$ ', '').replace(',', '.'))]
          data[9] = ['Set', parseFloat(item.mes09.replace('R$ ', '').replace(',', '.'))]
          data[10] = ['Out', parseFloat(item.mes10.replace('R$ ', '').replace(',', '.'))]
          data[11] = ['Nov', parseFloat(item.mes11.replace('R$ ', '').replace(',', '.'))]
          data[12] = ['Dez', parseFloat(item.mes12.replace('R$ ', '').replace(',', '.'))]
        }
        if (item.referencia === 'Valor de Contas Pagas') {
          data[1][2] = parseFloat(item.mes01.replace('R$ ', '').replace(',', '.'))
          data[2][2] = parseFloat(item.mes02.replace('R$ ', '').replace(',', '.'))
          data[3][2] = parseFloat(item.mes03.replace('R$ ', '').replace(',', '.'))
          data[4][2] = parseFloat(item.mes04.replace('R$ ', '').replace(',', '.'))
          data[5][2] = parseFloat(item.mes05.replace('R$ ', '').replace(',', '.'))
          data[6][2] = parseFloat(item.mes06.replace('R$ ', '').replace(',', '.'))
          data[7][2] = parseFloat(item.mes07.replace('R$ ', '').replace(',', '.'))
          data[8][2] = parseFloat(item.mes08.replace('R$ ', '').replace(',', '.'))
          data[9][2] = parseFloat(item.mes09.replace('R$ ', '').replace(',', '.'))
          data[10][2] = parseFloat(item.mes10.replace('R$ ', '').replace(',', '.'))
          data[11][2] = parseFloat(item.mes11.replace('R$ ', '').replace(',', '.'))
          data[12][2] = parseFloat(item.mes12.replace('R$ ', '').replace(',', '.'))
        }
      }
    }
    return data;
  }

  processaRelatorioContas(response) {
    var data = [
      [
        'Mês',
        'Contas à Pagar',
        'Contas Pagas',
      ]
    ];
   if (response.erro === 0 && response.lista.length>0) {
 

      for (const item of response.lista) {
        data.push([item.mes, parseFloat(item.valor), parseFloat(item.valor_pago)])
      }
    }else{
      data.push(['-', 0, 0]);
    }
    return data;
  }

  handleSubmitGeral = (event) => {
    event.preventDefault()
    this.callApi(true,false);
  }

  handleSubmitContas = (event) => {
    event.preventDefault()
    this.callApi(false,true);
  }

  async callApi(geral, contas) {
    if(geral){
      var response = await GetRelatorioGeralAPI(this.state.fAnoGeral);
      var dataGeral = this.processaRelatorioGeral(response);
      this.setState({
        refresh: 'concluidoRefresh',
        dadosGeral: { data: dataGeral }
      })
  
    }

    if(contas){
      var responseContas = await GetRelatorioFornContasAPI(this.state.fAnoContas, this.state.id_fornecedor);
      var dataContas = this.processaRelatorioContas(responseContas);
      var vdataContas = this.state.dadosContas;
      if(dataContas.length>0)
        vdataContas.data = dataContas;
      this.setState({
        refresh: 'concluidoRefresh',
        dadosContas: vdataContas
      })
    }

  }



  render() {
    var x = document.body;
    x.style.backgroundImage = "none";

    x = document.getElementById('sys-header');
    x.style.display = "block";
    x = document.getElementById('sys-footer');
    x.style.display = "block";

    return (
      <div className="container">
        <div className="row">
          <h2 className="section-title">Dashboard</h2>
          <div className="card">
          <div id="lista" className="dataTables_wrapper no-footer row">
            <form name="formGeral" onSubmit={this.handleSubmitGeral} action="" method="get" encType="multipart/form-data">

              {<FormEdit id='fAnoGeral'
                label='Ano'
                value={this.state.fAnoGeral}
                onChange={this.handleInputChange}
                typeField='number'
                styleDiv='4' />}

              {<FormEdit id='submit'
                label='Pesquisar'
                styleDiv='submit'
                styleField='submit'
                typeField='submit'
                icon='pesquisa'
              />}

            </form>
            </div>
            {this.carregaGraficoGeral()}
          </div>
          <div className="card">
            <div id="lista" className="dataTables_wrapper no-footer row">
              <form name="form" onSubmit={this.handleSubmitContas} action="" method="get" encType="multipart/form-data">

                {<FormEdit id='fAnoContas'
                  label='Ano'
                  value={this.state.fAnoContas}
                  onChange={this.handleInputChange}
                  typeField='number'
                  styleDiv='4' />}

                {<FormEdit id='nome_fornecedor'
                  label='Nome Fornecedor'
                  styleDiv='2'
                  typeField='autocomplete'
                  onChange={this.handleInputChangeFornecedor}
                  loadData={this.loadDataFornecedor}
                  options={[(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }]}
                  value={(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }}
                  required='on'
                />}

                {<FormEdit id='submit'
                  label='Pesquisar'
                  styleDiv='submit'
                  styleField='submit'
                  typeField='submit'
                  icon='pesquisa'
                />}

              </form>
            </div>
            {this.carregaGraficoContas()}
          </div>
        </div>
      </div>

    );

  }
}

export default Home;
