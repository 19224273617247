import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import FormEdit from '../components/FormEdit.js';
import { getSisTipoVencimento } from '../services/Auth.js';
import { GetContasPagarAPI } from '../models/ModelContasPagar.js';
import ContasPagarUpdate from './ContasPagarUpdate.js';


class ContasPagar extends ComponentErp {
  constructor(props) {
    super(props);
    this.state = store.getState().contasPagar.grids;
  }

  componentDidMount() {
    this.callApi()
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.setState({ refresh: 'buscando' });
      this.callApi()
    }

  }

  handleSubmit = (event) => {
    event.preventDefault()

    var response = {
      refresh: 'gravando',
      fNome: this.state.fNome,
      fDataIni: this.state.fDataIni,
      fDataFim: this.state.fDataFim,
      fDataTipo: this.state.fDataTipo,
      type: 'CONTASPAGAR.TOOGLE_FBUSCA'
    }
    this.updateReduxAndState(response);

  }

  novoCadastro = (event) => {
    event.preventDefault()

    var response = { type: 'CONTASPAGAR.TOOGLE_CLEAN', refresh: 'limpando' }
    this.updateReduxAndState(response);

    this.actionClick('.cadastro');
    this.clearModal();
  }


  clickChildCadastro = (event, id) => {
    event.preventDefault()

    var response = {
      id: id,
      type: 'CONTASPAGAR.TOOGLE_CADASTRO',
      refresh: 'buscacontaspagar'
    }

    this.updateReduxAndState(response);

    this.actionClick('.cadastro');
    this.clearModal();

  }

  async callApi() {
    var grids = store.getState().contasPagar.grids;
    var response = await GetContasPagarAPI(grids.limit, grids.offset, grids.fNome, grids.fDataIni, grids.fDataFim, grids.fDataTipo);
    response.refresh = 'concluido';
    response.onClickModal = this.clickChildCadastro;
    response.callBackPaginacao = this.callBackPaginacao;
    response.type = 'CONTASPAGAR.TOOGLE_LISTA';

    this.updateRedux(response);
    grids = store.getState().contasPagar.grids;
    this.setState(grids);
  }



  render() {

    return (

      <div className="container">
        <div className="row">
          <h2 className="section-title">Contas à pagar</h2>
          <div className="card">
            {this.navMenu(store.getState().contasPagar.grids.nav)}

            <div id="lista" className="dataTables_wrapper no-footer row">
              <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">

                {<FormEdit id='fNome'
                  label='Busque por nome'
                  value={this.state.fNome}
                  onChange={this.handleInputChange}
                  styleDiv='4' />}

                {<FormEdit id='fDataIni'
                  label='Data Inicial'
                  value={this.state.fDataIni}
                  onChange={this.handleInputChange}
                  typeField='date'
                  styleDiv='4' />}

                {<FormEdit id='fDataFim'
                  label='Data Final'
                  value={this.state.fDataFim}
                  onChange={this.handleInputChange}
                  typeField='date'
                  styleDiv='4' />}

                {<FormEdit id='fDataTipo'
                  label='Tipo de data'
                  styleDiv='4'
                  typeField='select'
                  defaultValue={this.state.fDataTipo}
                  required='on'
                  onChange={this.handleInputChange}
                  optionsField={getSisTipoVencimento('contaspagar')}
                />}

                {<FormEdit id='submit'
                  label='Pesquisar'
                  styleDiv='submit'
                  styleField='submit'
                  typeField='submit'
                  icon='pesquisa'
                />}

                {<FormEdit id='cadNovo'
                  label='Novo Cadastro'
                  styleDiv='submit'
                  onClick={this.novoCadastro}
                  styleField='submit'
                  typeField='button'
                  icon='adiciona'
                />}

              </form>

              {this.Grid(store.getState().contasPagar.grids)}

              <div className="input-field colinput-field col s20">
                <div id="modal_legenda" className="modal modal-fixed-footer">
                  <div className="modal-content">
                    <h4>Legenda</h4>
                    <div className="row">
                      <i className="material-icons  green-text text-lighten-2 left">bookmark</i>Pago no prazo
                    </div>
                    <div className="row">
                      <i className="material-icons  green-text text-darken-4 left">bookmark</i>Paga em atraso
                    </div>
                    <div className="row">
                      <i className="material-icons  red-text text-darken-4 left">bookmark</i>Conta atrasada
                    </div>
                    <div className="row">
                      <i className="material-icons  orange-text left">bookmark</i>Vencimento para hoje
                    </div>
                    <div className="row">
                      <i className="material-icons  yellow-text left">bookmark</i> Conta à Pagar
                    </div>

                  </div>
                  <div className="modal-footer">
                    <a href="#!" className="modal-action modal-close waves-effect waves-green btn-flat ">Ok</a>
                  </div>
                </div>
                <button className="btn waves-effect waves-light modal-trigger" href="#!" data-target="modal_legenda">
                  <i className="material-icons  white-text left">bookmark</i> Legenda
                </button>
              </div>
            </div>
            <div id="cadastro" className="dataTables_wrapper no-footer row">
              {<ContasPagarUpdate />}
            </div>
          </div>
        </div>
      </div>

    );

  }
}
export default (ContasPagar);
