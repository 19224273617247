import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';
import FormEdit from '../components/FormEdit.js';
import { getFormasPgto } from '../services/Auth.js';

import { GetContasReceberIdAPI, PostContasReceberAPI, PostContasReceberDelAPI } from '../models/ModelContasReceber.js';

class ContasReceberUpdate extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = store.getState().contasReceber.cadastro;
    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;


    document.body.appendChild(script);

  }

  componentDidUpdate(prevProps, prevState) {
    
    
    if (this.state.refresh === 'gravando') {
      this.callApi();
      this.setState({
        refresh: 'concluido'
      })
    }

    var response = {}
    if (store.getState().contasReceber.cadastro.refresh === 'buscando' &&
      store.getState().contasReceber.cadastro.id !== store.getState().contasReceber.cadastro.id_cliente
    ) {
      response = { type: 'CONTASRECEBER.TOOGLE_REFRESH' }
      this.updateRedux(response);
      this.callApiGetContasReceber(store.getState().contasReceber.cadastro.id)

    }

  }

  clickChild = (event, id) => {
    this.setState({
      id_financeiro: id
    })
    this.clickChildModal(
      '',
      'concluido',
      store.getState().contasReceber.cadastro.grids.edit.onClick,
      store.getState().contasReceber.cadastro.grids.edit.itens);

  }

  clickChildDelete = (event) => {
    event.preventDefault()

    this.callApiDelete();
  }

  async callApiDelete(){

    var response = await PostContasReceberDelAPI(this.state.id_financeiro);
    if(response.erro===0){
      this.callApiGetContasReceber(this.state.id);
      this.clearModal();
    }

  }
  async callApi() {

    let data =
    {
      id_cliente: this.state.id_cliente,
      id_venda: '',
      fin_tipo: 'C',
      fin_descricao: this.state.fin_descricao,
      fin_vlr: this.state.fin_vlr,
      id_sis_forma_pgto: this.state.id_sis_forma_pgto
    }
    
    var response = await PostContasReceberAPI(data);

    if(response.erro === 0){
      if (this.state.id_cliente === 0) {
        response.type = 'CONTASRECEBER.TOOGLE_CLEAN';
        this.updateRedux(response);
        response = store.getState().contasReceber.cadastro;
      }else{
        response.type = 'CONTASRECEBER.TOOGLE_REFRESH_ADD';
        this.updateRedux(response);
        response = store.getState().contasReceber.cadastro;  
      }
      this.setState(response);
      this.props.refreshBusca();
    }

  }

  async callApiGetContasReceber(id) {

    var response = await GetContasReceberIdAPI(id);

    response.refresh = 'concluido';

    response.onClickModal = this.clickChild;
    response.editOnClick = [this.clickChildDelete];

    response.callBackPaginacao = this.callBackPaginacao;
    
    if (response.erro === 0)
      response.type = 'CONTASRECEBER.TOOGLE_GET_ID';
    else
      response.type = 'CONTASRECEBER.TOOGLE_CLEAN';
    this.updateRedux(response);
    var cadastro = store.getState().contasReceber.cadastro;
    this.setState(cadastro);
  }

  render() {
    return (
      <form name="form" onSubmit={this.handleSubmitGravando} action="" method="get" encType="multipart/form-data">
        {<FormEdit id='id_cliente'
          label='ID Cliente'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.id_cliente}
          typeField='readonly'
          required='on'
        />}

        {<FormEdit id='ultima_compra'
          label='Última compra'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.ultima_compra}
          typeField='readonly'
          required='on'
        />}

        {<FormEdit id='nome'
          label='Cliente'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.nome}
          typeField='readonly'
          required='on'
        />}

        {<FormEdit id='cli_saldo'
          label='Saldo'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.cli_saldo}
          typeField='readonly'
          required='on'
        />}

        {<FormEdit id='fin_descricao'
          label='Descrição'
          styleDiv='1'
          onChange={this.handleInputChange}
          value={this.state.fin_descricao}
          required='on'
        />}

        {<FormEdit id='fin_vlr'
          label='Valor Recebimento'
          styleDiv='4'
          mask="real"
          onChange={this.handleInputChange}
          value={this.state.fin_vlr}
          required='on'
        />}

        {<FormEdit id='id_sis_forma_pgto'
            label='Forma de pagamento'
            styleDiv='4'
            onChange={this.handleInputChange}
            typeField='select'
            value={this.state.id_sis_forma_pgto}
            optionsField={getFormasPgto('forma_pgto_venda')}
          />}
        
        {<FormEdit id='gravar'
          label='Gravar'
          styleDiv='submit right'
          styleField='submit'
          typeField='submit'
          icon='gravar'
        />}

        {this.Grid(store.getState().contasReceber.cadastro.grids)}

      </form>

    );

  }
}


export default ContasReceberUpdate;
