import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import FormEdit from '../components/FormEdit.js';
import { GetFluxoCaixaAPI } from '../models/ModelFluxoCaixa.js';


class FluxoCaixa extends ComponentErp {
  constructor(props) {
    super(props);
    this.state = store.getState().fluxoCaixa.grids;
  }

  componentDidMount() {
    this.callApi()
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.setState({ refresh: 'buscando' });
      this.callApi()
    }

  }

  handleSubmit = (event) => {
    event.preventDefault()
    var response = {
      refresh: 'gravando',
      fData: this.state.fData,
      type: 'FLUXOCAIXA.TOOGLE_FBUSCA'
    }
    this.updateReduxAndState(response);

  }

  async callApi() {
    var grids = store.getState().fluxoCaixa.grids;

    var response = await GetFluxoCaixaAPI(grids.fData);
    response.refresh = 'concluido';
    response.type = 'FLUXOCAIXA.TOOGLE_LISTA';

    this.updateRedux(response);
    grids = store.getState().fluxoCaixa.grids;
    this.setState(grids);
  }



  render() {

    return (

      <div className="container">
        <div className="row">
          <h2 className="section-title">Fluxo de Caixa</h2>
          <div className="card">
            {this.navMenu(store.getState().fluxoCaixa.grids.nav)}

            <div id="lista" className="dataTables_wrapper no-footer row">
              <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">

                {<FormEdit id='fData'
                  label='Data'
                  value={this.state.fData}
                  onChange={this.handleInputChange}
                  typeField='date'
                  styleDiv='4' />}

                {<FormEdit id='submit'
                  label='Pesquisar'
                  styleDiv='submit'
                  styleField='submit'
                  typeField='submit'
                  icon='pesquisa'
                />}

              </form>

              {this.GridFull(store.getState().fluxoCaixa.grids)}
            </div>
            <div className="row s1">
              <div className="row">
                <div className="col s2" style={{ width: '140px' }}>
                  <i class="material-icons green-text text-lighten-2 left">bookmark</i>
                  <p>Créditos:</p>
                </div>
                <div className="col s2">
                  <p>{store.getState().fluxoCaixa.grids.creditos}</p>
                </div>
              </div>
              <div className="row">
                <div className="col s2" style={{ width: '140px' }}>
                  <i class="material-icons  red-text left">bookmark</i>
                  <p>Débitos:</p>
                </div>
                <div className="col s2">
                  <p>{store.getState().fluxoCaixa.grids.debitos}</p>
                </div>
              </div>
              <div className="row">
                <div className="col s2" style={{ width: '140px' }}>
                  <i class="material-icons  gray-text left">bookmark</i>
                  <p>Saldo:</p>
                </div>
                <div className="col s2">
                  <p>{store.getState().fluxoCaixa.grids.saldo}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    );

  }
}
export default (FluxoCaixa);
