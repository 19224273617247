import api from '../services/Api.js';
import { clearMessage, warningMessage, warningMessageCatch, progressBar } from '../services/Funcoes.js';


/**
 * Lista de Contas A Receber
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome 
 */

export const GetContasReceberAPI = (limit, offset, fNome, fSituacao) => {

  progressBar();


  return api.get('/contasareceber/lista?limit=' + limit + '&offset=' + offset + '&fnome=' + fNome + '&fsituacao=' + fSituacao)
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        pages: response.data.result.pages,
        limit: response.data.result.limit,
        offset: response.data.result.offset,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}


/* 
 * Busca Contas A Receber
 * @param {*} id  
 */
export const GetContasReceberIdAPI = (id) => {
  progressBar();
  return api.get('/contasareceber/listacliente/' + id)
    .then(response => {
      clearMessage();
      var ret = response.data.result;
      ret.id = id;
      ret.id_cliente = id;
      ret.lista = response.data.result.lista;
      ret.nome = response.data.result.contato.nome;
      ret.cli_saldo = response.data.result.contato.cli_saldo;
      ret.ultima_compra = response.data.result.contato.ultima_compra;
      ret.refresh = 'concluido';
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }

    })

}

/* 
 * Cadastro de Contas A Receber
 * @param {*} id 
 * @param {*} data 
 */
export const PostContasReceberAPI = (data) => {
  progressBar();

  var url = '';
  url = '/contasareceber/add';

  return api.post(url, data)
    .then(response => {
      warningMessage(response.data.result.mensagem);

      return {
        erro: 0,
        lista: response.data.result.lista,
        cli_saldo: response.data.result.cli_saldo,
        refresh: 'concluido'
      }

    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }
    })

}


export const PostContasReceberDelAPI = (id) => {
  progressBar();

  var url = '/contasareceber/del/' + id;

  return api.get(url)
    .then(response => {
      warningMessage(response.data.result.mensagem);

      return {
        erro: 0,
        refresh: 'concluido'
      }

    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }
    })

}
