import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import {withRouter} from 'react-router-dom';

class App extends Component{
    
  constructor(props) {
    super(props);
    

    ReactDOM.render(<Header />,document.getElementById('sys-header'));  
		ReactDOM.render(<Footer />,document.getElementById('sys-footer'));  

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;
    
    document.body.appendChild(script);

  }

	render(){  
    
  	return (
      <div>
        {this.props.children}
      </div>

    );
      		
	}
}


export default withRouter(App);