import React from 'react';
import ComponentErp from './ComponentErp.js';

import store from '../store';
import { getSisStatus } from '../services/Auth.js';
import FormEdit from '../components/FormEdit.js';
import { GetClienteAPI, PostClienteAPI } from '../models/ModelClientes.js';
import { convertDate } from '../services/Funcoes.js';

class ClientesUpdate extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = store.getState().clientes.cadastro;
    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;

    document.body.appendChild(script);

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.callApi();
      this.setState({
        refresh: 'concluido'
      })
    }

    var response = {}
    if (store.getState().clientes.cadastro.refresh === 'buscando' &&
      store.getState().clientes.cadastro.id !== store.getState().clientes.cadastro.sIdCliente
    ) {
      response = { type: 'CLIENTES.TOOGLE_REFRESH' }
      this.updateRedux(response);
      this.callApiGetCliente(store.getState().clientes.cadastro.id)
    }

    if (store.getState().clientes.cadastro.refresh === 'limpando') {
      response = { refresh: 'concluido', type: 'CLIENTES.TOOGLE_CLEAN' }
      this.updateRedux(response);
      var cadastro = store.getState().clientes.cadastro;
      this.setState(cadastro);
    }

  }

  async callApi() {
    let data =
    {
      id_sis_status: this.state.sStatus,
      id_cliente: this.state.sIdCliente,
      cli_cnpj: this.state.sCnpj,
      cli_razao: this.state.sCliRazao,
      cli_fantasia: this.state.sCliFantasia,
      cli_tel1: this.state.sCliTel1,
      cli_tel2: this.state.sCliCel1,
      cli_email: this.state.sCliEmail,
      cli_data_nascimento: convertDate(this.state.sCliNascimento)
    }

    var response = await PostClienteAPI(this.state.sIdCliente, data);

    if (this.state.sIdCliente === '' && response.erro === 0) {
      response.type = 'CLIENTES.TOOGLE_CLEAN';
      this.updateRedux(response);
      response = store.getState().clientes.cadastro;
    }

    this.setState(
      response
    );
  }


  async callApiGetCliente(id) {

    var response = await GetClienteAPI(id);

    response.refresh = 'concluido';
    if (response.erro === 0)
      response.type = 'CLIENTES.TOOGLE_GET_ID';
    else
      response.type = 'CLIENTES.TOOGLE_CLEAN';
    this.updateRedux(response);
    var cadastro = store.getState().clientes.cadastro;
    this.setState(cadastro);

  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({
      refresh: 'gravando'
    });

  }

  render() {

    return (
      <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">
        {<FormEdit id='sIdCliente'
          typeField='hidden'
          value={this.state.sIdCliente}
        />}

        {<FormEdit id='sCnpj'
          label='CPF/CNPJ'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.sCnpj}
          mask="cpfcnpj"
          required='on'
        />}

        {<FormEdit id='sStatus'
          label='Status'
          styleDiv='2'
          onChange={this.handleInputChange}
          typeField='select'
          value={this.state.sStatus}
          required='on'
          optionsField={getSisStatus('status_padrao')}
        />}

        {<FormEdit id='sCliRazao'
          label='Nome/Razao Social'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.sCliRazao}
          required='on'
        />}

        {<FormEdit id='sCliFantasia'
          label='Sobrenome/Nome Fantasia'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.sCliFantasia}
        />}

        {<FormEdit id='sCliTel1'
          label='Telefone'
          styleDiv='2'
          onChange={this.handleInputChange}
          mask="telcel"
          value={this.state.sCliTel1}
        />}

        {<FormEdit id='sCliCel1'
          label='Celular'
          styleDiv='2'
          onChange={this.handleInputChange}
          mask="telcel"
          value={this.state.sCliCel1}
        />}

        {<FormEdit id='sCliEmail'
          label='E-mail'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.sCliEmail}
        />}

        {<FormEdit id='sCliNascimento'
          label='Data Nascimento'
          styleDiv='2'
          onChange={this.handleInputChange}
          typeField="date"
          value={this.state.sCliNascimento}
        />}

        {<FormEdit id='gravar'
          label='Gravar'
          styleDiv='submit right'
          styleField='submit'
          typeField='submit'
          icon='gravar'
        />}
      </form>

    );

  }
}

export default ClientesUpdate;
