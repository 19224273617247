import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import { getSisStatus, getMenuAcao } from '../services/Auth.js';
import FormEdit from '../components/FormEdit.js';
import { GetClientesAPI } from '../models/ModelClientes.js';
import ClientesUpdate from './ClientesUpdate.js';
import ClientesEndereco from './ClientesEndereco.js';
import ClientesFinanceiro from './ClientesFinanceiro.js';


class Clientes extends ComponentErp {
  constructor(props) {
    super(props);
    this.state = store.getState().clientes.grids;
  }

  componentDidMount() {
    this.callApi()
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.setState({ refresh: 'buscando' });
      this.callApi()
    }

    if (this.state.refresh === 'limpando') {
      this.actionClick('.cadastro');
    }

    if (this.state.refresh === 'endereco' || this.state.refresh === 'listaendereco') {
      this.actionClick('.endereco');
    }

    if (this.state.refresh === 'financeiro' || this.state.refresh === 'listafinanceiro') {
      this.actionClick('.financeiro');
    }
  }


  handleSubmit = (event) => {
    event.preventDefault()

    var response = {
      refresh: 'gravando',
      fNome: this.state.fNome,
      fStatus: this.state.fStatus,
      type: 'CLIENTES.TOOGLE_FBUSCA'
    }
    this.updateReduxAndState(response);
  }

  clickChild = (event, id) => {
    this.clickChildModal(
      id,
      'concluido',
      store.getState().clientes.grids.edit.onClick,
      store.getState().clientes.grids.edit.itens);

  }

  clickChildCadastro = (event) => {

    event.preventDefault()
    var response = '';
    if (this.state.aba !== 'Cadastro' && this.state.id !== 0 && this.state.id !== store.getState().clientes.sIdCliente) {

      response = {
        id: this.state.id,
        type: 'CLIENTES.TOOGLE_CADASTRO'
      }
      this.updateRedux(response);

      this.setState({
        refresh: 'buscaclienteendereco2'
      });

      this.actionClick('.cadastro');
      this.clearModal();

    } else if (this.state.id === 0) {
      response = { type: 'CLIENTES.TOOGLE_CLEAN', refresh: 'limpando' }
      this.updateRedux(response);

    }
    if (this.state.aba !== 'Cadastro') {
      this.actionClick('.cadastro');
    }
    this.setAba('Cadastro');

  }

  clickChildFinanceiro = (event) => {
    if (this.state.aba !== 'Financeiro' && this.state.id !== 0 && this.state.id !== store.getState().clientes.financeiro.id_cliente) {
      var response = {
        financeiro: <ClientesFinanceiro />,
        refresh: 'listafinanceiro',
        id_cliente: this.state.id,
        type: 'CLIENTES.FINANCEIRO.TOOGLE_ID_CLIENTE'
      }
      this.updateReduxAndState(response);
    }

    if (this.state.aba !== 'Financeiro') {
      this.actionClick('.financeiro');
      this.clearModal();
      this.setState({
        refresh: 'listafinanceiro'
      });
    }

    this.setAba('Financeiro');
  }

  clickChildEndereco = (event) => {

    if (this.state.aba !== 'Endereco' && this.state.id !== 0 && this.state.id !== store.getState().clientes.enderecos.id_cliente) {

      var response = {
        endereco: <ClientesEndereco />,
        refresh: 'listaendereco',
        id_cliente: this.state.id,
        type: 'CLIENTES.ENDERECO.TOOGLE_ID_CLIENTE'
      }
      this.updateReduxAndState(response);
    }

    if (this.state.aba !== 'Endereco') {
      this.actionClick('.endereco');
      this.clearModal();
      this.setState({
        refresh: 'listaendereco'
      });
    }

    this.setAba('Endereco');
  }

  async callApi() {

    var grids = store.getState().clientes.grids;

    var response = await GetClientesAPI(grids.limit, grids.offset, grids.fNome, grids.fStatus);
    response.refresh = 'concluido';
    response.onClickModal = this.clickChild;
    response.callBackPaginacao = this.callBackPaginacao;
    response.editOnClick = [this.clickChildCadastro, this.clickChildEndereco, this.clickChildFinanceiro];

    let nav = store.getState().clientes.grids.nav;
    nav.disabled[2] = 'disabled';
    nav.onClick[0] = this.clickChildListaAba;
    nav.onClick[1] = this.clickChildCadastro;
    nav.onClick[2] = this.clickChildEndereco;
    nav.onClick[3] = this.clickChildFinanceiro;

    if (getMenuAcao(1, 8) === false) {
      delete nav.tabs[3];
    }

    response.nav = nav;

    response.type = 'CLIENTES.TOOGLE_LISTA';

    this.updateRedux(response);
    grids = store.getState().clientes.grids;
    this.setState(grids);
  }



  render() {

    return (

      <div className="container">
        <div className="row">
          <h2 className="section-title">Clientes</h2>
          <div className="card">
            {this.navMenu(store.getState().clientes.grids.nav)}
            <div id="lista" className="dataTables_wrapper no-footer row">
              <form id="form_busca" name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">

                {<FormEdit id='fNome'
                  onChange={this.handleInputChange}
                  label='Busque por nome'
                  value={this.state.fNome}
                  styleDiv='4' />}

                {<FormEdit id='fStatus'
                  label='Status'
                  styleDiv='4'
                  onChange={this.handleInputChange}
                  typeField='select'
                  value={this.state.fStatus}
                  optionsField={getSisStatus('status_padrao')} />}

                {<FormEdit id='submit'
                  label='Pesquisar'
                  styleDiv='submit'
                  styleField='submit'
                  typeField='submit'
                  icon='pesquisa'
                />}

                {<FormEdit id='cadNovo'
                  label='Novo Cadastro'
                  styleDiv='submit'
                  onClick={this.novoCadastro}
                  styleField='submit'
                  typeField='button'
                  icon='adiciona'
                />}

              </form>

              {this.Grid(store.getState().clientes.grids)}

            </div>
            <div id="cadastro" className="dataTables_wrapper no-footer row">
              {<ClientesUpdate />}
            </div>
            <div id="endereco" className="dataTables_wrapper no-footer row">
              {store.getState().clientes.grids.endereco}
            </div>
            <div id="financeiro" className="dataTables_wrapper no-footer row">
              {store.getState().clientes.grids.financeiro}
            </div>
          </div>
        </div>
      </div>

    );

  }
}
export default (Clientes);
