import React from 'react';
import ReactDOM from 'react-dom';
import Modal from '../components/Modal.js';

export const getDate = () => {
  var result = new Date().toLocaleString('pt-BR', { month: '2-digit', day: '2-digit', year: 'numeric' });
  return result;
}

export const getDateYear = () => {
  var result = new Date();
  return result.getFullYear();
}

export const addDateDay = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result.toLocaleString('pt-BR', { month: '2-digit', day: '2-digit', year: 'numeric' });
}

export const convertDateToDb = (value) => {
  if (value.substr(2, 1) === '/' && value.length === 10)
    return value.substr(6, 4) + '-' + value.substr(3, 2) + '-' + value.substr(0, 2);
  else
    return value;
}

export const convertDate = (value) => {
  if (value.substr(4, 1) === '-' && value.length === 10)
    return value.substr(8, 2) + '/' + value.substr(5, 2) + '/' + value.substr(0, 4);
  else
    return value;
}

export const warningMessage = (value) => {
  
  ReactDOM.render(<Modal type="warning"
    button="Fechar"
    title="Atenção!"
    content={value}
  />
    , document.getElementById('sys-modal'));
}

export const warningMessageCatch = (value) => {
  var errorCatch = 'Erro de comunicação com o servidor';
  if (value !== undefined)
    errorCatch = value.data.error;

  ReactDOM.render(<Modal type="warning"
    button="Fechar"
    title="Atenção!"
    content={errorCatch}
  />
    , document.getElementById('sys-modal'));
}

export const questionsMessage = (value,content) => {
  ReactDOM.render(<Modal type="warning"
  button="Fechar"
  title={value}
  content={content}
/>
  , document.getElementById('sys-modal'));
}


export const clearMessage = () =>{
  ReactDOM.render(<Modal type="clear" />, document.getElementById('sys-modal'));
}

export const progressBar = () =>{
  ReactDOM.render(<Modal type="progress" />, document.getElementById('sys-modal'));
}
