import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import FormEdit from '../components/FormEdit.js';
import { GetRelatorioPorFornAPI } from '../models/ModelContasPagar.js';


class RelatorioContasPagarPorFornecedores extends ComponentErp {
  constructor(props) {
    super(props);
    this.state = store.getState().relContasPagarGeral.gridsPorForn;
  }

  componentDidMount() {
    this.callApi()
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.setState({ refresh: 'buscando' });
      this.callApi()
    }

  }

  handleSubmit = (event) => {
    event.preventDefault()
    var response = {
      refresh: 'gravando',
      fAno: this.state.fAno,
      id_fornecedor: this.state.id_fornecedor,
      type: 'RELCONTASPAGARPORFORN.TOOGLE_FBUSCA'
    }
    this.updateReduxAndState(response);

  }

  async callApi() {
    var gridsPorForn = store.getState().relContasPagarGeral.gridsPorForn;
    var response = await GetRelatorioPorFornAPI(gridsPorForn.fAno, gridsPorForn.id_fornecedor);
    response.refresh = 'concluido';
    response.type = 'RELCONTASPAGARPORFORN.TOOGLE_LISTA';

    this.updateRedux(response);
    gridsPorForn = store.getState().relContasPagarGeral.gridsPorForn;
    this.setState(gridsPorForn);
  }



  render() {

    return (

      <div className="container">
        <div className="row">
          <h2 className="section-title">Relatório - Despesa por Fornecedor</h2>
          <div className="card">
            {this.navMenu(store.getState().relContasPagarGeral.gridsPorForn.nav)}

            <div id="lista" className="dataTables_wrapper no-footer row">
              <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">

                {<FormEdit id='fAno'
                  label='Ano'
                  value={this.state.fAno}
                  onChange={this.handleInputChange}
                  typeField='number'
                  styleDiv='4' />}

                {<FormEdit id='nome_fornecedor'
                  label='Nome Fornecedor'
                  styleDiv='2'
                  typeField='autocomplete'
                  onChange={this.handleInputChangeFornecedor}
                  loadData={this.loadDataFornecedor}
                  options={[(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }]}
                  value={(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }}
                  required='on'
                />}

                {<FormEdit id='submit'
                  label='Pesquisar'
                  styleDiv='submit'
                  styleField='submit'
                  typeField='submit'
                  icon='pesquisa'
                />}

              </form>

              {this.GridFull(store.getState().relContasPagarGeral.gridsPorForn)}
            </div>

          </div>
        </div>
      </div>

    );

  }
}
export default (RelatorioContasPagarPorFornecedores);
