import { getDate, addDateDay } from '../../services/Funcoes.js';

const INITIAL_STATE = {
    grids:
    {
        redux_prefix: 'CONTASPAGAR.',
        header: ['ID', '','Fornecedor', 'Cadastro', 'Vencimento', 
            'Valor', 'Valor Pago','Forma de Pagto','Plano de Contas'],
        colunas: ['id_contas_pagar','color', 'nome', 'conta_pag_data_cad', 'conta_pag_data_venc',
            'conta_pag_valor_total', 'conta_pag_valor_pago','forma_pgto_nome','conta'],
        checkbox: '',
        styles: ['50px','25px', '', '60px', '50px', '70px', '70px'],
        lista: [],

        limit: 25,
        offset: 1,
        pages: 0,
        fNome: '',
        fDataIni: getDate(),
        fDataFim: addDateDay(new Date(), 7),
        fDataTipo: 'vencimento',
        endereco: null,
        nav: {
            tabs: ['Lista de contas à pagar', 'Cadastrar'],
            className: ['lista active', 'cadastro'],
            disabled: [null, null],
            href: ['#lista', '#cadastro'],
            onClick: [null, null]
        },
        edit: {
            itens: ['Alterar Cadastro'],
            onClick: ['']
        },
        onClickModal: '',
        id: 0,
        refresh: 'concluido' //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
    },
    cadastro:
    {
        refresh: 'concluido', //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        id: 0,
        id_contas_pagar: 0,
        id_contas_pagar_pai: '',
        id_fornecedor: '',
        id_emp_planoconta: '',
        id_emp_planoconta_item: '',
        id_sis_forma_pgto: '',
        id_sis_status: '',
        conta_pag_parcelas: '',
        conta_pag_parcela_num: '',
        conta_pag_nf: '',
        conta_pag_cod_barra: '',
        conta_pag_descricao: '',
        conta_pag_valor_total: '',
        conta_pag_valor_pago: '',
        conta_pag_valor_desc: '',
        conta_pag_valor_juros: '',
        conta_pag_data_venc: '',
        conta_pag_data_compra: '',
        conta_pag_data_pgto: ''
    }

}

export default function contasPagar(state = INITIAL_STATE, action) {

    var grid = state.grids;
    if (action.type === 'CONTASPAGAR.TOOGLE_LISTA') {
        grid.erro = action.erro;
        if (grid.erro === 0) {
            grid.lista = action.lista;
            grid.onClickModal = action.onClickModal;
            grid.callBackPaginacao = action.callBackPaginacao;
            grid.pages = action.pages;
            grid.limit = action.limit;
            grid.offset = action.offset;
            grid.edit.onClick = action.editOnClick;
        }
        return { ...state, grid }
    }

    if (action.type === 'CONTASPAGAR.TOOGLE_FBUSCA') {
        grid.fNome = action.fNome;
        grid.fDataIni = action.fDataIni;
        grid.fDataFim = action.fDataFim;
        grid.fDataTipo = action.fDataTipo;
        return { ...state, grid }
    }

    if (action.type === 'CONTASPAGAR.TOOGLE_LIMIT') {
        grid.offset = 1;
        grid.limit = action.limit;
        return { ...state, grid }
    }

    if (action.type === 'CONTASPAGAR.TOOGLE_OFFSET') {
        grid.offset = action.offset;
        return { ...state, grid }
    }

    var cadastro = state.cadastro;
    if (action.type === 'CONTASPAGAR.TOOGLE_CADASTRO') {
        cadastro.id = action.id;
        cadastro.refresh = 'buscando';
        return { ...state, cadastro }
    }

    if (action.type === 'CONTASPAGAR.TOOGLE_REFRESH') {
        cadastro.refresh = 'concluido';
        return { ...state, cadastro }
    }


    if (action.type === 'CONTASPAGAR.TOOGLE_SET_ID_FORNECEDOR') {
        cadastro.refresh = 'concluido';
        cadastro.id_fornecedor = action.id_fornecedor;
        return { ...state, cadastro }
    }

    if (action.type === 'CONTASPAGAR.TOOGLE_SET_ID') {
        cadastro.refresh = 'concluido';
        cadastro.id_contas_pagar = action.id_contas_pagar;
        cadastro.id_contas_pagar_pai = 1;
        cadastro.id_fornecedor = '';
        cadastro.id_emp_planoconta = '';
        cadastro.id_emp_planoconta_item = '';
        cadastro.id_sis_forma_pgto = '';
        cadastro.id_sis_status = '';
        cadastro.conta_pag_parcelas = '';
        cadastro.conta_pag_parcela_num = '';
        cadastro.conta_pag_nf = '';
        cadastro.conta_pag_cod_barra = '';
        cadastro.conta_pag_descricao = '';
        cadastro.conta_pag_valor_total = '';
        cadastro.conta_pag_valor_pago = '';
        cadastro.conta_pag_valor_desc = '';
        cadastro.conta_pag_valor_juros = '';
        cadastro.conta_pag_data_venc = '';
        cadastro.conta_pag_data_pgto = '';
        cadastro.conta_pag_data_compra = '';
        return { ...state, cadastro }
    }

    if (action.type === 'CONTASPAGAR.TOOGLE_GET_ID') {
        cadastro.refresh = 'concluido';
        cadastro.id_contas_pagar = action.id_contas_pagar;
        cadastro.id_contas_pagar_pai = action.id_contas_pagar_pai;
        cadastro.id_fornecedor = action.id_fornecedor;
        cadastro.id_emp_planoconta = action.id_emp_planoconta;
        cadastro.id_emp_planoconta_item = action.id_emp_planoconta_item;
        cadastro.nome_fornecedor = action.nome_fornecedor;
        cadastro.id_sis_forma_pgto = action.id_sis_forma_pgto;
        cadastro.id_sis_status = action.id_sis_status;
        cadastro.conta_pag_parcelas = action.conta_pag_parcelas;
        cadastro.conta_pag_parcela_num = action.conta_pag_parcela_num;
        cadastro.conta_pag_nf = action.conta_pag_nf;
        cadastro.conta_pag_cod_barra = action.conta_pag_cod_barra;
        cadastro.conta_pag_descricao = action.conta_pag_descricao;
        cadastro.conta_pag_valor_total = action.conta_pag_valor_total;
        cadastro.conta_pag_valor_pago = action.conta_pag_valor_pago;
        cadastro.conta_pag_valor_desc = action.conta_pag_valor_desc;
        cadastro.conta_pag_valor_juros = action.conta_pag_valor_juros;
        cadastro.conta_pag_data_venc = action.conta_pag_data_venc;
        cadastro.conta_pag_data_pgto = action.conta_pag_data_pgto;
        cadastro.conta_pag_data_compra = action.conta_pag_data_compra;
        return { ...state, cadastro }
    }


    if (action.type === 'CONTASPAGAR.TOOGLE_CLEAN') {

        cadastro.refresh = action.refresh;
        cadastro.id = 0;
        cadastro.id_contas_pagar = 0;
        cadastro.id_contas_pagar_pai = '';
        cadastro.id_fornecedor = '';
        cadastro.id_emp_planoconta = '';
        cadastro.id_emp_planoconta_item = '';
        cadastro.id_sis_forma_pgto = '';
        cadastro.id_sis_status = '';
        cadastro.conta_pag_parcelas = '';
        cadastro.conta_pag_parcela_num = '';
        cadastro.conta_pag_nf = '';
        cadastro.conta_pag_cod_barra = '';
        cadastro.conta_pag_descricao = '';
        cadastro.conta_pag_valor_total = '';
        cadastro.conta_pag_valor_pago = '';
        cadastro.conta_pag_valor_desc = '';
        cadastro.conta_pag_valor_juros = '';
        cadastro.conta_pag_data_venc = '';
        cadastro.conta_pag_data_pgto = '';
        cadastro.conta_pag_data_compra = '';
        return { ...state, cadastro }
    }


    return state;
}