import api from '../services/Api.js';
import { clearMessage, warningMessage, warningMessageCatch, progressBar } from '../services/Funcoes.js';


/**
 * Autocomplete de Produtos
 * @param {*} fIdFornecedor 
 * @param {*} fNome 
 */

export const GetProdutosAutocompleteAPI = (fIdFornecedor, fNome) => {
  return api.get('/produto/autocomplete?fid_fornecedor=' + fIdFornecedor + '&fnome=' + fNome)
    .then(response => {
      return {
        lista: response.data.result.lista,
        erro: 0
      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })
}

/**
 * Lista de Produtos
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome 
 */

export const GetProdutosAPI = (limit, offset, fNome, id_fornecedor, fSituacao) => {

  progressBar();


  return api.get('/produto/lista?limit=' + limit + '&offset=' + offset + '&fnome=' + fNome + '&id_fornecedor=' + id_fornecedor + '&fsituacao=' + fSituacao)
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        pages: response.data.result.pages,
        limit: response.data.result.limit,
        offset: response.data.result.offset,
        erro: 0
      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}


/* 
 * Busca Produto
 * @param {*} id  
 */
export const GetProdutoAPI = (id) => {
  progressBar();
  return api.get('/produto/' + id)
    .then(response => {
      clearMessage();
      var ret = response.data.result;
      ret.id = id;
      ret.id_produto = id;
      ret.refresh = 'concluido';
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }

    })

}

/* 
 * Cadastro do Produto
 * @param {*} id 
 * @param {*} data 
 */
export const PostProdutoAPI = (id, data) => {
  progressBar();

  var url = '';
  if (id === 0) {
    url = '/produto/add';
  } else {
    url = '/produto/' + id;
  }

  return api.post(url, data)
    .then(response => {
      warningMessage(response.data.result.mensagem);

      return {
        erro: 0,
        refresh: 'concluido'
      }


    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }
    })

}
