const INITIAL_STATE = {
    grids:
    {
        redux_prefix: 'FORNECEDORES.',
        header: ['ID', 'CPF/CNPJ', 'Nome', 'Telefone', 'E-mail'],
        colunas: ['id_fornecedor', 'forn_cnpj', 'forn_razao', 'forn_tel1', 'forn_email'],
        checkbox: '',
        styles: ['50px', '', '', '', ''],
        lista: [],

        limit: 25,
        offset: 1,
        pages: 0,
        fNome: '',
        fStatus: '',
        endereco: null,
        nav: {
            tabs: ['Lista de Fornecedores', 'Cadastrar', 'Endereço'],
            className: ['lista active', 'cadastro', 'endereco disabled'],
            disabled: [null, null, 'disabled'],
            href: ['#lista', '#cadastro', '#endereco'],
            onClick: [null, null, null]
        },
        edit: {
            itens: ['Alterar Cadastro', 'Alterar Endereços'],
            onClick2: ['', '']
        },
        onClickModal: '',
        id: 0,
        refresh: 'concluido'  
    },
    cadastro:
    {
        refresh: 'concluido', //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        id: 0,
        sIdFornecedor: '',
        sStatus: '',
        sCnpj: '',
        sFornRazao: '',
        sFornFantasia: '',
        sFornTel1: '',
        sFornCel1: '',
        sFornEmail: ''
    },
    enderecos:
    {
        refresh: 'concluido', //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        header: ['ID', 'Tipo', 'Principal?', 'CEP', 'Endereço', 'Bairro', 'Cidade', 'Estado'],
        colunas: ['id_fornecedor_end', 'tp_end', 'forn_principal', 'forn_cep', 'forn_endereco', 'forn_bairro', 'cidade', 'estado'],
        checkbox: '',
        styles: ['50px', '50px', '50px', '70px', '', '', '', '50px'],
        lista: [],
        fornecedor: '',
        limit: 25,
        offset: 1,
        pages: 0,
        btnCadastro: null,
        onClickModal: '',
        id_fornecedor: 0,
        id_fornecedor_end: 0
    }

}

export default function fornecedores(state = INITIAL_STATE, action) {
    var grid = state.grids;
    var cadastro = state.cadastro;
    var enderecos = state.enderecos;
    if (action.type === 'FORNECEDORES.TOOGLE_LISTA') {
        grid.erro = action.erro;
        if (grid.erro === 0) {
            grid.lista = action.lista;
            grid.onClickModal = action.onClickModal;
            grid.callBackPaginacao = action.callBackPaginacao;
            grid.pages = action.pages;
            grid.limit = action.limit;
            grid.offset = action.offset;
            grid.edit.onClick = action.editOnClick;
        }
        grid.nav = action.nav;
        grid.nav.disabled[2] = 'disabled';

        return { ...state, grid }
    }

    if (action.type === 'FORNECEDORES.TOOGLE_FBUSCA') {
        grid.fNome = action.fNome;
        grid.fStatus = action.fStatus;
        return { ...state, grid }
    }

    if (action.type === 'FORNECEDORES.TOOGLE_LIMIT') {
        grid.offset = 1;
        grid.limit = action.limit;
        return { ...state, grid }
    }
    if (action.type === 'FORNECEDORES.TOOGLE_OFFSET') {
        grid.offset = action.offset;
        return { ...state, grid }
    }

    if (action.type === 'FORNECEDORES.TOOGLE_CADASTRO') {
        cadastro.id = action.id;
        cadastro.refresh = 'buscando';
        grid.nav.disabled[2] = null;
        return { ...state, cadastro, grid }
    }

    if (action.type === 'FORNECEDORES.TOOGLE_REFRESH') {
        cadastro.refresh = 'concluido';
        return { ...state, cadastro }
    }

    if (action.type === 'FORNECEDORES.TOOGLE_GET_ID') {
        cadastro.refresh = 'concluido';
        cadastro.sStatus = action.sStatus;
        cadastro.sIdFornecedor = action.sIdFornecedor;
        cadastro.sCnpj = action.sCnpj;
        cadastro.sFornRazao = action.sFornRazao;
        cadastro.sFornFantasia = action.sFornFantasia;
        cadastro.sFornTel1 = action.sFornTel1;
        cadastro.sFornCel1 = action.sFornCel1;
        cadastro.sFornEmail = action.sFornEmail;
        return { ...state, cadastro }
    }

    if (action.type === 'FORNECEDORES.TOOGLE_CLEAN') {
        cadastro.refresh = action.refresh;
        cadastro.id = 0;
        cadastro.sStatus = '';
        cadastro.sIdFornecedor = '';
        cadastro.sCnpj = '';
        cadastro.sFornRazao = '';
        cadastro.sFornFantasia = '';
        cadastro.sFornTel1 = '';
        cadastro.sFornCel1 = '';
        cadastro.sFornEmail = '';
        grid.nav.disabled[2] = 'disabled';

        return { ...state, cadastro, grid }
    }
    
    if (action.type === 'FORNECEDORES.ENDERECO.TOOGLE_ID_FORNECEDOR') {
        enderecos.refresh = action.refresh;
        cadastro.id = action.id_fornecedor;
        grid.nav.disabled[2] = null;
        grid.endereco = action.endereco;

        return { ...state, enderecos, cadastro, grid }
    }
   
    if (action.type === 'FORNECEDORES.ENDERECO.TOOGLE_REFRESH') {
        enderecos.refresh = 'concluido';
        grid.refresh = 'concluido';
        return { ...state, enderecos, grid }
    }

    if (action.type === 'FORNECEDORES.ENDERECO.TOOGLE_LISTA') {
        
        enderecos.erro = action.erro;
        if (enderecos.erro === 0) {
            enderecos.id_fornecedor = action.id_fornecedor;
            enderecos.fornecedor = action.fornecedor;
            enderecos.lista = action.lista;
            enderecos.btnCadastro = action.btnCadastro;
            enderecos.onClickModal = action.onClickModal;
            enderecos.callBackPaginacao = action.callBackPaginacao;
            enderecos.pages = action.pages;
            enderecos.limit = action.limit;
            enderecos.offset = action.offset;
        }
        return { ...state, enderecos }
    }

    return state;
}