import api from '../services/Api.js';
import { clearMessage, warningMessage, warningMessageCatch, progressBar } from '../services/Funcoes.js';
import { login } from '../services/Auth.js';

/**
 * Lista de Colaboradores
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome 
 */

export const GetColaboradoresAPI = (limit, offset, fNome, fStatus) => {

  progressBar();


  return api.get('/colaborador/lista?limit=' + limit + '&offset=' + offset + '&fnome=' + fNome +
    '&fstatus=' + fStatus)
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        pages: response.data.result.pages,
        limit: response.data.result.limit,
        offset: response.data.result.offset,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}


/* 
 * Busca Colaborador
 * @param {*} id  
 */
export const GetColaboradorAPI = (id) => {
  progressBar();
  return api.get('/colaborador/' + id)
    .then(response => {
      clearMessage();
      var ret = response.data.result;
      ret.id = id;
      ret.id_colaborador = id;
      ret.refresh = 'concluido';
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }

    })

}

/* 
 * Cadastro do Colaborador
 * @param {*} id 
 * @param {*} data 
 */
export const PostColaboradorAPI = (id, data) => {
  progressBar();

  var url = '';
  if (id === 0) {
    url = '/colaborador/add';
  } else {
    url = '/colaborador/' + id;
  }

  return api.post(url, data)
    .then(response => {
      warningMessage(response.data.result.mensagem);
      return {
        erro: 0,
        refresh: 'concluido'
      }

    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }
    })

}

/* 
 * Senha do Colaborador
 * @param {*} data 
 */
export const PostColaboradorSenhaAPI = (data) => {
  progressBar();

  var url = '/usuario/senha';

  return api.post(url, data)
    .then(response => {
      warningMessage(response.data.result.mensagem);
      login(response.data.result.token);
      var ret = response.data.result;
      ret.refresh = 'concluido';
      ret.erro = 0;
      return ret

    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }
    })

}

/* 
 * Busca Permissoes do Colaborador
 * @param {*} id  
 */
export const GetColaboradorPermissoesAPI = (id) => {
  progressBar();
  return api.get('/colaborador/' + id + '/permissoes')
    .then(response => {
      clearMessage();
      var ret = response.data.result;
      ret.id = id;
      ret.id_empresa_usu = id;
      ret.refresh = 'concluido';
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }

    })

}

/* 
 * Grava Permissões do Colaborador
 * @param {*} id 
 * @param {*} data 
 */
export const PostColaboradorPermissoesAPI = (id, data) => {
  progressBar();

  var url = '/colaborador/' + id + '/permissoes';

  return api.post(url, data)
    .then(response => {
      warningMessage(response.data.result.mensagem);
      return {
        erro: 0,
        refresh: 'concluido'
      }

    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }
    })

}
