import { getDate } from '../../services/Funcoes.js';

const INITIAL_STATE = {
    grids:
    {
        redux_prefix: 'FLUXOCAIXA.',
        header: ['ID', 'Data', 'Valor', 'C/D', 'Descrição', 'Tipo de lançamento'],
        colunas: ['id_financeiro','fin_data_cad','fin_vlr',  'fin_tipo', 'fin_descricao', 'tipo_lancamento'],
        checkbox: '',
        styles: [ '50px','70px', '70px','50px', '', ''],
        lista: [],
        saldo: '',
        creditos: '',
        debitos: '',
        fData: getDate(),
        nav: {
            tabs: ['Fluxo de caixa'],
            className: ['lista active'],
            disabled: [null],
            href: ['#lista'],
            onClick: [null]
        },
        edit: {
            itens: [''],
            onClick: ['']
        },
        id: 0, 
        refresh: 'concluido' //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
    },
    cadastro:
    {
        refresh: 'concluido', //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        id: 0,
        id_financeiro: '',
        id_cliente: '',
        fin_vlr: '',
        fin_descricao: '', 
        fin_tipo: '' 
        
    }

}

export default function fluxoCaixa(state = INITIAL_STATE, action) {
    var grid = state.grids;
    if (action.type === 'FLUXOCAIXA.TOOGLE_LISTA') {
        grid.erro = action.erro;
        if (grid.erro === 0) {
            grid.lista = action.lista;
            grid.saldo = action.saldo;
            grid.debitos = action.debitos;
            grid.creditos = action.creditos;
            grid.edit.onClick = action.editOnClick;
        }
        return { ...state, grid }
    }

    if (action.type === 'FLUXOCAIXA.TOOGLE_FBUSCA') {
        grid.fData = action.fData;
        return { ...state, grid }
    }



    var cadastro = state.cadastro;
    if (action.type === 'CONTASRECEBER.TOOGLE_REFRESH') {
        cadastro.refresh = 'concluido';
        return { ...state, cadastro }
    }

    if (action.type === 'CONTASRECEBER.TOOGLE_CLEAN') {
        cadastro.refresh = action.refresh;
        cadastro.id = 0;
        cadastro.id_financeiro = 0;
        cadastro.fin_vlr = '';
        cadastro.fin_descricao = '';
        cadastro.fin_tipo = '';
        return { ...state, cadastro }
    }

    return state;
}   