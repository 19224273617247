import React, { Component } from 'react';
import { getMenu, setMenuActive, setMenuCompacto, getMenuCompacto, getEmpresa, getUsuario }
	from '../services/Auth.js';

class Header extends Component {
	constructor(props) {

		super(props);
		this.state = {
			usuario: '',
			empresa: '',
			menufixo: ' sidenav-fixed',
			menucompacto: 'Menu Compacto',
			permissoes: '',
			empresa_img: '/assets/images/icon-tec2web.png'
		}

	}

	componentDidUpdate(prevProps, prevState) {
	}

	componentDidMount(prevProps, prevState) {
		if (getMenuCompacto() === 1) {
			var x = document.body;
			x.className = "";
			this.setState({
				menufixo: '',
				menucompacto: 'Menu Fixo'
			});

		}
	}

	clickMenu = (event) => {
		setMenuActive(event);
	}

	hideMenu = (event) => {
		//event.preventDefault()
		var x = document.body;
		if (this.state.menufixo !== '') {
			setMenuCompacto(1);
			x.className = "";
			x.querySelector('div.sidenav-overlay').style.transform = 'translateX(0%)';
			x.querySelector('div.sidenav-overlay').click();
			this.setState({
				menufixo: '',
				menucompacto: 'Menu Fixo'
			});
		} else {
			setMenuCompacto(0);
			x.className = "has-fixed-sidenav"
			x.querySelector('div.sidenav-overlay').style.transform = 'translateX(-105%)';
			this.setState({
				menufixo: 'sidenav-fixed',
				menucompacto: 'Menu Compacto'
			});

		}

	}

	itensMenu = (menu, icon, link) => {
		return (
			<a className="collapsible-header" href={link} onClick={e => e.preventDefault()}>
				{menu}
				<i className={'material-icons ' + icon}>{icon + '_left'}</i>
			</a>
		);
	}

	itensSubMenu = (res) => {

		if (res.length > 0) {
			var listSubItens = res.map((item, key) =>

				<li key={item.id_sis_menu}><a href={item.link} className="waves-effect">
					{item.menu}<i className="material-icons">{item.icon}</i>
				</a></li>

			);

			return (<div className="collapsible-body"><ul>{listSubItens}</ul></div>);
		} else {
			return ('');
		}

	}


	itensMenuFull = () => {
		var vmenu = getMenu();
		if (vmenu !== null) {
			return vmenu.map((item, key) =>
				<li className={"bold waves-effect " + item.active} key={item.id_sis_menu} onClick={e => this.clickMenu(item.id_sis_menu)}>
					{this.itensMenu(item.menu, item.icon, item.link)}
					{this.itensSubMenu(item.itens)}
				</li>
			);
		} else {
			return ('');
		}
	}

	render() {
		return (
			<div>
				<div className="navbar-fixed">
					<nav className="navbar white">
						<div className="nav-wrapper"><a href="#!" className="brand-logo grey-text text-darken-4">Usuario: {getUsuario()}</a>
							<a href="#!" data-target="sidenav-left" className="sidenav-trigger left">
								<i className="material-icons black-text">menu</i>
							</a>

						</div>
					</nav>
				</div>
				<ul id="sidenav-left" className={'sidenav ' + this.state.menufixo}>
					<li>
						<a href="/home" className="logo-container">
							{getEmpresa()}{this.state.empresa}
							<img id="logomenu" src={this.state.empresa_img} alt="Logo empresa" className="left mt10 mr20" />
						</a>
					</li>
					<li className="no-padding">
						<ul className="collapsible collapsible-accordion">
							{this.itensMenuFull()}
							<li className="bold waves-effect menucompacto">
								<a className="collapsible-header" href="#!" onClick={this.hideMenu}>
									{this.state.menucompacto}
								</a>
							</li>

						</ul>
					</li>
					<li className="center">
						<div className="row">
							<a href="https://tec2web.com.br">
								<img className="materialize-logo" src="/assets/images/tec2web.png" alt="Tec2Web" />
							</a>
						</div>
						<div className="row white-text">suporte@tec2web.com.br</div>
						<div className="row white-text">(11) 97243-7198</div>
					</li>
				</ul>

			</div>
		);


	}
}

export default Header;
