import api from '../services/Api.js';
import { clearMessage, warningMessage, warningMessageCatch, progressBar } from '../services/Funcoes.js';

/**
 * Lista de Fluxo de Caixa
 * @param {*} fData
 */

export const GetFluxoCaixaAPI = (fData) => {

  progressBar();


  return api.get('/fluxocaixa/lista?fdata=' + fData)
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        saldo: response.data.result.saldo,
        creditos: response.data.result.creditos,
        debitos: response.data.result.debitos,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}

/* 
 * Cadastro de Fluxo de Caixa
 * @param {*} data 
 */
export const PostFluxoCaixaAPI = (data) => {
  progressBar();

  var url = '';
  url = '/fluxocaixa/add';

  return api.post(url, data)
    .then(response => {
      warningMessage(response.data.result.mensagem);

      return {
        erro: 0,
        refresh: 'concluido'
      }

    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }
    })

}


/* 
 * Delete ID do Fluxo de Caixa
 * @param {*} id 
 */
export const PostFluxoCaixaDelAPI = (id) => {
  progressBar();

  var url = '/fluxocaixa/del/' + id;

  return api.get(url)
    .then(response => {
      warningMessage(response.data.result.mensagem);

      return {
        erro: 0,
        refresh: 'concluido'
      }

    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }
    })

}
