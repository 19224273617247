import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import { getSisUnidades } from '../services/Auth.js';
import FormEdit from '../components/FormEdit.js';
import { GetProdutoAPI, PostProdutoAPI } from '../models/ModelProdutos.js';

class ProdutosUpdate extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = store.getState().produtos.cadastro;

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;


    document.body.appendChild(script);

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.callApi();
      this.setState({
        refresh: 'concluido'
      })
    }

    var response = {}
    if (store.getState().produtos.cadastro.refresh === 'buscando' &&
      store.getState().produtos.cadastro.id !== store.getState().produtos.cadastro.id_produto
    ) {
      response = { type: 'PRODUTOS.TOOGLE_REFRESH' }
      this.updateRedux(response);
      this.callApiGetProduto(store.getState().produtos.cadastro.id)
    }

    if (store.getState().produtos.cadastro.refresh === 'limpando') {
      response = { refresh: 'concluido', type: 'PRODUTOS.TOOGLE_CLEAN' }
      this.updateRedux(response);
      var cadastro = store.getState().produtos.cadastro;
      this.setState(cadastro);
    }

  }

  async callApi() {

    let data =
    {
      id_fornecedor: this.state.id_fornecedor,
      id_produto_unidade: this.state.id_produto_unidade,
      xProd: this.state.xProd,
      cProd: this.state.cProd,
      qCom: this.state.qCom,
      vUnCom: this.state.vUnCom,
      vVenda: this.state.vVenda
    }
    var response = await PostProdutoAPI(this.state.id_produto, data);

    if (this.state.id_produto === 0 && response.erro === 0) {
      response.type = 'PRODUTOS.TOOGLE_CLEAN';
      this.updateRedux(response);
      response = store.getState().produtos.cadastro;
    }
    this.setState(response);

  }

  async callApiGetProduto(id) {

    var response = await GetProdutoAPI(id);

    response.refresh = 'concluido';
    if (response.erro === 0)
      response.type = 'PRODUTOS.TOOGLE_GET_ID';
    else
      response.type = 'PRODUTOS.TOOGLE_CLEAN';
    this.updateRedux(response);
    var cadastro = store.getState().produtos.cadastro;
    this.setState(cadastro);

  }

  render() {
    return (
      <form name="form" onSubmit={this.handleSubmitGravando} action="" method="get" encType="multipart/form-data">
        {<FormEdit id='id_produto'
          typeField='hidden'
          value={this.state.id_produto}
        />}

        {<FormEdit id='id_fornecedor'
          label='ID Fornecedor'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.id_fornecedor}
          typeField='readonly'
          required='on'
        />}

        {<FormEdit id='nome_fornecedor'
          label='Nome Fornecedor'
          styleDiv='2'
          typeField='autocomplete'
          onChange={this.handleInputChangeFornecedor}
          loadData={this.loadDataFornecedor}
          options={[(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }]}
          value={(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }}

          required='on'
        />}

        {<FormEdit id='cProd'
          label='Cód. Produto'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.cProd}
          required='on'
        />}

        {<FormEdit id='xProd'
          label='Nome do Produto'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.xProd}
          required='on'
        />}

        {<FormEdit id='qCom'
          label='Quantidade'
          styleDiv='2'
          onChange={this.handleInputChange}
          mask="numero"
          value={this.state.qCom}
        />}

        {<FormEdit id='id_produto_unidade'
          label='Unidade'
          styleDiv='2'
          onChange={this.handleInputChange}
          typeField='select'
          value={this.state.id_produto_unidade}
          required='on'
          optionsField={getSisUnidades()}
        />}

        {<FormEdit id='vUnCom'
          label='Valor de Compra'
          styleDiv='2'
          onChange={this.handleInputChange}
          mask="real"
          value={this.state.vUnCom}
        />}

        {<FormEdit id='vVenda'
          label='Valor de Venda'
          styleDiv='2'
          onChange={this.handleInputChange}
          mask="real"
          value={this.state.vVenda}
        />}

        {<FormEdit id='gravar'
          label='Gravar'
          styleDiv='submit right'
          styleField='submit'
          typeField='submit'
          icon='gravar'
        />}
      </form>

    );

  }
}


export default ProdutosUpdate;
