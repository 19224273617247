import React from 'react';
import ComponentErp from './ComponentErp.js';
import {logout} from '../services/Auth.js';
import { Redirect } from "react-router-dom";

class Sair extends ComponentErp {
  
  componentDidMount() {
    logout();
  }

  componentDidUpdate(prevProps, prevState) {

  }

  render() {

    return (
      <div className="container">
        <Redirect to="/" />
      </div>

    );

  }
}
export default (Sair); 
