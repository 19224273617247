import React, {Component} from 'react';

class Collection extends Component{
  
  loadContent = () =>{
    var content = this.props.itens;
    if(typeof content==='string'){
      return null;
    }else{
			return content.map((item, key) => 		
        <a  key={key} href="#!" onClick={this.props.onClick[key]} className="collection-item">{item}</a>  
			);
    }
  }

  render() {
      return (
        <div className="collection">
          {this.loadContent()}
        </div>    
      );
    
          
  }

}

export default Collection;
