import React from 'react';
import ComponentErp from './ComponentErp.js';

import store from '../store';
import FormEdit from '../components/FormEdit.js';
import { GetComandasAPI } from '../models/ModelComandas.js';
import ComandasUpdate from './ComandasUpdate.js';
import { convertDate } from '../services/Funcoes.js';


class Comandas extends ComponentErp {
  constructor(props) {
    super(props);
    this.state = store.getState().comandas.grids;
  }

  componentDidMount() {
    this.callApi()
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.setState({ refresh: 'buscando' });
      this.callApi()
    }

  }

  handleSubmit = (event) => {
    event.preventDefault()
    var response = {
      refresh: 'gravando',
      fNome: this.state.fNome,
      fData: convertDate(this.state.fData),
      type: 'COMANDAS.TOOGLE_FBUSCA'
    }

    this.updateReduxAndState(response);

  }

  novoCadastro = (event) => {
    event.preventDefault()

    var response = { type: 'COMANDAS.TOOGLE_CLEAN', refresh: 'limpando' }
    this.updateReduxAndState(response);

    this.actionClick('.cadastro');
    this.clearModal();

  }


  clickChildCadastro = (event, id) => {

    event.preventDefault()
    var response = '';
    if (id !== 0 && id !== store.getState().produtos.id_produto) {

      response = {
        id: id,
        type: 'COMANDAS.TOOGLE_CADASTRO',
        refresh: 'buscacomanda'
      }
      this.updateReduxAndState(response);

      this.actionClick('.cadastro');
      this.clearModal();

    } else if (id === 0) {
      response = { type: 'PRODUTOS.TOOGLE_CLEAN', refresh: 'limpando' }
      this.updateReduxAndState(response);
    }
    if (this.state.aba !== 'Cadastro') {
      this.actionClick('.cadastro');
    }
    this.setAba('Cadastro');

  }

  async callApi() {
    var grids = store.getState().comandas.grids;

    var response = await GetComandasAPI(grids.limit, grids.offset, grids.fNome, grids.fData);
    response.refresh = 'concluido';
    response.onClickModal = this.clickChildCadastro;
    response.callBackPaginacao = this.callBackPaginacao;
    response.type = 'COMANDAS.TOOGLE_LISTA';

    this.updateRedux(response);
    grids = store.getState().comandas.grids;
    this.setState(grids);
  }



  render() {

    return (

      <div className="container">
        <div className="row">
          <h2 className="section-title">Comandas</h2>
          <div className="card">
            {this.navMenu(store.getState().comandas.grids.nav)}

            <div id="lista" className="dataTables_wrapper no-footer row">
              <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">

                {<FormEdit id='fNome'
                  onChange={this.handleInputChange}
                  label='Busque por nome'
                  value={this.state.fNome}
                  styleDiv='4' />}

                {<FormEdit id='fData'
                  onChange={this.handleInputChange}
                  label='Data'
                  typeField='date'
                  value={this.state.fData}
                  styleDiv='4' />}

                {<FormEdit id='submit'
                  label='Pesquisar'
                  styleDiv='submit'
                  styleField='submit'
                  typeField='submit'
                  icon='pesquisa'
                />}

                {<FormEdit id='cadNovo'
                  label='Novo Cadastro'
                  styleDiv='submit'
                  onClick={this.novoCadastro}
                  styleField='submit'
                  typeField='button'
                  icon='adiciona'
                />}

              </form>

              {this.Grid(store.getState().comandas.grids)}

            </div>
            <div id="cadastro" className="dataTables_wrapper no-footer row">
              {<ComandasUpdate />}
            </div>
          </div>
        </div>
      </div>

    );

  }
}
export default (Comandas);
