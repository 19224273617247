import api from '../services/Api.js';
import { clearMessage, warningMessage, warningMessageCatch, progressBar } from '../services/Funcoes.js';


/**
 * Lista de Contas A Pagar
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome 
 * @param {*} fDataIni 
 * @param {*} fDataFim 
 * @param {*} fDataTipo 
 */
 
export const GetContasPagarAPI = (limit, offset, fNome, fDataIni, fDataFim, fDataTipo) => {

  progressBar();


  return api.get('/contasapagar/lista?limit=' + limit + '&offset=' + offset + '&fnome=' + fNome +
    '&fdataini=' + fDataIni + '&fdatafim=' + fDataFim + '&fdatatipo=' + fDataTipo)
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        pages: response.data.result.pages,
        limit: response.data.result.limit,
        offset: response.data.result.offset,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}

/* 
 * Relatório  Contas A Pagar - Geral
 * @param {*} fAno  
 */
export const GetRelatorioGeralAPI = (fAno) => {

  progressBar();


  return api.get('/relatorios/contasapagar/geral?ano=' + fAno )
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}

/* 
 * Relatório  Contas A Pagar - Contas
 * @param {*} fAno  
 */
export const GetRelatorioContasAPI = (fAno) => {

  progressBar();


  return api.get('/relatorios/contasapagar/conta?ano=' + fAno )
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}

/* 
 * Relatório  Contas A Pagar - Contas por Fornecedor
 * @param {*} fAno  
 * @param {*} fIdFornecedor  
 */
export const GetRelatorioFornContasAPI = (fAno,fIdFornecedor) => {

  progressBar();


  return api.get('/relatorios/contasapagar/fornecedorcontas?ano=' + fAno+'&id_fornecedor=' + fIdFornecedor )
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}

/* 
 * Relatório  Contas A Pagar - Fornecedores
 * @param {*} fAno  
 * @param {*} fIdFornecedor
 */
export const GetRelatorioFornAPI = (fAno,fIdFornecedor) => {

  progressBar();


  return api.get('/relatorios/contasapagar/fornecedor?ano=' + fAno+'&id_fornecedor=' + fIdFornecedor )
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}

/* 
 * Relatório  Contas A Pagar - Por Fornecedores
 * @param {*} fAno  
 * @param {*} fIdFornecedor
 */
export const GetRelatorioPorFornAPI = (fAno,fIdFornecedor) => {

  progressBar();


  return api.get('/relatorios/contasapagar/porfornecedor?ano=' + fAno+'&id_fornecedor=' + fIdFornecedor )
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}

/* 
 * Excluir Contas A Pagar
 * @param {*} id  
 */
export const PostContasPagarDelAPI = (id) => {
  progressBar();

  var url = '/contasapagar/del/' + id;

  return api.get(url)
    .then(response => {
      warningMessage(response.data.result.mensagem);

      return {
        erro: 0,
        refresh: 'concluido'
      }

    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }
    })

}

/* 
 * Busca Contas A Pagar
 * @param {*} id  
 */
export const GetContasPagarIdAPI = (id) => {
  progressBar();
  return api.get('/contasapagar/' + id)
    .then(response => {
      clearMessage();
      var ret = response.data.result;
      ret.id = id;
      ret.id_contas_pagar = id;
      ret.refresh =  'concluido';
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }

    })

}

/* 
 * Cadastro de Contas A Pagar
 * @param {*} id 
 * @param {*} data 
 */
export const PostContasPagarAPI = (id, data) => {
  progressBar();

  var url = '';
  if (id === 0) {
    url = '/contasapagar/add';
  } else {
    url = '/contasapagar/' + id;
  }

  return api.post(url, data)
    .then(response => {
      warningMessage(response.data.result.mensagem);

      return {
        erro: 0,
        refresh: 'concluido'
      }

    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }
    })

}
