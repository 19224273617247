import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';
import { getSisStatus, getFormasPgto, getSisPlanoDebito, getSisPlanoItemDebito } from '../services/Auth.js';
import FormEdit from '../components/FormEdit.js';
import { PostContasPagarDelAPI, GetContasPagarIdAPI, PostContasPagarAPI } from '../models/ModelContasPagar.js';
import { convertDate } from '../services/Funcoes.js';

class ContasPagarUpdate extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = store.getState().contasPagar.cadastro;

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;


    document.body.appendChild(script);

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.callApi();
      this.setState({
        refresh: 'concluido'
      })
    }

    if (this.state.refresh === 'excluir') {
      this.callApiExcluir();
      this.setState({
        refresh: 'excluindo'
      })
    }

    var response = {}
    if (store.getState().contasPagar.cadastro.refresh === 'buscando' &&
      store.getState().contasPagar.cadastro.id !== store.getState().contasPagar.cadastro.id_contas_pagar
    ) {
      response = { type: 'CONTASPAGAR.TOOGLE_REFRESH' }
      this.updateRedux(response);
      this.callApiGetContasPagar(store.getState().contasPagar.cadastro.id)
    }

    if (store.getState().contasPagar.cadastro.refresh === 'limpando') {
      response = { refresh: 'concluido', type: 'CONTASPAGAR.TOOGLE_CLEAN' }
      this.updateRedux(response);
      var cadastro = store.getState().contasPagar.cadastro;
      this.setState(cadastro);
    }
  }

  handleInputContaDebito = (event) => {
    this.handleInputChange(event);
    this.setState({
      plano_debito: getSisPlanoItemDebito(event.target.value),
      id_emp_planoconta_item: null
    });

  }


  async callApi() {

    let data =
    {
      id_contas_pagar_pai: this.state.id_contas_pagar_pai,
      id_fornecedor: this.state.id_fornecedor,
      id_emp_planoconta: this.state.id_emp_planoconta,
      id_emp_planoconta_item: this.state.id_emp_planoconta_item,
      id_sis_forma_pgto: this.state.id_sis_forma_pgto,
      id_sis_status: this.state.id_sis_status,
      conta_pag_parcelas: this.state.conta_pag_parcelas,
      conta_pag_parcela_num: this.state.conta_pag_parcela_num,
      conta_pag_nf: this.state.conta_pag_nf,
      conta_pag_cod_barra: this.state.conta_pag_cod_barra,
      conta_pag_descricao: this.state.conta_pag_descricao,
      conta_pag_valor_total: this.state.conta_pag_valor_total,
      conta_pag_valor_pago: this.state.conta_pag_valor_pago,
      conta_pag_valor_desc: convertDate(this.state.conta_pag_valor_desc),
      conta_pag_valor_juros: convertDate(this.state.conta_pag_valor_juros),
      conta_pag_data_venc: convertDate(this.state.conta_pag_data_venc),
      conta_pag_data_compra: convertDate(this.state.conta_pag_data_compra),
      conta_pag_data_pgto: convertDate(this.state.conta_pag_data_pgto)
      
    }

    var response = await PostContasPagarAPI(this.state.id_contas_pagar, data);

    if (this.state.id_contas_pagar === 0 && response.erro === 0) {
      response.type = 'CONTASPAGAR.TOOGLE_CLEAN';
      this.updateRedux(response);
      response = store.getState().contasPagar.cadastro;
    }
    this.setState(response);


  }

  async callApiExcluir() {

    var response = await PostContasPagarDelAPI(this.state.id_contas_pagar);

    if (response.erro === 0) {
      response.type = 'CONTASPAGAR.TOOGLE_CLEAN';
      response.refresh = 'concluido';
      this.updateReduxAndState(response);
      var cadastro = store.getState().contasPagar.cadastro;
      this.setState(cadastro);
      this.actionClick('.lista');
      this.clearModal();
    }


  }

  async callApiGetContasPagar(id) {

    var response = await GetContasPagarIdAPI(id);

    response.refresh = 'concluido';
    if (response.erro === 0)
      response.type = 'CONTASPAGAR.TOOGLE_GET_ID';
    else
      response.type = 'CONTASPAGAR.TOOGLE_CLEAN';


    this.updateRedux(response);
    var cadastro = store.getState().contasPagar.cadastro;
    cadastro.plano_debito = getSisPlanoItemDebito(response.id_emp_planoconta);

    this.setState(cadastro);

  }

  handleExcluir = (event) => {
    event.preventDefault()

    this.setState({ refresh: 'excluir' });

  }

  render() {
    return (
      <form name="form" onSubmit={this.handleSubmitGravando} action="" method="get" encType="multipart/form-data">
        {<FormEdit id='id_fornecedor'
          label='ID Fornecedor'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.id_fornecedor}
          typeField='readonly'
          required='on'
        />}

        {<FormEdit id='nome_fornecedor'
          label='Nome Fornecedor'
          styleDiv='2'
          typeField='autocomplete'
          onChange={this.handleInputChangeFornecedor}
          loadData={this.loadDataFornecedor}
          options={[(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }]}
          value={(this.state.id_fornecedor !== '') ? { id: this.state.id_fornecedor, nome: this.state.nome_fornecedor } : { id: 0, nome: '' }}

          required='on'
        />}

        {<FormEdit id='id_emp_planoconta'
          label='Plano de Contas'
          styleDiv='2'
          onChange={this.handleInputContaDebito}
          typeField='select'
          value={this.state.id_emp_planoconta}
          required='on'
          optionsField={getSisPlanoDebito()}
        />}

        {<FormEdit id='id_emp_planoconta_item'
          label='Conta'
          styleDiv='2'
          onChange={this.handleInputChange}
          typeField='select'
          value={this.state.id_emp_planoconta_item}
          required='on'
          optionsField={this.state.plano_debito}
        />}

        {<FormEdit id='id_sis_forma_pgto'
          label='Forma de pagamento'
          styleDiv='4'
          onChange={this.handleInputChange}
          typeField='select'
          value={this.state.id_sis_forma_pgto}
          required='on'
          optionsField={getFormasPgto('forma_pgto_conta_pagar')}
        />}

        {<FormEdit id='conta_pag_parcelas'
          label='Qtde de parcelas'
          styleDiv='4'
          mask="numero"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_parcelas}
        />}

        {<FormEdit id='conta_pag_parcela_num'
          label='Número da parcela'
          styleDiv='4'
          mask="numero"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_parcela_num}
        />}

        {<FormEdit id='conta_pag_nf'
          label='Nota fiscal'
          styleDiv='4'
          mask="numero"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_nf}
        />}

        {<FormEdit id='conta_pag_descricao'
          label='Descrição'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.conta_pag_descricao}
        />}

        {<FormEdit id='conta_pag_cod_barra'
          label='Código de Barras'
          styleDiv='2'
          mask="numero"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_cod_barra}
        />}


        {<FormEdit id='conta_pag_valor_total'
          label='Valor total'
          styleDiv='4'
          mask="real"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_valor_total}
          required='on'
        />}

        {<FormEdit id='conta_pag_valor_desc'
          label='Desconto'
          styleDiv='4'
          mask="real"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_valor_desc}
        />}

        {<FormEdit id='conta_pag_valor_juros'
          label='Valor juros'
          styleDiv='4'
          mask="real"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_valor_juros}
        />}

        {<FormEdit id='conta_pag_valor_pago'
          label='Valor Pago'
          styleDiv='4'
          mask="real"
          onChange={this.handleInputChange}
          value={this.state.conta_pag_valor_pago}
        />}

        {<FormEdit id='conta_pag_data_compra'
          label='Dt. Compra'
          styleDiv='4'
          typeField='date'
          onChange={this.handleInputChange}
          value={this.state.conta_pag_data_compra}
        />}

        {<FormEdit id='conta_pag_data_venc'
          label='Dt. Vencimento'
          styleDiv='4'
          typeField='date'
          onChange={this.handleInputChange}
          value={this.state.conta_pag_data_venc}
          required='on'
        />}
  
        {<FormEdit id='conta_pag_data_pgto'
          label='Dt. Pagamento'
          styleDiv='4'
          typeField='date'
          onChange={this.handleInputChange}
          value={this.state.conta_pag_data_pgto}
        />}

        {<FormEdit id='id_sis_status'
          label='Status'
          styleDiv='4'
          onChange={this.handleInputChange}
          typeField='select'
          value={this.state.id_sis_status}
          required='on'
          optionsField={getSisStatus('status_contas_pag')}
        />}
        <div className="col s12">
          {<FormEdit id='excluir'
            label='Excluir'
            styleDiv='submit right'
            styleField='submit'
            typeField='button'
            onClick={this.handleExcluir}
            icon='excluir'
          />}

          {<FormEdit id='gravar'
            label='Gravar'
            styleDiv='submit right'
            styleField='submit'
            typeField='submit'
            icon='gravar'
          />}
        </div>
      </form>

    );

  }
}


export default ContasPagarUpdate;
