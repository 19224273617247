import React from 'react';
import ComponentErp from './ComponentErp.js';
import FormEdit from '../components/FormEdit.js';
import { PostColaboradorSenhaAPI } from '../models/ModelColaboradores.js';

class UsuarioSenha extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = {
      sEmail: '',
      sNovaSenha: '',
      sConfirmaSenha: ''
    };
    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;

    document.body.appendChild(script);

  }

  componentDidUpdate(prevProps, prevState) {

  }

  async callApi() {
    let data =
    {
      colaborador: false,
      email: this.state.sEmail,
      novasenha: this.state.sNovaSenha,
      confirma: this.state.sConfirmaSenha
    }

    var response = await PostColaboradorSenhaAPI(data);
    this.setState(response);
    this.setState({
      sEmail: '',
      sNovaSenha: '',
      sConfirmaSenha: ''
    });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.callApi();
    this.setState({
      refresh: 'gravando',
    });

  }

  render() {

    return (
      <div className="container">
        <div className="row">
          <h2 className="section-title">Alterar senha</h2>
          <div className="card">
            <div id="lista" className="dataTables_wrapper no-footer row">
            <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">

              {<FormEdit id='sEmail'
                label='E-mail'
                styleDiv='1'
                onChange={this.handleInputChange}
                value={this.state.sEmail}
              />}

              {<FormEdit id='sNovaSenha'
                label='Nova Senha'
                styleDiv='2'
                typeField="password"
                onChange={this.handleInputChange}
                value={this.state.sNovaSenha}
              />}

              {<FormEdit id='sConfirmaSenha'
                label='Confirme a nova senha'
                styleDiv='2'
                typeField="password"
                onChange={this.handleInputChange}
                value={this.state.sConfirmaSenha}
              />}

              {<FormEdit id='gravar'
                label='Gravar'
                styleDiv='submit right'
                styleField='submit'
                typeField='submit'
                icon='gravar'
              />}
            </form>
          </div>
        </div>
      </div>
      </div >
    );

  }
}

export default UsuarioSenha;
