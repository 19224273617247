import api from '../services/Api.js';
import { clearMessage, warningMessageCatch, progressBar } from '../services/Funcoes.js';
import { getSisCidades, getSisIdEstado, setSisCidades } from '../services/Auth.js';

/**
 * Lista de Cidades
 * @param {*} id_estado 
 * @param {*} modal 
 */
export const GetEnderecosAPI = (id_estado, modal) => {

  if (modal)
  progressBar();

  var id_estado_atual = getSisIdEstado();
  if (id_estado_atual === id_estado) {
    return {
      erro: 0,
      cidades: getSisCidades()
    }
  }

  return api.get('/endereco/' + id_estado)
    .then(response => {
      setSisCidades(id_estado, response.data.result.cidades)

      clearMessage();

      return {
        erro: 0,
        cidades: getSisCidades()
      }
      
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1,
        cidades: null
      }


    })



}

/**
 * Lista Endereco por CEP
 * @param {*} cep 
 */
export const GetEnderecoCepAPI = (cep) => {

  progressBar();

  return api.get('/endereco/cep/' + cep)
    .then(response => {
      clearMessage();
      if(response.data.result.endereco.endereco!==undefined){
        setSisCidades(response.data.result.endereco.id_estado, response.data.result.endereco.cidades)
        return {
          erro: 0,
          endereco: response.data.result.endereco,
          cidades: getSisCidades()

        }
      }else
      return {
        erro: 0
      }

    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1,
        endereco: null
      }


    })



}
