import React from 'react';
import ComponentErp from './ComponentErp.js';

import store from '../store';
import { getSisStatus } from '../services/Auth.js';
import FormEdit from '../components/FormEdit.js';
import { GetFornecedorAPI, PostFornecedorAPI } from '../models/ModelFornecedores.js';

class FornecedoresUpdate extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = store.getState().fornecedores.cadastro;
    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;

    document.body.appendChild(script);

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.callApi();
      this.setState({
        refresh: 'concluido'
      })
    }

    var response = {}
    if (store.getState().fornecedores.cadastro.refresh === 'buscando' &&
      store.getState().fornecedores.cadastro.id !== store.getState().fornecedores.cadastro.sIdFornecedor
    ) {
      response = { type: 'FORNECEDORES.TOOGLE_REFRESH' }
      this.updateRedux(response);
      this.callApiGetFornecedor(store.getState().fornecedores.cadastro.id)
    }

    if (store.getState().fornecedores.cadastro.refresh === 'limpando') {
      response = { refresh: 'concluido', type: 'FORNECEDORES.TOOGLE_CLEAN' }
      this.updateRedux(response);
      var cadastro = store.getState().fornecedores.cadastro;
      this.setState(cadastro);
    }

  }

  async callApi() {
    let data =
    {
      id_sis_status: this.state.sStatus,
      id_fornecedor: this.state.sIdFornecedor,
      forn_cnpj: this.state.sCnpj,
      forn_razao: this.state.sFornRazao,
      forn_fantasia: this.state.sFornFantasia,
      forn_tel1: this.state.sFornTel1,
      forn_tel2: this.state.sFornCel1,
      forn_email: this.state.sFornEmail
    }

    var response = await PostFornecedorAPI(this.state.sIdFornecedor, data);

    if (this.state.sIdFornecedor === '' && response.erro === 0) {
      response.type = 'FORNECEDORES.TOOGLE_CLEAN';
      this.updateRedux(response);
      response = store.getState().fornecedores.cadastro;
    }

    this.setState(
      response
    );

  }

  async callApiGetFornecedor(id) {

    var response = await GetFornecedorAPI(id);

    response.refresh = 'concluido';
    if (response.erro === 0)
      response.type = 'FORNECEDORES.TOOGLE_GET_ID';
    else
      response.type = 'FORNECEDORES.TOOGLE_CLEAN';
    this.updateRedux(response);
    var cadastro = store.getState().fornecedores.cadastro;
    this.setState(cadastro);

  }

  handleSubmit = (event) => {
    event.preventDefault()
    this.setState({
      refresh: 'gravando'
    });

  }

  render() {

    return (
      <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">
        {<FormEdit id='sIdFornecedor'
          typeField='hidden'
          value={this.state.sIdFornecedor}
        />}

        {<FormEdit id='sCnpj'
          label='CPF/CNPJ'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.sCnpj}
          mask="cpfcnpj"
          required='on'
        />}

        {<FormEdit id='sStatus'
          label='Status'
          styleDiv='2'
          onChange={this.handleInputChange}
          typeField='select'
          value={this.state.sStatus}
          required='on'
          optionsField={getSisStatus('status_padrao')}
        />}

        {<FormEdit id='sFornRazao'
          label='Nome/Razao Social'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.sFornRazao}
          required='on'
        />}

        {<FormEdit id='sFornFantasia'
          label='Sobrenome/Nome Fantasia'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.sFornFantasia}
        />}

        {<FormEdit id='sFornTel1'
          label='Telefone'
          styleDiv='2'
          onChange={this.handleInputChange}
          mask="telcel"
          value={this.state.sFornTel1}
        />}

        {<FormEdit id='sFornCel1'
          label='Celular'
          styleDiv='2'
          onChange={this.handleInputChange}
          mask="telcel"
          value={this.state.sFornCel1}
        />}

        {<FormEdit id='sFornEmail'
          label='E-mail'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.sFornEmail}
        />}

        {<FormEdit id='gravar'
          label='Gravar'
          styleDiv='submit right'
          styleField='submit'
          typeField='submit'
          icon='gravar'
        />}
      </form>

    );

  }
}

export default FornecedoresUpdate;
