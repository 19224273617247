import api from '../services/Api.js';
import { clearMessage, warningMessage, warningMessageCatch, progressBar } from '../services/Funcoes.js';

/**
 * Autocomplete de Fornecedores
 * @param {*} fNome 
 */

export const GetFornecedoresAutocompleteAPI = (fNome) => {
  return api.get('/fornecedor/autocomplete?fnome=' + fNome)
    .then(response => {
      return {
        lista: response.data.result.lista,
        erro: 0
      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}

/**
 * Lista de Fornecedores
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome 
 */

export const GetFornecedoresAPI = (limit, offset, fNome) => {
  progressBar();
  return api.get('/fornecedor/lista?limit=' + limit + '&offset=' + offset + '&fnome=' + fNome)
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        pages: response.data.result.pages,
        limit: response.data.result.limit,
        offset: response.data.result.offset,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        error: 1
      }


    })



}


/* 
 * Busca Fornecedor
 * @param {*} id  
 */
export const GetFornecedorAPI = (id) => {
  progressBar();
  return api.get('/fornecedor/' + id)
    .then(response => {
      clearMessage();

      return {
        erro: 0,
        id: response.data.result.id_fornecedor,
        refresh: 'concluido',
        sStatus: response.data.result.id_sis_status,
        sIdFornecedor: response.data.result.id_fornecedor,
        sCnpj: response.data.result.forn_cnpj,
        sFornRazao: response.data.result.forn_razao,
        sFornFantasia: response.data.result.forn_fantasia,
        sFornTel1: response.data.result.forn_tel1,
        sFornCel1: response.data.result.forn_tel2,
        sFornEmail: response.data.result.forn_email
      }
    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }

    })

}

/* 
 * Cadastro do Fornecedor
 * @param {*} id 
 * @param {*} data 
 */
export const PostFornecedorAPI = (id, data) => {
  progressBar();

  var url = '';
  if (id === '') {
    url = '/fornecedor/add';
  } else {
    url = '/fornecedor/' + id;
  }

  return api.post(url, data)
    .then(response => {
      warningMessage(response.data.result.mensagem);
      return {
        erro: 0,
        refresh: 'concluido'
      }


    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }
    })

}
