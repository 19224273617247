import { getDateYear } from '../../services/Funcoes.js';

const INITIAL_STATE = {
    grids:
    {
        redux_prefix: 'RELCONTASPAGARGERAL.',
        header: ['Referencia', 'Janeiro', 'Fevereiro', 'Março', 
            'Abril', 'Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
        colunas: ['referencia','mes01', 'mes02', 'mes03', 'mes04',
            'mes05', 'mes06','mes07','mes08','mes09','mes10','mes11','mes12'],
        checkbox: '',
        styles: [],
        lista: [],
        fAno: getDateYear(),
        nav: {
            tabs: ['Geral'],
            className: ['geral active'],
            disabled: [null],
            href: ['#geral'],
            onClick: [null]
        },
        edit: {
            itens: ['Alterar Cadastro'],
            onClick: ['']
        },
        refresh: 'concluido' //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
    },
    gridsContas:
    {
        redux_prefix: 'RELCONTASPAGARCONTAS.',
        header: ['Visão Geral', 'Janeiro', 'Fevereiro', 'Março', 
            'Abril', 'Maio','Junho','Julho','Agosto','Setembro','Outubro','Novembro','Dezembro'],
        colunas: ['referencia','mes01', 'mes02', 'mes03', 'mes04',
            'mes05', 'mes06','mes07','mes08','mes09','mes10','mes11','mes12'],
        checkbox: '',
        styles: [],
        lista: [],
        fAno: getDateYear(),
        nav: {
            tabs: ['Geral'],
            className: ['geral active'],
            disabled: [null],
            href: ['#geral'],
            onClick: [null]
        },
        edit: {
            itens: ['Alterar Cadastro'],
            onClick: ['']
        },
        refresh: 'concluido' //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
    },
    gridsForn:
    {
        redux_prefix: 'RELCONTASPAGARFORN.',
        header: ['Status','Data Venc.', 'Data Pagto', 'Plano de Contas', 'Conta', 
            'Detalhamento', 'Fornecedor','Valor','Forma de Pgto'],
        colunas: ['color','vencimento','pagamento', 'classificacao', 'descricao', 'detalhamento',
            'forn_razao', 'valor','forma_pgto_nome'],
        checkbox: '',
        styles: ['40px','90px','90px'],
        lista: [],
        fAno: getDateYear(),
        id_fornecedor: '',
        nav: {
            tabs: ['Geral'],
            className: ['geral active'],
            disabled: [null],
            href: ['#geral'],
            onClick: [null]
        },
        edit: {
            itens: ['Alterar Cadastro'],
            onClick: ['']
        },
        refresh: 'concluido' //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
    },
    gridsPorForn:
    {
        redux_prefix: 'RELCONTASPAGARPORFORN.',
        header: ['Fornecedor','Mês', 'Valor Total', 'Valor Pago', 'Valor à Pagar'],
        colunas: ['forn_razao','vencimento','valor', 'pagas', 'npagas'],
        checkbox: '',
        styles: [null,'90px','90px','90px','90px'],
        lista: [],
        fAno: getDateYear(),
        id_fornecedor: '',
        nav: {
            tabs: ['Geral'],
            className: ['geral active'],
            disabled: [null],
            href: ['#geral'],
            onClick: [null]
        },
        edit: {
            itens: ['Alterar Cadastro'],
            onClick: ['']
        },
        refresh: 'concluido' //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
    }


}

export default function relContasPagarGeral(state = INITIAL_STATE, action) {

    var grid = state.grids;
    if (action.type === 'RELCONTASPAGARGERAL.TOOGLE_LISTA') {
        grid.erro = action.erro;
        if (grid.erro === 0) {
            grid.lista = action.lista;
        }
        return { ...state, grid }
    }

    if (action.type === 'RELCONTASPAGARGERAL.TOOGLE_FBUSCA') {
        grid.fAno = action.fAno;
        return { ...state, grid }
    }

    var gridContas = state.gridsContas;
    if (action.type === 'RELCONTASPAGARCONTAS.TOOGLE_LISTA') {
        gridContas.erro = action.erro;
        if (gridContas.erro === 0) {
            gridContas.lista = action.lista;
        }
        return { ...state, gridContas }
    }

    if (action.type === 'RELCONTASPAGARCONTAS.TOOGLE_FBUSCA') {
        gridContas.fAno = action.fAno;
        return { ...state, gridContas }
    }

    var gridForn = state.gridsForn;
    if (action.type === 'RELCONTASPAGARFORN.TOOGLE_LISTA') {
        gridForn.erro = action.erro;
        if (gridForn.erro === 0) {
            gridForn.lista = action.lista;
        }
        return { ...state, gridForn }
    }

    if (action.type === 'RELCONTASPAGARFORN.TOOGLE_FBUSCA') {
        gridForn.fAno = action.fAno;
        gridForn.id_fornecedor = action.id_fornecedor;
        return { ...state, gridForn }
    }
 
    var gridPorForn = state.gridsPorForn;
    if (action.type === 'RELCONTASPAGARPORFORN.TOOGLE_LISTA') {
        gridPorForn.erro = action.erro;
        if (gridPorForn.erro === 0) {
            gridPorForn.lista = action.lista;
        }
        return { ...state, gridPorForn }
    }

    if (action.type === 'RELCONTASPAGARPORFORN.TOOGLE_FBUSCA') {
        gridPorForn.fAno = action.fAno;
        gridPorForn.id_fornecedor = action.id_fornecedor;
        return { ...state, gridPorForn }
    }
 
    return state;
}