import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import { getSisStatus } from '../services/Auth.js';
import FormEdit from '../components/FormEdit.js';
import { GetColaboradorAPI, PostColaboradorAPI } from '../models/ModelColaboradores.js';

class ColaboradoresUpdate extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = store.getState().colaboradores.cadastro;

    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;


    document.body.appendChild(script);

  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.callApi();
      this.setState({
        refresh: 'concluido'
      })
    }

    var response = {}
    if (store.getState().colaboradores.cadastro.refresh === 'buscando' &&
      store.getState().colaboradores.cadastro.id !== store.getState().colaboradores.cadastro.id_empresa_usu
    ) {
      response = { type: 'COLABORADORES.TOOGLE_REFRESH' }
      store.dispatch(response);
      this.callApiGetColaborador(store.getState().colaboradores.cadastro.id)
    }

    if (store.getState().colaboradores.cadastro.refresh === 'limpando') {
      response = { refresh: 'concluido', type: 'COLABORADORES.TOOGLE_CLEAN' }
      store.dispatch(response);
      var cadastro = store.getState().colaboradores.cadastro;
      this.setState(cadastro);
    }

  }

  async callApi() {

    let data =
    {
      id_sis_status: this.state.id_sis_status,
      emp_usu_nome: this.state.emp_usu_nome,
      emp_usu_cpf: this.state.emp_usu_cpf,
      emp_usu_telefone: this.state.emp_usu_telefone,
      emp_usu_ramal: this.state.emp_usu_ramal,
      emp_usu_celular: this.state.emp_usu_celular,
      emp_usu_email: this.state.emp_usu_email
    }

    var response = await PostColaboradorAPI(this.state.id_empresa_usu, data);

    if (this.state.id_empresa_usu === 0 && response.erro === 0) {
      response.type = 'COLABORADORES.TOOGLE_CLEAN';
      store.dispatch(response);
      response = store.getState().colaboradores.cadastro;
    }
    this.setState(response);


  }

  async callApiGetColaborador(id) {

    var response = await GetColaboradorAPI(id);

    response.refresh = 'concluido';
    if (response.erro === 0)
      response.type = 'COLABORADORES.TOOGLE_GET_ID';
    else
      response.type = 'COLABORADORES.TOOGLE_CLEAN';
    store.dispatch(response);
    var cadastro = store.getState().colaboradores.cadastro;
    this.setState(cadastro);

  }


  render() {
    return (
      <form name="form" onSubmit={this.handleSubmitGravando} action="" method="get" encType="multipart/form-data">

        {<FormEdit id='emp_usu_cpf'
          label='CPF'
          styleDiv='2'
          mask="cpfcnpj"
          onChange={this.handleInputChange}
          value={this.state.emp_usu_cpf}
          required='on'
        />}

        {<FormEdit id='emp_usu_nome'
          label='Nome'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.emp_usu_nome}
          required='on'
        />}

        {<FormEdit id='emp_usu_telefone'
          label='Telefone'
          styleDiv='2'
          mask="telcel"
          onChange={this.handleInputChange}
          value={this.state.emp_usu_telefone}
        />}

        {<FormEdit id='emp_usu_ramal'
          label='Ramal'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.emp_usu_ramal}
        />}

        {<FormEdit id='emp_usu_celular'
          label='Celular'
          styleDiv='2'
          mask="telcel"
          onChange={this.handleInputChange}
          value={this.state.emp_usu_celular}
        />}

        {<FormEdit id='emp_usu_email'
          label='E-mail'
          styleDiv='2'
          onChange={this.handleInputChange}
          value={this.state.emp_usu_email}
        />}

        {<FormEdit id='id_sis_status'
          label='Status'
          styleDiv='2'
          onChange={this.handleInputChange}
          typeField='select'
          value={this.state.id_sis_status}
          required='on'
          optionsField={getSisStatus('status_padrao')}
        />}

        {<FormEdit id='gravar'
          label='Gravar'
          styleDiv='submit right'
          styleField='submit'
          typeField='submit'
          icon='gravar'
        />}
      </form>

    );

  }
}


export default ColaboradoresUpdate;
