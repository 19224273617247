import React, { Component } from 'react';
import { Mask } from './mask/Mask';
import M from 'materialize-css';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
import { convertDateToDb } from '../services/Funcoes.js';

class FormEdit extends Component {

  constructor(props) {

    super(props);
    var styleDiv = ' s12';
    var styleField = ' validade';
    var typeField = 'text';
    var icon = 'send';

    if (this.props.styleDiv === '1')
      styleDiv = ' s12'
    if (this.props.styleDiv === '2')
      styleDiv = ' s6'
    if (this.props.styleDiv === '3')
      styleDiv = ' s3'
    if (this.props.styleDiv === '4')
      styleDiv = ' s4'
    if (this.props.styleDiv === 'submit')
      styleDiv = 'input-field col s20'
    if (this.props.styleDiv === 'submit right')
      styleDiv = 'input-field col s20 right'

    if (this.props.styleField)
      styleField = styleField + ' ' + this.props.styleField

    if (this.props.typeField)
      typeField = this.props.typeField

    if (this.props.icon === 'pesquisa')
      icon = 'search'
    else if (this.props.icon === 'adiciona')
      icon = 'add'
    else if (this.props.icon === 'edita')
      icon = 'edit'
    else if (this.props.icon === 'atualiza')
      icon = 'refresh'
    else if (this.props.icon === 'gravar')
      icon = 'save'
    else if (this.props.icon === 'excluir')
      icon = 'delete'

    this.state = {
      styleDiv: 'input-field col' + styleDiv,
      styleField: 'input-field col s12 ' + styleField,
      typeField: typeField,
      optionsField: this.props.optionsField,
      active: '',
      open: false,
      defaultValue: this.props.defaultValue,
      options: this.props.options,
      loading: false,
      loadData: this.props.loadData,
      icon: icon,
      value: this.props.value,
      refresh: 'concluido',
      refreshAuto: '',
      position: 1
    }
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.props.value === this.state.value && this.state.refresh === 'gravando') {
      this.setState({
        refresh: 'concluido'
      })
      M.AutoInit();
    }

    if (this.props.typeField === 'autocomplete') {
      if ((this.state.open && this.state.options.length === 0) !== this.state.loading)
        this.setState({ loading: this.state.open && this.state.options.length === 0 });
    }

    if (this.props.typeField === 'select' && this.props.optionsField !== this.state.optionsField) {
      this.setState({
        optionsField: this.props.optionsField,
        refresh: 'gravando'
      })
    }

    if (this.props.value !== undefined && this.props.value !== this.state.value) {
      if (this.props.typeField === 'select' || this.props.typeField === 'readonly') {
        this.setState({
          refresh: 'gravando'
        });
      }

      if (this.state.typeField === 'text' && this.props.mask === undefined) {
        this.setState({
          value: this.props.value
        },
          () => {
            this.refs.input.selectionStart = this.state.position
            this.refs.input.selectionEnd = this.state.position

          })
      } else {
        this.setState({
          value: this.props.value
        })
      }

      if (this.state.options !== this.props.optinons)
        this.setState({ options: this.props.options });

      if (this.props.value !== '') {
        this.setState({
          active: 'active',
        })
      } else {
        this.setState({
          active: '',
        })
      }
    }
  }




  handleSubmit = (e) => {
    this.setState({ value: e.target.value })
  }

  handleChange = (e) => {
    this.setState({ position: e.target.selectionStart })
    if (this.props.mask !== undefined)
      e.target.value = Mask(e.target.value, this.props.mask);
    if (this.props.onChange !== undefined)
      this.props.onChange(e);
  }

  handleChangeAuto = (event, values) => {
    if (this.props.onChange !== undefined)
      this.props.onChange(values);
  }

  handleKeyUpAuto = (event, values) => {
    this.callLoad(event.target.value);
  }


  callLoad(value) {
    (async () => {
      const response = await this.props.loadData(value);
      this.setState({ options: response })
    })();
    return false;
  }

  handleChangeCk = (e) => {
    e.target.value = (e.target.checked === true) ? 1 : 0;
    if (this.props.onChange !== undefined)
      this.props.onChange(e);
  }


  render() {
    if (this.state.typeField === 'hidden')
      return (
        <input id={this.props.id}
          type={this.state.typeField}
          name={this.props.id}
          value={(this.state.value === null) ? '' : this.state.value}
          className={this.state.styleField} />
      );

    if (this.state.typeField === 'select') {
      return (
        <div className={this.state.styleDiv}>
          <select value={(this.state.value === null) ? '-1' : this.state.value}
            onChange={this.handleChange}
            defaultValue={this.state.defaultValue}
            name={this.props.id}
            id={this.props.id}
            key={this.props.id}
          >
            <option value="-1" >Escolha uma opção</option>
            {(Array.isArray(this.state.optionsField)) ? this.state.optionsField.map((item, key) =>
              <option key={item.id} value={item.id}>{item.value}</option>
            ) : ''
            }
          </select>
          <label htmlFor={this.props.id} >{this.props.label}</label>
        </div>)
    }
    if (this.state.typeField === 'submit')
      return (
        <div className={this.state.styleDiv}>
          <button id={this.props.id}
            className="btn waves-effect waves-light"
            type={this.state.typeField}
            name={this.props.id} >{this.props.label}
            <i className="material-icons white-text left">{this.state.icon}</i>
          </button>
        </div>)

    if (this.state.typeField === 'button')
      return (
        <div className={this.state.styleDiv}>
          <button id={this.props.id}
            className="btn waves-effect waves-light"
            onClick={this.props.onClick}
            name={this.props.id} >{this.props.label}
            <i className="material-icons left white-text left">{this.state.icon}</i>
          </button>
        </div>)

    if (this.state.typeField === 'button')
      return (
        <div className={this.state.styleDiv}>
          <input id={this.props.id}
            name={this.props.id}
            type='text'
            value={(this.state.value === null) ? '' : this.state.value}
            className={this.state.styleField} />
                disabled
          <label htmlFor={this.props.id} className={this.state.active}>{this.props.label}</label>
        </div>
      );

    if (this.state.typeField === 'checkbox')
      return (
        <div className={this.state.styleDiv}>
          <label>
            <input
              id={this.props.id}
              name={this.props.id}
              ref='input'
              type={this.state.typeField}
              required={this.props.required}
              checked={(this.state.value === '1' || this.state.value === 1) ? 'checked' : ''}
              onChange={this.handleChangeCk}
              className={this.state.styleField}
            />
            <span>{this.props.label}</span></label>
        </div>
      );

    if (this.state.typeField === 'autocomplete') {
      return (
        <div className={this.state.styleDiv}>
          <Autocomplete
            id={this.state.id}
            open={this.state.open}
            onOpen={() => {
              this.setState({ open: true })
            }}
            onClose={() => {
              this.setState({ open: false })
            }}
            onChange={this.handleChangeAuto}
            onKeyUp={this.handleKeyUpAuto}
            getOptionSelected={(option, value) => option.nome === value.name}
            getOptionLabel={(option) => option.nome}
            options={this.state.options}
            value={this.state.value}
            loading={this.state.loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label={this.props.label}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {this.state.loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </div>);
    }

    if (this.state.typeField === 'readonly')
      return (
        <div className={this.state.styleDiv} disabled>
          <input id={this.props.id}
            name={this.props.id}
            type='text'
            ref='input'
            value={(this.state.value === null) ? '' : this.state.value}
            onChange={this.handleChange}
            className={this.state.styleField + ' validade'}
            disabled
          />
          <label htmlFor={this.props.id} className={this.state.active + ' active'}>{this.props.label}</label>
        </div>
      );

    if (this.state.typeField === 'date')
      return (
        <div className={this.state.styleDiv}>
          <input id={this.props.id}
            name={this.props.id}
            ref='input'
            defaultValue={this.state.defaultValue}
            type={this.state.typeField}
            required={this.props.required}
            value={(this.state.value === null) ? '' :
              convertDateToDb(this.state.value)}
            onChange={this.handleChange}
            className={this.state.styleField}
          />
          <label htmlFor={this.props.id} className={this.state.active + ' active'}>{this.props.label}</label>
        </div>
      );

    return (
      <div className={this.state.styleDiv}>
        <input id={this.props.id}
          name={this.props.id}
          ref='input'
          defaultValue={this.state.defaultValue}
          type={this.state.typeField}
          required={this.props.required}
          value={(this.state.value === null) ? '' : this.state.value}
          onChange={this.handleChange}
          className={this.state.styleField}
        />
        <label htmlFor={this.props.id} className={this.state.active}>
          {this.props.label}
        </label>
      </div>
    );

  }

}

export default FormEdit;
