import api from '../services/Api.js';
import { clearMessage, warningMessage, warningMessageCatch, progressBar } from '../services/Funcoes.js';

/**
 * Autocomplete de Clientes
 * @param {*} fNome 
 */

export const GetClientesAutocompleteAPI = (fNome) => {
  return api.get('/cliente/autocomplete?fnome=' + fNome)
    .then(response => {
      return {
        lista: response.data.result.lista,
        erro: 0
      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}

/**
 * Lista de Clientes
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome 
 * @param {*} fStatus 
 */

export const GetClientesAPI = (limit, offset, fNome, fStatus) => {
  progressBar();
  return api.get('/cliente/lista?limit=' + limit + '&offset=' + offset + '&fnome=' + fNome + '&fstatus=' + fStatus)
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        pages: response.data.result.pages,
        limit: response.data.result.limit,
        offset: response.data.result.offset,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        error: 1
      }


    })



}


/* 
 * Busca Cliente
 * @param {*} id  
 */
export const GetClienteAPI = (id) => {
  progressBar();
  return api.get('/cliente/' + id)
    .then(response => {
      clearMessage();

      return {
        erro: 0,
        id: response.data.result.id_cliente,
        refresh: 'concluido',
        sStatus: response.data.result.id_sis_status,
        sIdCliente: response.data.result.id_cliente,
        sCnpj: response.data.result.cli_cnpj,
        sCliRazao: response.data.result.cli_razao,
        sCliFantasia: response.data.result.cli_fantasia,
        sCliTel1: response.data.result.cli_tel1,
        sCliCel1: response.data.result.cli_tel2,
        sCliEmail: response.data.result.cli_email,
        sCliNascimento: response.data.result.cli_data_nascimento
      }
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }

    })

}

/* 
 * Cadastro do Cliente
 * @param {*} id 
 * @param {*} data 
 */
export const PostClienteAPI = (id, data) => {
  progressBar();

  var url = '';
  if (id === '') {
    url = '/cliente/add';
  } else {
    url = '/cliente/' + id;
  }

  return api.post(url, data)
    .then(response => {
      warningMessage(response.data.result.mensagem);
      return {
        erro: 0,
        refresh: 'concluido'
      }


    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }
    })

}
