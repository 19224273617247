import { getDateYear } from '../../services/Funcoes.js';

const INITIAL_STATE = {
    grids:
    {
        redux_prefix: 'RESULTADOFINANCEIRO.',
        header: ['Referência', 'Vendas', 'Compras','Lucro Operacional','Créditos', 'Débitos', 'Lucro Financeiro'],
        colunas: ['referencia','vendas', 'debitosoper','lucro','creditos',  'debitos', 'lucroreal'],
        checkbox: '',
        styles: [ '50px','70px', '70px','70px','50px', '70px', '70px'],
        lista: [],
        saldo: '',
        creditos: '',
        debitos: '',
        fAno: getDateYear(),
        nav: {
            tabs: ['Resultado financeiro'],
            className: ['lista active'],
            disabled: [null],
            href: ['#lista'],
            onClick: [null]
        },
        edit: {
            itens: [''],
            onClick: ['']
        },
        id: 0, 
        refresh: 'concluido' //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
    }

}

export default function resultadoFinanceiro(state = INITIAL_STATE, action) {
    var grid = state.grids;
    if (action.type === 'RESULTADOFINANCEIRO.TOOGLE_LISTA') {
        grid.erro = action.erro;
        if (grid.erro === 0) {
            grid.lista = action.lista;
            grid.vendastotal = action.vendastotal;
            grid.debitosopertotal = action.debitosopertotal;
            grid.lucrototal = action.lucrototal;
            grid.debitostotal = action.debitostotal;
            grid.creditostotal = action.creditostotal;
            grid.lucrorealtotal = action.lucrorealtotal;
            grid.edit.onClick = action.editOnClick;
        }
        return { ...state, grid }
    }

    if (action.type === 'RESULTADOFINANCEIRO.TOOGLE_FBUSCA') {
        grid.fAno = action.fAno;
        return { ...state, grid }
    }

    return state;
}   