import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import FormEdit from '../components/FormEdit.js';
import { GetClientesFinAPI } from '../models/ModelClientesFin.js';

class ClientesFinanceiro extends ComponentErp {

  constructor(props) {
    super(props);
    this.state = store.getState().clientes.financeiro;
    const script = document.createElement("script");

    script.src = "/assets/js/admin-materialize.min.js?v=1";
    script.async = true;

    document.body.appendChild(script);
  }

  componentDidMount() {
    this.callApi(1)
  }

  componentDidUpdate(prevProps, prevState) {

    var response = {};
    
    if ((store.getState().clientes.financeiro.refresh === 'listafinanceiro' &&
        store.getState().clientes.financeiro.id_cliente !== store.getState().clientes.cadastro.id
      )) {
      response = { type: 'CLIENTES.FINANCEIRO.TOOGLE_REFRESH' }
      this.updateRedux(response);
      this.callApi(1);
    }

    if (this.state.refresh === 'buscando') {
      response = {
        refresh: 'concluido'
      }

      this.setState(response);

    }

    if (this.state.refresh === 'limpando') {
      this.callApi(0);
      response = {
        refresh: 'concluido',
        id_cliente: this.state.id_cliente
      }
      this.setState(response);

    }

  }


  callBackPaginacao = (response) => {
    response.refresh = 'buscaclientefinanceiro';
    response.type = 'CLIENTES.FINANCEIRO.' + response.type;
    this.updateReduxAndState(response);
  }

  async callApi(modal) {
    var grids = store.getState().clientes.financeiro;
    
    var response = await GetClientesFinAPI(store.getState().clientes.cadastro.id, grids.limit, grids.offset, modal);

    response.refresh = 'concluido';
    response.callBackPaginacao = this.callBackPaginacao;
    response.id_cliente = store.getState().clientes.cadastro.id;

    response.type = 'CLIENTES.FINANCEIRO.TOOGLE_LISTA';
    this.updateRedux(response);
    grids = store.getState().clientes.financeiro;
    this.setState(grids);
  }


  render() {

    return (
      <div>
        <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">
          
          {<FormEdit id='sFinCnpj'
            label='Cliente'
            styleDiv='1'
            value={store.getState().clientes.financeiro.cliente}
            typeField='readonly'
          />}

        </form>
        {this.Grid(store.getState().clientes.financeiro)}

      </div>

    );

  }
}

export default ClientesFinanceiro;
