import { getDate } from '../../services/Funcoes.js';

const INITIAL_STATE = {
    grids:
    {
        redux_prefix: 'COMANDAS.',
        header: ['ID', 'Cliente', 'Data'],
        colunas: ['id_venda', 'cli_razao', 'venda_data_cad'],
        checkbox: '',
        styles: ['50px', '', ''],
        lista: [],

        limit: 25,
        offset: 1,
        pages: 0,
        fNome: '',
        fData: getDate(),
        endereco: null,
        nav: {
            tabs: ['Lista de Comandas', 'Cadastrar'],
            className: ['lista active', 'cadastro'],
            disabled: [null, null],
            href: ['#lista', '#cadastro'],
            onClick: [null, null, null]
        },
        edit: {
            itens: ['Alterar Cadastro'],
            onClick: ['']
        },
        onClickModal: '',
        id: 0,
        refresh: 'concluido' //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form
    },
    cadastro:
    {
        novoitem: false,
        refresh: 'concluido', //0 = concluído, 1 = gravando, 2 = buscando, 3 = limpando form 
        id: 0,
        id_tipo: '1',
        id_venda: 0,
        id_cliente: '',
        id_servico: '',
        id_produto: '',
        id_produto_unidade: '',
        id_fornecedor: '',
        nome_fornecedor: '',
        nome_servico: '',
        nome_produto: '',
        venda_qtdd: '',
        venda_vlr: '',
        venda_vlr_desconto: '',
        venda_vlr_desc: '',
        venda_vlr_serv: '',
        venda_vlr_prod: '',
        venda_vlr_total: '',
        venda_vlr_rec: '',
        venda_recebimento: '',
        venda_fechada:'0',
        id_sis_forma_pgto: '',
        venda_data_cad: '',
        cli_razao: '',
        produtos: {
            header: ['ID', 'Fornecedor', 'Produto', 'Qtdd', 'Valor', 'Desconto', 'Data'],
            colunas: ['id_venda_prod', 'nome_fornecedor', 'nome_produto', 'venda_qtdd', 'venda_vlr', 'venda_vlr_desconto', 'venda_data_cad'],
            checkbox: '',
            styles: ['50px', '', '', '50px', '80px', '80px', '90px'],
            lista: [],
            edit: {
                itens: ['Alterar Cadastro'],
                onClick: ['']
            },
            onClickModal: '',
        },
        servicos: {
            header: ['ID', 'Serviço', 'Qtdd', 'Valor', 'Desconto', 'Data'],
            colunas: ['id_venda_serv', 'nome_servico', 'venda_qtdd', 'venda_vlr', 'venda_vlr_desconto', 'venda_data_cad'],
            checkbox: '',
            styles: ['50px', '', '50px', '80px', '80px', '90px'],
            lista: [],
            edit: {
                itens: ['Alterar Cadastro'],
                onClick: ['']
            },
            onClickModal: '',
        }
    }

}

export default function comandas(state = INITIAL_STATE, action) {
    var grid = state.grids;
    if (action.type === 'COMANDAS.TOOGLE_LISTA') {
        grid.erro = action.erro;
        if (grid.erro === 0) {
            grid.lista = action.lista;
            grid.onClickModal = action.onClickModal;
            grid.callBackPaginacao = action.callBackPaginacao;
            grid.pages = action.pages;
            grid.limit = action.limit;
            grid.offset = action.offset;
            grid.edit.onClick = action.editOnClick;
        }
        return { ...state, grid }
    }



    if (action.type === 'COMANDAS.TOOGLE_FBUSCA') {
        grid.fNome = action.fNome;
        grid.fData = action.fData;
        return { ...state, grid }
    }

    if (action.type === 'COMANDAS.TOOGLE_LIMIT') {
        grid.offset = 1;
        grid.limit = action.limit;
        return { ...state, grid }
    }

    if (action.type === 'COMANDAS.TOOGLE_OFFSET') {
        grid.offset = action.offset;
        return { ...state, grid }
    }

    var cadastro = state.cadastro;
    if (action.type === 'COMANDAS.TOOGLE_CADASTRO') {
        cadastro.id = action.id;
        cadastro.refresh = 'buscando';
        return { ...state, cadastro }
    }

    if (action.type === 'COMANDAS.TOOGLE_NEW_ITEM') {
        cadastro.novoitem = action.novoitem;
        return { ...state, cadastro }
    }

    if (action.type === 'COMANDAS.RECEBIMENTO') {
        cadastro.venda_vlr_rec = action.venda_vlr_rec;
        cadastro.id_sis_forma_pgto = action.id_sis_forma_pgto;
        return { ...state, cadastro }
    }

    if (action.type === 'COMANDAS.TOOGLE_REFRESH') {
        cadastro.refresh = 'concluido';
        return { ...state, cadastro }
    }


    if (action.type === 'COMANDAS.TOOGLE_SET_ID_FORNECEDOR') {
        cadastro.refresh = 'concluido';
        cadastro.id_fornecedor = action.id_fornecedor;
        return { ...state, cadastro }
    }

    if (action.type === 'COMANDAS.TOOGLE_SET_ID') {
        cadastro.refresh = 'concluido';
        cadastro.id = action.id_venda;
        cadastro.id_venda = '';
        cadastro.id_tipo = '1';
        cadastro.id_servico = '';
        cadastro.id_fornecedor = '';
        cadastro.id_produto = '';
        cadastro.id_produto_unidade = '';
        cadastro.venda_qtdd = '';
        cadastro.venda_vlr = '';
        cadastro.venda_vlr_desconto = '';
        cadastro.venda_vlr_total = '';
        cadastro.venda_vlr_prod = '';
        cadastro.venda_vlr_serv = '';
        cadastro.venda_vlr_desc = '';

        cadastro.produtos.lista = [];
        cadastro.servicos.lista = [];
        return { ...state, cadastro }
    }

    if (action.type === 'COMANDAS.TOOGLE_GET_ID') {
        cadastro.refresh = 'concluidoRefresh';
        cadastro.novoitem = false;
        cadastro.id_venda = action.id_venda;
        cadastro.id_cliente = action.id_cliente;
        cadastro.venda_qtdd = '';
        cadastro.venda_vlr_total = action.venda_vlr_total;
        cadastro.venda_vlr_rec = action.venda_vlr_rec;
        cadastro.venda_vlr_serv = action.venda_vlr_serv;
        cadastro.venda_vlr_prod = action.venda_vlr_prod;
        cadastro.venda_vlr_desc = action.venda_vlr_desc;
        cadastro.venda_data_cad = action.venda_data_cad;
        cadastro.id_sis_forma_pgto = '';
        cadastro.venda_recebimento = action.venda_recebimento;
        cadastro.venda_fechada = action.venda_fechada
        cadastro.cli_razao = action.cli_razao;
        cadastro.produtos.lista = action.produtos;
        cadastro.servicos.lista = action.servicos;
        cadastro.produtos.onClickModal = action.onClickModalProd;
        cadastro.servicos.onClickModal = action.onClickModalServ;

        return { ...state, cadastro }
    }

    if (action.type === 'COMANDAS.TOOGLE_CLEAN') {
        cadastro.refresh = action.refresh;
        cadastro.id = 0;
        cadastro.id_venda = 0;
        cadastro.novoitem = true;
        cadastro.id_tipo = '1';
        cadastro.id_cliente = '';
        cadastro.venda_vlr_total = '';
        cadastro.venda_vlr = '';
        cadastro.venda_vlr_desc = '';
        cadastro.venda_vlr_rec = '';
        cadastro.venda_recebimento = '';
        cadastro.venda_fechada = '0';
        cadastro.venda_vlr_serv = '';
        cadastro.venda_vlr_prod = '';
        cadastro.venda_data_cad = '';
        cadastro.id_sis_forma_pgto = '';
        cadastro.cli_razao = '';
        cadastro.produtos.lista = [];
        cadastro.servicos.lista = [];

        return { ...state, cadastro }
    }


    return state;
}