import api from '../services/Api.js';
import { clearMessage, warningMessage, warningMessageCatch, progressBar } from '../services/Funcoes.js';

/**
 * Lista de Vendas
 * @param {*} limit 
 * @param {*} offset 
 * @param {*} fNome 
 * @param {*} fData 
 */

export const GetComandasAPI = (limit, offset, fNome, fData) => {

  progressBar();
  return api.get('/venda/lista?limit=' + limit + '&offset=' + offset + '&fnome=' + fNome +
    '&fdata=' + fData)
    .then(response => {
      clearMessage();
      return {
        lista: response.data.result.lista,
        pages: response.data.result.pages,
        limit: response.data.result.limit,
        offset: response.data.result.offset,
        erro: 0

      }
    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        error: 1
      }


    })



}

/* 
 * Deleta Item Venda Prod
 * @param {*} id  
 * @param {*} id_venda_prod  
 */
export const DelVendaItemProd = (id, id_venda_prod) => {
  progressBar();
  return api.get('/venda/del/prod/' + id + '/' + id_venda_prod)
    .then(response => {
      var ret = response.data.result;
      ret.refresh = 'concluido';
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }

    })

}

/* 
 * Fecha comanda
 * @param {*} id  
 */
export const PostFecharComanda = (id) => {
  progressBar();
  return api.post('/venda/fecharcomanda/' + id)
    .then(response => {
      var ret = response.data.result;
      ret.refresh = 'concluido';
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }

    })

}

/* 
 * Reabrir comanda
 * @param {*} id  
 */
export const PostReabrirComanda = (id) => {
  progressBar();
  return api.post('/venda/reabrircomanda/' + id)
    .then(response => {
      var ret = response.data.result;
      ret.refresh = 'concluido';
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }

    })

}

/* 
 * Lança recebimento
 * @param {*} id  
 * @param {*} data
 */
export const PostRecebimento = (id, data) => {
  progressBar();
  return api.post('/venda/recebimento/' + id, data)
    .then(response => {
      var ret = response.data.result;
      ret.refresh = 'concluido';
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      warningMessageCatch(error.response);
      return {
        erro: 1
      }

    })

}

/* 
 * Deleta Item Venda Serv
 * @param {*} id  
 * @param {*} id_venda_serv  
 */
export const DelVendaItemServ = (id, id_venda_serv) => {
  progressBar();
  return api.get('/venda/del/serv/' + id + '/' + id_venda_serv)
    .then(response => {
      var ret = response.data.result;
      ret.refresh = 'concluido';
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }

    })

}

/* 
 * Busca Venda
 * @param {*} id  
 */
export const GetComandaAPI = (id) => {
  progressBar();
  return api.get('/venda/' + id)
    .then(response => {
      clearMessage();
      var ret = response.data.result;
      ret.id = id;
      ret.id_venda = id;
      ret.refresh = 'concluido';
      ret.erro = 0;
      return ret
    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }

    })

}

/* 
 * Cadastro do Venda
 * @param {*} id 
 * @param {*} data 
 */
export const PostComandaAPI = (id, data) => {
  progressBar();

  var url = '';
  if (id === 0) {
    url = '/venda/add';
  } else {
    url = '/venda/' + id;
  }

  return api.post(url, data)
    .then(response => {
      warningMessage(response.data.result.mensagem);
      return {
        erro: 0,
        id_venda: response.data.result.id,
        refresh: 'buscando'
      }

    })
    .catch(error => {
      warningMessageCatch(error.response);

      return {
        erro: 1
      }
    })

}
