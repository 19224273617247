import React from 'react';
import ComponentErp from './ComponentErp.js';
import store from '../store';

import FormEdit from '../components/FormEdit.js';
import { GetRelatorioGeralAPI } from '../models/ModelContasPagar.js';


class RelatorioContasPagarGeral extends ComponentErp {
  constructor(props) {
    super(props);
    this.state = store.getState().relContasPagarGeral.grids;
    
  }

  componentDidMount() {
    this.callApi()
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.refresh === 'gravando') {
      this.setState({ refresh: 'buscando' });
      this.callApi()
    }

  }

  handleSubmit = (event) => {
    event.preventDefault()
    var response = {
      refresh: 'gravando',
      fAno: this.state.fAno,
      type: 'RELCONTASPAGARGERAL.TOOGLE_FBUSCA'
    }
    this.updateReduxAndState(response);

  }

  async callApi() {
    var grids = store.getState().relContasPagarGeral.grids;

    var response = await GetRelatorioGeralAPI(grids.fAno);
    response.refresh = 'concluido';
    response.type = 'RELCONTASPAGARGERAL.TOOGLE_LISTA';

    this.updateRedux(response);
    grids = store.getState().relContasPagarGeral.grids;
    this.setState(grids);
  }



  render() {

    return (

      <div className="container">
        <div className="row">
          <h2 className="section-title">Relatório - Despesa geral</h2>
          <div className="card">
            {this.navMenu(store.getState().relContasPagarGeral.grids.nav)}

            <div id="lista" className="dataTables_wrapper no-footer row">
              <form name="form" onSubmit={this.handleSubmit} action="" method="get" encType="multipart/form-data">

                {<FormEdit id='fAno'
                  label='Ano'
                  value={this.state.fAno}
                  onChange={this.handleInputChange}
                  typeField='number'
                  styleDiv='4' />}

                {<FormEdit id='submit'
                  label='Pesquisar'
                  styleDiv='submit'
                  styleField='submit'
                  typeField='submit'
                  icon='pesquisa'
                />}

              </form>

              {this.GridFull(store.getState().relContasPagarGeral.grids)}
            </div>

          </div>
        </div>
      </div>

    );

  }
}
export default (RelatorioContasPagarGeral);
